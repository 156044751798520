import { defineComponent, ref, watch } from 'vue';
// import { SEOManager } from '@/managers';
import store from '@/store';
import SectionJSON from '@/_static/sections.json';
import useMapL from './composables/useMapL';
import SearchFields from '@/components/SearchFields/SearchFields.component.vue';
import DetailPanel from '@/components/DetailPanel/DetailPanel.component.vue';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';

export default defineComponent({
  name: 'Inspire',
  props: {
  },
  directives: {
  },
  components: {
    SearchFields, DetailPanel
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  setup() {
    // SEOManager.setTitles('About');
    const sections = SectionJSON;
    const map = useMapL();
    const panelOpen = ref(false);
    const classPanel = ref('inspire');
    const inspireTitle = ref('');
    inspireTitle.value = decodeString(i18n.global.t('button.inspire'));
    const inspireDes = ref('');
    inspireDes.value = decodeString(i18n.global.t('inspire.description'));

    watch(() => store.getters['tool/panelOpen'], function() {
      console.log('>>>WATCH panelOpen');
      panelOpen.value = store.getters['tool/panelOpen'];

      if (window.innerWidth < 992) {
        classPanel.value = 'inspire';
      } else {
        classPanel.value = store.getters['tool/panelOpen'] ? 'inspireFlex' : 'inspire';
      }
    });

    window.addEventListener('resize', function() {
      if (window.innerWidth < 992) {
        classPanel.value = 'inspire';
      } else {
        classPanel.value = 'inspireFlex';
      }

      if (store.getters['tool/clickInspireIds'].length !== 0) {
        if (window.innerWidth > 992) {
          const omap = document.getElementById('olmap');
          omap.style.setProperty('width', '100%');
          const map = document.getElementById('lmap');
          map.style.setProperty('width', '100%');
          const box = document.getElementById('detailpanel');
          box.style.setProperty('width', '100%');
          const tools = document.getElementById('search-fields');
          tools.style.setProperty('width', '50%');
        } else {
          // const map = document.getElementById('lmap');
          // map.style.setProperty('width', '100%');
          const box = document.getElementById('detailpanel');
          box.style.setProperty('width', '100%');
          const tools = document.getElementById('search-fields');
          tools.style.setProperty('width', '100%');
        }
      }
    }, true);

    return {
      sections,
      panelOpen,
      classPanel,
      inspireTitle,
      inspireDes,
      ...map,
    };
  }

});
