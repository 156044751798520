import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.class),
    "aria-hidden": "true",
    focusable: "false",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("use", {
      "xlink:href": `${_ctx.iconSprite}#${_ctx.name}`
    }, null, 8, _hoisted_1)
  ], 2))
}