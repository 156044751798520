<teleport to="head">
  <title>{{ title }}</title>
  <template v-for="(metaItem, i) in list" :key="i">
    <template v-if="metaItem.name">
      <meta :name="metaItem.name" :content="metaItem.content" />
    </template>
    <template v-else-if="metaItem.property">
      <meta :property="metaItem.property" :content="metaItem.content" />
    </template>
  </template>
  <template v-for="(language, i) in languages" :key="i">
    <link rel="alternate" :hreflang="language.code" :href="language.url" />
  </template>
</teleport>

<main class="app-page" v-show="i18nLoading === false">
  <ecl-site-header-harmonised
    ref="refHeader"
    :className="className"
    :menuItems="menuPrimary"
    siteName="Rural toolkit"
    :showLanguage="true"
    @performSearch="performSearch"
  ></ecl-site-header-harmonised>
  <!-- :siteName="name" -->
  <ecl-page-header-harmonised
    :metaInfo="metaInfo"
    :description="description"
    :breadcrumbList="breadcrumbList"
    :breadcrumbEllipsisLimit="0"
  ></ecl-page-header-harmonised>

  <!-- :pageTitle="title" -->
  <div class="app-page__content">
    <router-view></router-view>
  </div>
  <ecl-footer-harmonised
    :siteName="{ label: 'Rural toolkit', url: baseUrl}"
    :contentOwnerDetail="contentOwnerDetails"
    :siteContentColumns="footerSiteContentColumns"
    :overrides="footerOverrides"
  ></ecl-footer-harmonised>
</main>
