import { EclBreadcrumbItem } from '@jrcb3/ecl-vue';

interface BreadcrumbConfigs {
  default: EclBreadcrumbItem[];
}

const breadcrumbConfigs: BreadcrumbConfigs = {
  default: []
};
export default breadcrumbConfigs;
