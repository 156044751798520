<div
  class="paragraph-more-less"
  :class="{'break-abscract': splitAbstract, 'is-collapsed': isCollapsed, 'is-expanded': isExpanded}"
>
  <ecl-p class="paragraph__abstract" v-if="abstractText" v-html="abstractText"></ecl-p>
  <template v-if="paragraphList">
    <transition name="fade">
      <div class="paragraph__list" :id="id" v-show="isExpanded" v-bind="{ hidden: showCollapsed }">
        <ecl-p
          class="paragraph_list__item"
          v-for="(paragraph, index) in paragraphList"
          :key="index"
          v-html="paragraph"
        ></ecl-p>
        <ecl-button
          v-if="showExpand"
          class="ecl-button ecl-button--more"
          :text="$t(buttonLabel)"
          :ariaLabel="$t(buttonLabel)"
          :aria-controls="id"
          :aria-expanded="isExpanded"
          :type="EclButtonType.Text"
          @click="toggleCollapse"
        ></ecl-button>
      </div>
    </transition>
    <ecl-button
      v-if="showCollapsed"
      class="ecl-button ecl-button--more"
      :text="$t(buttonLabel)"
      :ariaLabel="$t(buttonLabel)"
      :aria-controls="id"
      :aria-expanded="isExpanded"
      :type="EclButtonType.Text"
      @click="toggleCollapse"
    ></ecl-button>
  </template>
</div>
