<ecl-container>
  <ecl-row class="ecl-u-pb-l">
    <ecl-column col="12" l="12" xl="12" class="">
      <h1 class="ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl ecl-u-mb-l">{{title}}</h1>
    </ecl-column>
  </ecl-row>

  <p class="ecl-u-type-paragraph-lead ecl-u-pt-l" v-html="des"></p>
  <div class="ecl-u-mb-xl">&nbsp;</div>

  <template v-for="(item, index) in resources.resources" :key="index">
    <div class="ecl-u-pt-xl ecl-u-pb-s">
      <ecl-h v-if="index == 0" level="3" class="ecl-u-mt-none ecl-u-pb-m">{{subTitle1}}</ecl-h>
      <ecl-h v-if="index == 5" level="3" class="ecl-u-pt-xl ecl-u-pb-m">{{subTitle2}}</ecl-h>
      <ecl-h v-if="index == 16" level="3" class="ecl-u-pt-xl ecl-u-pb-m">{{subTitle3}}</ecl-h>
      <ecl-h v-if="index == 18" level="3" class="ecl-u-pt-xl ecl-u-pb-m">{{subTitle4}}</ecl-h>  

      <ecl-row>
        <ecl-column col="12" l="3" m="3" class="">
          <img
          class="section__image"
          :src="require(`@/assets/${item.image}`)"
          :alt="titles[index]"
        />            
        </ecl-column>
        <ecl-column col="12" m="9" l="9"  class="ecl-u-mb-m" style="padding-left: 2rem;">

          <ecl-h level="5" class="ecl-u-mt-s"  v-html="titles[index]"></ecl-h>
          <ecl-p v-html="dess[index]"></ecl-p>
          <ecl-button
            class="ecl-u-mt-m"
            icon="external"
            iconSize="xs"
            :text="buttonGo"
            :ariaLabel="buttonGo"
            :type="EclButtonType.Primary"
            :url="item.url"
            :target="item.target"
          ></ecl-button>
          
        </ecl-column>
      </ecl-row>

      <ecl-row v-if="item.row == 1">
        <ecl-column col="12" m="12" class="">
          <span class="ecl-u-pt-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1"></span>
        </ecl-column>
      </ecl-row>      


    </div>
  </template>
  <div class="ecl-u-mb-l">&nbsp;</div>
</ecl-container>