<div class="ecl-container ecl-u-pb-xl">
  <ecl-row class="ecl-u-pb-l">
    <ecl-column col="12" l="12" xl="12" class="">
      <h1 class="ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl ecl-u-mb-l" v-html="inspireTitle"></h1>
    </ecl-column>
  </ecl-row>

  <p class="ecl-u-type-paragraph-lead ecl-u-pt-l" v-html="inspireDes"></p>
</div>

  <div id="inspire" :class="classPanel">
    <div class="panel_inspire">
      <div id ="search-fields" class="contenitore-superiore">
        <SearchFields></SearchFields>
      </div> 

      <div id="olmap" class="contenitore-inferiore">  
        <div ref="mapEl" id="lmap" class="map" data-finger-alert="Use two finger to move the map"></div>
      </div>  

    </div>

    <div id="detailpanel" class="panel_inspire_detail" v-if="panelOpen">
      <div sticky-container ref="panelContent" id="detailPanelContent" v-if="panelOpen">
        <DetailPanel></DetailPanel>
      </div>
    </div>

  </div>