<template v-if="name">
  <svg :class="class" aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <use :xlink:href="`${iconSprite}#${name}`"></use>
  </svg>
</template>
<template v-else-if="data">
  <span :class="class" v-html="data"></span>
</template>
<template v-else-if="url">
  <img :class="class" :src="url" alt="Icon" />
</template>
