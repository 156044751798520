import { RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router';
import { isNil, merge, map, assign, each } from 'lodash';
import { unref } from 'vue';
import { decodeString } from '@/helpers/string/mutate';
import { LocaleManager } from '@/managers';
import { appConfigs } from '@/configs';
import { loadLocaleMessages, loadLocales } from '@/locale';
import { store } from '@/store';
import { VueRoute } from '../helpers/url/VueRoute';
import { SiteModuleStore } from '../store/modules/site/types';

/**
 * Carica i settings del site
 *
 * @returns {Promise<SiteResponse>}
 */
export const loadSite = async () => {
  const siteId = appConfigs.api.params.site;
  console.log('>>siteId');
  console.log(siteId);

  return new Promise((resolve) => {
    let breadcrumb = [];
    breadcrumb = require('@/_static/strings/breadcrumb.json');

    const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
      ? unref(LocaleManager.locale)
      : 'en';
    const l = lang === 'en' ? '' : '_' + lang.toUpperCase();

    let site;
    try {
      site = require('@/_static/strings/siteStrings' + l + '.json');
    } catch (ex) {
      site = require('@/_static/strings/siteStrings.json');
    }

    breadcrumb[0].title = site.labels.home;

    store.commit('site/name', 'Rural toolkit');
    store.commit('site/breadcrumb', breadcrumb);
    console.log('>>>>');
    console.log(site.labels.contentOwnerDetails);

    store.commit('site/contentOwnerDetails', site.labels.contentOwnerDetails);

    resolve(true);
  });
};

/**
 * Carica tutte le lingue disponibili per il sito
 * se non presente imposta una lingua di default
 * verifica che la lingua impostata sia disponibile tra quelle disponibili per il sito
 *
 * @param {RouteLocationNormalized} to
 * @returns {Promise<boolean | RouteLocationRaw>}
 */
export const languageCheck = async (to: RouteLocationNormalized): Promise<boolean | RouteLocationRaw> => {
  // load the available locale
  await loadLocales();

  // get the params from url
  const lang: string = to.query.lng ? to.query.lng.toString() : null;

  // verify if the lang is available
  const langIsAvailable = LocaleManager.localeIsAvailable(lang);
  if (!langIsAvailable) {
    const browserLang: string = LocaleManager.getBrowserLanguage(true);
    const defaultLang: string = LocaleManager.localeIsAvailable(browserLang) ? browserLang : 'en';
    LocaleManager.setLocale(defaultLang);
    return merge(to, {
      path: to.fullPath,
      query: {
        lng: defaultLang
      },
      replace: true
    }) as RouteLocationRaw;
  }

  await loadLocaleMessages(lang);
  LocaleManager.setLocale(lang);
  return true;
};

/**
 * Carica la navigazione principale (il main nav)
 *
 * @param {Router} router
 * @returns {Promise<SiteModuleStore['mainNav']>}
 */
export const loadMainNavigation = async (router: Router): Promise<SiteModuleStore['mainNav']> => {
  const helperVueRoute = new VueRoute(router);
  const siteId = appConfigs.api.params.menu;
  const currentMainNav = store.state?.site?.mainNav ?? ({ id: null } as SiteModuleStore['mainNav']);
  console.log('>>>>>>>>>>>><menu>>>>>>>>>>>');

  return new Promise((resolve) => {
    if (siteId !== currentMainNav.id) {
      let menu = [];
      menu = require('@/_static/strings/menu.json');

      console.log(menu);

      const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
        ? unref(LocaleManager.locale)
        : 'en';
      const l = lang === 'en' ? '' : '_' + lang.toUpperCase();

      let menuStrings;
      try {
        menuStrings = require('@/_static/strings/menuStrings' + l + '.json');
      } catch (ex) {
        menuStrings = require('@/_static/strings/menuStrings.json');
      }
      menu[0].label = menuStrings.labels.home;
      menu[1].label = menuStrings.labels.finder;
      menu[2].label = menuStrings.labels.inspire;
      menu[3].label = menuStrings.labels.resources;
      menu[4].label = menuStrings.labels.faq;
      menu[5].label = menuStrings.labels.about;

      const mainNav = {
        id: siteId,
        items: map(menu, (itemNav) => {
          const item = assign({}, itemNav);
          item.label = decodeString(item.label);
          console.log(item.label);

          item.to = helperVueRoute.convertToRouteOrUrl(item.to);
          if (!isNil(item.children)) {
            each(item.children, (itemChild) => {
              itemChild.to = helperVueRoute.convertToRouteOrUrl(itemChild.to);
            });
          }

          return item;
        }),
      } as SiteModuleStore['mainNav'];
      console.log(mainNav);

      store.commit('site/mainNav', mainNav);
      resolve(mainNav);
    } else {
      resolve(currentMainNav);
    }
  });
};
