import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/Icon_Info.svg'
import _imports_1 from '@/assets/icons/Icon_Description.svg'
import _imports_2 from '@/assets/icons/Icon_Combination-funding.svg'
import _imports_3 from '@/assets/icons/Icon_Tips.svg'


const _hoisted_1 = {
  id: "factsheet-lfp",
  style: {"background-color":"#FFFFFF"}
}
const _hoisted_2 = {
  id: "factsheet__lfp",
  class: "ecl-u-mv-m ecl-u-mb-none ecl-u-mt-none ombra-div"
}
const _hoisted_3 = { class: "center-image" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "overlay-image" }
const _hoisted_7 = { class: "text-image" }
const _hoisted_8 = {
  id: "get-inspired",
  class: "ecl-card__body"
}
const _hoisted_9 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_10 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_11 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_12 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_13 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_14 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 1,
  class: ""
}
const _hoisted_17 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 2 }
const _hoisted_23 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = {
  key: 3,
  id: "caseStudyTypology"
}
const _hoisted_26 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_27 = { class: "ecl-tag ecl-tag--display" }
const _hoisted_28 = {
  id: "",
  class: "ecl-u-pt-xl",
  style: {"background-color":"#f3f6fc"}
}
const _hoisted_29 = {
  id: "city-profile",
  class: ""
}
const _hoisted_30 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = {
  key: 0,
  id: "",
  class: "ecl-u-pt-xl",
  style: {"background-color":"#ffffff"}
}
const _hoisted_33 = {
  id: "city-profile",
  class: ""
}
const _hoisted_34 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = {
  key: 1,
  id: "",
  class: "ecl-u-pt-xl",
  style: {"background-color":"#f3f6fc"}
}
const _hoisted_37 = {
  id: "city-profile",
  class: ""
}
const _hoisted_38 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = { key: 2 }
const _hoisted_41 = {
  id: "get-inspired",
  class: "ecl-card__body"
}
const _hoisted_42 = {
  key: 0,
  id: "keywords"
}
const _hoisted_43 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_44 = { class: "ecl-tag ecl-tag--display" }
const _hoisted_45 = { class: "ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m" }
const _hoisted_46 = {
  id: "pillarLtvra",
  class: "contenitore-img",
  style: {"margin-left":"1px"}
}
const _hoisted_47 = ["src", "alt"]
const _hoisted_48 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_ecl_col = _resolveComponent("ecl-col")
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.imageFile)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "ecl-image dim-image",
                src: require(`@/static/images/` + _ctx.imageFile),
                alt: _ctx.row.TITLE
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (!_ctx.imageFile)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "ecl-image dim-image",
                src: require(`@/static/images/Picture_to_repeat_1.png`),
                alt: _ctx.row.TITLE
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.row.TITLE), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ecl_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_col, {
                col: "1",
                class: "ecl-u-ml-m"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("img", {
                    class: "ecl-u-mt-none",
                    style: {"width":"44px","height":"44px"},
                    src: _imports_0,
                    alt: "Icon Info"
                  }, null, -1)
                ])),
                _: 1
              }),
              _createVNode(_component_ecl_column, { col: "8" }, {
                default: _withCtx(() => [
                  _createElementVNode("h6", _hoisted_9, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.modalKey), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_column, {
                col: "12",
                m: "12",
                l: "12",
                class: "ecl-u-mt-none"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.inspireCountry) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.row.COUNTRY), 1)
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.inspireLocation) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.row.LOCATION), 1)
                  ]),
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.inspireOrg) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.row.ORGANISATION), 1)
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.inspireBudget) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.row.BUDGET), 1)
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.inspireFunds) + ": ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.FUND_KEY, (item) => {
                      return (_openBlock(), _createElementBlock("span", null, [
                        (item[2] !== null)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: _normalizeClass(_ctx.classColors[item[2]]),
                              onClick: $event => (_ctx.handleClickFiche(item[0], item[2]))
                            }, _toDisplayString(item[1]), 11, _hoisted_15))
                          : _createCommentVNode("", true),
                        (item[2] == null)
                          ? (_openBlock(), _createElementBlock("a", _hoisted_16, _toDisplayString(item[1]), 1))
                          : _createCommentVNode("", true),
                        _cache[1] || (_cache[1] = _createTextVNode("  "))
                      ]))
                    }), 256))
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.inspireTimeframe) + ": ", 1),
                    _createTextVNode(_toDisplayString(_ctx.row.TIMEFRAME), 1)
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                  (_ctx.row.PROJECT_WEBSITE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.projectWebsite) + ": ", 1),
                          _createElementVNode("span", { innerHTML: _ctx.PROJECT_WEBSITE }, null, 8, _hoisted_20)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.row.PROJECT_WEBSITE && _ctx.row.DATABASE_LINK)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[2] || (_cache[2] = [
                        _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  (_ctx.row.DATABASE_LINK)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.inspireDblink) + ": ", 1),
                          _createElementVNode("span", { innerHTML: _ctx.DATABASE_LINK }, null, 8, _hoisted_24)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.row.CASE_STUDY_TYPOLOGY)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                        _createElementVNode("div", _hoisted_26, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.caseStudy) + ": ", 1)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseStudyTypology, (item) => {
                          return (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(item), 1))
                        }), 256)),
                        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_ecl_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_col, {
                  col: "1",
                  class: "ecl-u-ml-m"
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("img", {
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_1,
                      alt: "Icon Description"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_column, { col: "8" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", _hoisted_30, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.inspireProjectDes), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("p", {
              style: 'text-align: left;',
              class: "ecl-u-type-paragraph-m ecl-u-pb-m",
              innerHTML: _ctx.row.DESCRIPTION
            }, null, 8, _hoisted_31)
          ])
        ]),
        (_ctx.row.COMBINATION_OF_FUNDS)
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_ecl_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_col, {
                      col: "1",
                      class: "ecl-u-ml-m"
                    }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createElementVNode("img", {
                          class: "ecl-u-mt-none",
                          style: {"width":"44px","height":"44px"},
                          src: _imports_2,
                          alt: "Icon Combination Funding"
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ecl_column, { col: "8" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h6", _hoisted_34, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.multiFunds), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("p", {
                  style: 'text-align: left;',
                  class: "ecl-u-type-paragraph-m ecl-u-pb-m",
                  innerHTML: _ctx.row.COMBINATION_OF_FUNDS
                }, null, 8, _hoisted_35)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.row.TIPS_COMBINATION_FUNDS)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createVNode(_component_ecl_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_col, {
                      col: "1",
                      class: "ecl-u-ml-m"
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("img", {
                          class: "ecl-u-mt-none",
                          style: {"width":"44px","height":"44px"},
                          src: _imports_3,
                          alt: "Icon Tips"
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ecl_column, { col: "10" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h6", _hoisted_38, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.tips), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("p", {
                  style: 'text-align: left;',
                  class: "ecl-u-type-paragraph-m ecl-u-pb-m",
                  innerHTML: _ctx.row.TIPS_COMBINATION_FUNDS
                }, null, 8, _hoisted_39)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.row.TIPS_COMBINATION_FUNDS)
          ? (_openBlock(), _createElementBlock("div", _hoisted_40, _cache[15] || (_cache[15] = [
              _createElementVNode("br", null, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_41, [
          (_ctx.KEY_WORDS)
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.inspireKeyw) + ": ", 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.KEY_WORDS, (item) => {
                  return (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(item), 1))
                }), 256)),
                _cache[16] || (_cache[16] = _createElementVNode("hr", { class: "ecl-u-mt-l" }, null, -1))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.inspirePillars) + ": ", 1),
            _createElementVNode("div", _hoisted_46, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.PILLAR_LTVRA, (item) => {
                return (_openBlock(), _createElementBlock("span", null, [
                  _createElementVNode("img", {
                    class: "ecl-u-mt-none ecl-u-mr-s ecl-u-ml-s",
                    style: {"width":"100px","height":"100px"},
                    src: require(`@/assets/icons/${item.img}`),
                    alt: item.des
                  }, null, 8, _hoisted_47),
                  _createElementVNode("div", {
                    class: "ecl-u-type-m ecl-u-mt-none",
                    style: {"text-align":"center"},
                    innerHTML: item.des
                  }, null, 8, _hoisted_48)
                ]))
              }), 256))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_modal)
  ], 64))
}