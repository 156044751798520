    <ecl-container>
      <ecl-row class="ecl-u-pb-l">
        <ecl-column col="12" l="12" xl="12" class="">
          <h1 class="ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl">{{finderTitle}}</h1>          
        </ecl-column>
      </ecl-row>

      <ecl-row class="ecl-u-pt-l">
        <!-- results-->
        <ecl-column col="12" l="7" class="">
  
          <ecl-row class="">
            <ecl-column col="12" l="12" class="">
              <h4 v-if="isMobileTablet" id="financialOpp" class="ecl-u-type-heading-4">{{finderFO}} ({{fiches.length}})</h4>
              <h3 v-if="!isMobileTablet" id="financialOpp" class="ecl-u-type-heading-3">{{finderFO}} ({{fiches.length}})</h3>              
            </ecl-column>
          </ecl-row>
        </ecl-column>
        <!-- end results-->
  
        <!-- search-->
        <ecl-column col="12" l="5" class="">
            <h4 v-if="isMobileTablet" class="ecl-u-type-heading-4">{{finderTaylor}}</h4>
            <h3 v-if="!isMobileTablet" class="ecl-u-type-heading-3">{{finderTaylor}}</h3>
        </ecl-column>
      </ecl-row>

     
      <ecl-row class="ecl-u-mb-none ecl-u-mt-l">
        <!-- search mobile -->
        <ecl-column col="12" l="5" class="ecl-u-mb-s" v-if="isMobileTablet">
          <ecl-row class="">
            <div id="col-chips" style="display: none;">
            <ecl-column col="12" l="12" class="">

              <div class="component-chip-list">
                <div class="chip-list">
                
                  <div v-if="listFilterKind[filterKindSelected-1]" class="chip chip--pointer" @click="resetFilterSelected('K', filterKindSelected[0])">
                    <span class="chip__name">{{filterKindSelectedDes.value}}</span>
                    <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                    <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                  </div>

                  <div v-if="filterTypeActSelected" v-for="(item, index) in filterTypeActSelected" :key="index" class="chip chip--pointer" @click="resetFilterSelected('TA', item)">
                    <span class="chip__name">{{listFilterTypeActOrig[item-1].value}}</span>
                    <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                    <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                  </div>
               
                  <div v-if="listFilterTypeSupportOrig[filterTypeSupportSelected-1]" class="chip chip--pointer" @click="resetFilterSelected('T', filterTypeSupportSelected[0])">
                    <span class="chip__name">{{listFilterTypeSupportOrig[filterTypeSupportSelected-1].valueOrig}}</span>
                    <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                    <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                  </div>

                  <template id="chip" style="display: none;">
                    <div class="chip chip-reset chip--pointer" @click="resetFilters()">
                      <span class="chip__name">{{finderResetF}}</span>
                      <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                      <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                    </div>
                  </template>              
                </div>
              </div>
            </ecl-column> 
            </div>           

            <ecl-column col="12" l="12" class="ecl-u-mb-l">

              <EclAccordion>
                <EclAccordionItem @click="accordionItemToggle()">
                  <template v-slot:trigger>
                    <span>{{finderKind}}</span>
                  </template>
                  <template v-slot:content>
                    <fieldset class="ecl-form-group" aria-describedby="helper-id-K">
                      <div v-for="(item, index) in listFilterKind" :key="index" class="ecl-radio"><input type="radio" :id="'K' + item.id" name="radio-group-K" class="ecl-radio__input"
                        required="" :value="item.id" /><label class="ecl-radio__label" :for="'K' + item.id"><span
                          class="ecl-radio__box" @click="changeSelectedFilterKind(item.id)"><span class="ecl-radio__box-inner"></span></span><span
                          class="ecl-radio__text" @click="changeSelectedFilterKind(item.id)">{{item.value}}</span></label>
                      </div>
                    </fieldset>
                  </template>
                </EclAccordionItem>

                <EclAccordionItem @click="accordionItemToggle()">
                  <template v-slot:trigger>
                    <span>{{finderTA}}</span>
                  </template>
                  <template v-slot:content>
                    <span v-for="(item, index) in listCheckboxes" :key="index">
                      <h7 class="ecl-u-type-heading-7 ecl-u-mb-m ecl-u-mt-m" id="cat"><strong>{{listCheckboxesCat[index]}}</strong></h7>
                      <ecl-checkbox :checkboxes="item" :modelValue="checkbox[index]" @update:modelValue="checkbox[index] = $event"></ecl-checkbox>
                      <br/>
                    </span>
                  </template>
                </EclAccordionItem>

                <EclAccordionItem @click="accordionItemToggle()">
                  <template v-slot:trigger>
                    <span>{{finderTS}}</span>
                  </template>
                  <template v-slot:content>
                    <fieldset class="ecl-form-group" aria-describedby="helper-id-T">
                      <div v-for="(item, index) in listFilterTypeSupport" :key="index" class="ecl-radio"><input type="radio" :id="'T'+item.id" name="radio-group-T" class="ecl-radio__input"
                        required="" :value="item.id" /><label class="ecl-radio__label" :for="'T'+item.id"><span
                          class="ecl-radio__box" @click="changeSelectedFilterTypeSupport(item.id)"><span class="ecl-radio__box-inner"></span></span><span
                          class="ecl-radio__text" @click="changeSelectedFilterTypeSupport(item.id)" v-html="item.value"></span></label>
                      </div>
                    </fieldset>
                   
                  </template>
                </EclAccordionItem>
              </EclAccordion>
            </ecl-column>
          </ecl-row>
        </ecl-column>
        
        <!--end search-->

        <!-- results-->
        <ecl-column col="12" l="7" class="">
          <ecl-row>
            <ecl-column col="12" l="12" class="ecl-u-mb-none">

              <div v-for="(item, index) in typeArrays" :key="index">
                <ecl-row v-if="fichesGrouped[index].length > 0">
                  <!--ecl-column col="12" l="3" m="3" class="ecl-u-mb-l ecl-u-mb-m-none">
                    <div
                      class="ecl-card__image"
                      aria-label="card image"
                      role="img"
                      :style="{'background-image': `url(${require(`@/assets/icons/${icons[index]}`)})`}"
                    ></div>
                  </ecl-column-->
                  <ecl-column col="12" l="12" m="12" class="ecl-u-mb-xl">
                    <article class="ecl-card-rural ecl-card--pub ecl-u-mb-none" 
                    style="background-color: #f1f5fc;"
                    >
                    <div class="ecl-card__body ecl-u-mb-none">
                      <h3 class="ecl-content-block__title" v-html="typeArraysDes[index]"></h3>
                      <span class="ecl-u-mt-m ecl-u-mb-l ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-2"></span>
                      <ecl-row>
                        <ecl-column col="12" l="4" class="ecl-u-mb-none ecl-u-pl-xs ecl-u-pr-xs" v-for="(itemfg, indexfg) in fichesGrouped[index]" :key="indexfg">
                          <div v-if="!showTooltip & itemfg.FICHE_LONGTITLE_FUND !== null" class="popper">{{itemfg.FICHE_LONGTITLE_FUND}}</div>

                          <div style="padding-bottom: .8rem; height: 95px;">
                            <div v-if="itemfg.FICHE_LONGTITLE_FUND !== null" class="tooltip-found" :id="'tooltip-fiche' + itemfg.FUNDID">{{itemfg.FICHE_LONGTITLE_FUND}}</div>

                            <article :id="'fiche' + itemfg.FUNDID" class="ecl-card ecl-card--pub" :class="classBkgColors[index]"  
                            href=""
                            @click="handleClickFiche(itemfg.FUNDID, index)">
                              <div id="parent">
                                <div id="child" style="font-size: 19px;" class="ecl-u-type-align-center ecl-u-type-bold" :class="classTextColors[index]">{{itemfg.FICHE_SHORTTITLE_FUND}}
                                </div>
                              </div>

                              <!--div id="parent" v-if="showTooltip">
                                <div v-tooltip="{ content: itemfg.FICHE_LONGTITLE_FUND }" id="child" class="ecl-u-type-align-center ecl-u-type-bold" :class="classTextColors[index]">{{itemfg.FICHE_SHORTTITLE_FUND}} 
                                </div>
                              </div>
                              <div id="parent" v-if="!showTooltip">
                                <div id="child" class="ecl-u-type-align-center ecl-u-type-bold" :class="classTextColors[index]">{{itemfg.FICHE_SHORTTITLE_FUND}}
                                </div>
                              </div-->
                            </article>
                          </div>

                        </ecl-column>
                      </ecl-row>
                    </div>
                    </article>
                  </ecl-column>
                </ecl-row>
              </div>
      
            </ecl-column>
          </ecl-row>
        </ecl-column>
        <!-- end results-->
     
        <!-- search pc -->
        <ecl-column col="12" l="5" class="ecl-u-mb-s" v-if="!isMobileTablet">
          <ecl-row class="">
            <div id="col-chips" style="display: none;">
            <ecl-column col="12" l="12" class="">

              <div class="component-chip-list">
                <div class="chip-list">
                
                  <div v-if="listFilterKind[filterKindSelected-1]" class="chip chip--pointer" @click="resetFilterSelected('K', filterKindSelected[0])">
                    <span class="chip__name">{{filterKindSelectedDes.value}}</span>
                    <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                    <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                  </div>

                  <div v-if="filterTypeActSelected" v-for="(item, index) in filterTypeActSelected" :key="index" class="chip chip--pointer" @click="resetFilterSelected('TA', item)">
                    <span class="chip__name">{{listFilterTypeActOrig[item-1].value}}</span>
                    <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                    <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                  </div>

                  <div v-if="listFilterTypeSupportOrig[filterTypeSupportSelected-1]" class="chip chip--pointer" @click="resetFilterSelected('T', filterTypeSupportSelected[0])">
                    <span class="chip__name">{{listFilterTypeSupportOrig[filterTypeSupportSelected-1].valueOrig}}</span>
                    <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                    <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                  </div>

                  <template id="chip" style="display: none;">
                    <div class="chip chip-reset chip--pointer" @click="resetFilters()">
                      <span class="chip__name">{{finderResetF}}</span>
                      <ecl-icon class="chip__icon" icon="close" size="xs"></ecl-icon>
                      <ecl-icon class="chip__icon chip__icon--hover" icon="close-filled" size="xs"></ecl-icon>
                    </div>
                  </template>              
                </div>
              </div>
            </ecl-column> 
            </div>           

            <ecl-column col="12" l="12" class="ecl-u-mb-l">

              <EclAccordion>
                <EclAccordionItem @click="accordionItemToggle()">
                  <template v-slot:trigger>
                    <span>{{finderKind}}</span>
                  </template>
                  <template v-slot:content>
                    <fieldset class="ecl-form-group" aria-describedby="helper-id-K">
                      <div v-for="(item, index) in listFilterKind" :key="index" class="ecl-radio"><input type="radio" :id="'K' + item.id" name="radio-group-K" class="ecl-radio__input"
                        required="" :value="item.id" /><label class="ecl-radio__label" :for="'K' + item.id"><span
                          class="ecl-radio__box" @click="changeSelectedFilterKind(item.id)"><span class="ecl-radio__box-inner"></span></span><span
                          class="ecl-radio__text" @click="changeSelectedFilterKind(item.id)">{{item.value}}</span></label>
                      </div>
                    </fieldset>
                  </template>
                </EclAccordionItem>

                <EclAccordionItem @click="accordionItemToggle()">
                  <template v-slot:trigger>
                    <span>{{finderTA}}</span>
                  </template>
                  <template v-slot:content>
                    <span v-for="(item, index) in listCheckboxes" :key="index">
                      <h7 class="ecl-u-type-heading-7 ecl-u-mb-m ecl-u-mt-m" id="cat"><strong>{{listCheckboxesCat[index]}}</strong></h7>
                      <ecl-checkbox :checkboxes="item" :modelValue="checkbox[index]" @update:modelValue="checkbox[index] = $event"></ecl-checkbox>
                      <br/>
                    </span>
                  </template>
                </EclAccordionItem>

                <EclAccordionItem @click="accordionItemToggle()">
                  <template v-slot:trigger>
                    <span>{{finderTS}}</span>
                  </template>
                  <template v-slot:content>
                    <fieldset class="ecl-form-group" aria-describedby="helper-id-T">
                      <div v-for="(item, index) in listFilterTypeSupport" :key="index" class="ecl-radio"><input type="radio" :id="'T'+item.id" name="radio-group-T" class="ecl-radio__input"
                        required="" :value="item.id" /><label class="ecl-radio__label" :for="'T'+item.id"><span
                          class="ecl-radio__box" @click="changeSelectedFilterTypeSupport(item.id)"><span class="ecl-radio__box-inner"></span></span><span
                          class="ecl-radio__text" @click="changeSelectedFilterTypeSupport(item.id)" v-html="item.value"></span></label>
                      </div>
                    </fieldset>
                   
                  </template>
                </EclAccordionItem>
                <EclAccordionItem style="display:none">
                </EclAccordionItem>
              </EclAccordion>
            </ecl-column>
          </ecl-row>
        </ecl-column>
        <!--end search-->
    
    </ecl-row>
    
    <br/><br/>
  
  </ecl-container>
  <modal></modal>
  