import { createRouter, createWebHistory, RouteLocationRaw, Router, RouteRecordRaw } from 'vue-router';
import { store } from '@/store';
import Home from '@/views/Home/Home.vue';
import About from '../components/About/About.component.vue';
import Finder from '../components/Finder/Finder.component.vue';
import Inspire from '../components/Inspire/Inspire.component.vue';
import Resource from '../components/Resource/Resource.component.vue';
import FQ from '../components/Fq/Fq.component.vue';
import Accessibility from '../components/Accessibility-statement/Accessibility-statement.component.vue';
import { AppManager, LocaleManager } from '@/managers';

import { languageCheck, loadMainNavigation } from './contentLoader';
import { ref, unref } from 'vue';
import useModal from './composables/useModal';

// di default en così l'alert non si vede mai per EN
const languageAlertShowedFor = ref(['en']);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      label: 'about.title'
    },
    component: About
  },
  {
    path: '/finder',
    name: 'finder',
    meta: {
      label: 'finder.title'
    },
    component: Finder
  },
  {
    path: '/inspire',
    name: 'inspire',
    meta: {
      label: 'button.inspire'
    },
    component: Inspire
  },
  {
    path: '/resources',
    name: 'resources',
    meta: {
      label: 'button.resources'
    },
    component: Resource
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
      label: 'FAQ.title'
    },
    component: FQ
  },
  {
    path: '/accessibility-statement',
    name: 'accessibility-statement',
    meta: {
      label: 'Accessibility statement'
    },
    component: Accessibility
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not_found',
    component: () => import('../views/404/404.vue')
  }
];

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => {
    return { top: 0 };
  },
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.path !== from.path) {
    store.commit('site/loading', true);
  }

  const fromName = from.name?.toString() || '';
  const toName = to.name?.toString() || '';

  document.body.classList.remove(`page--${fromName}`);
  document.body.classList.add(`page--${toName}`);

  // Carica le lingue, le traduzioni e verifica la lingua dell'utente
  // se non impostata utilizza quella di default
  const validLanguage = await languageCheck(to);
  if (typeof validLanguage === 'object') {
    return next(validLanguage as RouteLocationRaw);
  }

  // Show Language Translation Alert
  const currLanguageCode = LocaleManager.getCurrentCode();
  if (unref(languageAlertShowedFor).includes(currLanguageCode) === false) {
    const { alertETranslation } = useModal();
    alertETranslation();
    languageAlertShowedFor.value.push(currLanguageCode);
  }

  // const routeName = to?.name;
  // if (routeName) {
  //   const pageConfig = store.getters['site/pageConfig'](routeName);
  //   if (pageConfig?.header) {
  //     SEOManager.setTitles(pageConfig.header?.title ?? '');
  //     SEOManager.setDescriptions(pageConfig.header?.description ?? '');
  //   }
  // }

  await loadMainNavigation(router);

  store.commit('site/loading', false);

  return next();
});

router.afterEach(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $wt = (window as any).$wt;
  if ($wt && $wt.analytics.isTrackable()) {
    $wt.trackPageView();
  }
  AppManager.stopLoading();
});

export default router;
