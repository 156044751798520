import { nextTick, unref } from 'vue';
import { createI18n } from 'vue-i18n';
import { LocaleManager } from '@/managers';
import store from '@/store';
import { isNil } from 'lodash';

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  warnHtmlMessage: false,
  missingWarn: false,
  fallbackWarn: false
});

const t = (key: string, ...args) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key, args);
};

export async function loadLocales() {
  if (LocaleManager.getLanguages().value.length === 0) {
    return new Promise((resolve) => {
      let languages;
      try {
        languages = require('@/_static/strings/languages.json');
      } catch (ex) {
        languages = require('@/_static/strings/languages.json');
      }
      LocaleManager.setLanguages(languages.data.site.languageList);

      resolve(true);
    });
  } else {
    return nextTick();
  }
}

export async function loadLocaleMessages(locale: string) {
  if (!i18n.global.availableLocales.includes(locale)) {
    return new Promise((resolve) => {
      LocaleManager.setLocale(locale);

      const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
        ? unref(LocaleManager.locale)
        : 'en';
      const l = lang === 'en' ? '' : '_' + lang.toUpperCase();

      let strings;
      try {
        strings = require('@/_static/strings/stringsFromRT' + l + '.json');
      } catch (ex) {
        strings = require('@/_static/strings/stringsFromRT.json');
      }

      LocaleManager.setLocaleMessage(locale, strings);
      store.commit('site/i18nLoading', false);
      resolve(true);
    });
  } else {
    LocaleManager.setLocale(locale);
    return nextTick();
  }
}

export default i18n;
export { t };
