<ecl-modal v-model="showPopup" id="modal-village">
  <template v-if="found.FICHE_LONGTITLE_FUND != null" v-slot:title>{{ found.FICHE_SHORTTITLE_FUND + ': ' + found.FICHE_LONGTITLE_FUND}}</template>
  <template v-if="found.FICHE_LONGTITLE_FUND == null" v-slot:title>{{ found.FICHE_SHORTTITLE_FUND }}</template>  
  <template v-slot:body v-if="foundSurvey">
    <ecl-loader v-if="loadPDF === true" background="transparent" :panel="true"></ecl-loader>
    <div id="topDiv"></div>

    <div class="printButton">
      <div class="printButtonBlock">    
        <ecl-button
        class="ecl-u-mt-m"
        icon="print"
        iconSize="s"
        :type="EclButtonType.Secondary"
        @click="createPDF()"
        target="_blank"
        v-tooltip="{ content: 'Print' }"
        alt="Print"
        title="Print"
        aria-label="Print"
        ></ecl-button>
      </div>
    </div>

    <article class="ecl-card">
      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Info.svg"
            alt="Icon Info"
            />
          </ecl-col>

          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalKey}}</strong></h6>
          </ecl-column>
        </ecl-row>
        <ecl-row>
          <ecl-column col="12" m="12" l="12" class="ecl-u-mt-l">
            <h7 class="ecl-u-type-heading-7"><strong>{{modalTotalB}}&nbsp;</strong>{{ found.FICHE_BUDGET }}</h7>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>
            <h7 class="ecl-u-type-heading-7"><strong>{{modalTypeF}}&nbsp;</strong></h7><span v-html="FICHE_TYPEFUND"></span>
            <span v-if="found.FICHE_COFIN">
              <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>
              <h7 class="ecl-u-type-heading-7"><strong>{{modalCfp}}&nbsp;</strong></h7>
              <span v-html="FICHE_COFIN"></span>
            </span>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>
            <h7 class="ecl-u-type-heading-7"><strong>{{modalCoverage}}&nbsp;</strong>{{ found.FICHE_PERIOD }}</h7>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>
            <!--h7 class="ecl-u-type-heading-7"><strong>Funding support initiatives:&nbsp;&nbsp;</strong>
            </h7><br/-->
            
            <ecl-button
            class="ecl-u-mt-xs"
            icon="external"
            iconSize="xs"
            :text="buttonOWP"
            :ariaLabel="buttonOWP"
            :type="EclButtonType.Primary"
            :url="FICHE_WEBFUND"
            target="_blank"
            ></ecl-button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ecl-button v-if="found.FICHE_FMBMAIL"
            class="ecl-u-mt-m"
            icon="external"
            iconSize="xs"
            :text="modalContact"
            :ariaLabel="modalContact"
            :type="EclButtonType.Primary"
            :url="FICHE_FMBMAIL"
            target="_blank"
            ></ecl-button>
          </ecl-column>        
        </ecl-row>

      </div>
    </article>
    <br/>
    <article class="ecl-card-rural" v-if="found.FICHE_DESCRGENERAL">
      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Description.svg"
            alt="Icon Description"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalDesF}}</strong></h6>
          </ecl-column>
        </ecl-row>

          <paragraph-more-less
          :breakAbstract="true"
          :breakAbstractCount="FICHE_DESCRGENERAL_BREAK"
          :abstract="FICHE_DESCRGENERAL">
          </paragraph-more-less>
      </div>
    </article>

    <br/>
    <article class="ecl-card-rural" v-if="found.FICHE_DESCRRURAL">
      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Relevance.svg"
            alt="Icon Relevance"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalWhy}}</strong></h6>
          </ecl-column>
        </ecl-row>

          <paragraph-more-less
          :breakAbstract="true"
          :breakAbstractCount="190"
          :abstract="FICHE_DESCRRURAL">
          </paragraph-more-less>
      </div>
    </article>
    <br/>
    <article class="ecl-card" v-if="found.FICHE_WHO">
      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Who.svg"
            alt="Icon Who"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalWho}}</strong></h6>
          </ecl-column>
        </ecl-row>

          <paragraph-more-less
          :breakAbstract="true"
          :breakAbstractCount="290"
          :abstract="FICHE_WHO">
          </paragraph-more-less>
      </div>
    </article>
    <br/>

    <article class="ecl-card" v-if="found.FICHE_CALLSWEB">
      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Calls.svg"
            alt="Icon Calls"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalCalls}}</strong></h6>
          </ecl-column>
        </ecl-row>
        <ecl-row>
          <ecl-column col="12" m="12" l="12" v-if="found.FICHE_CALLSWEB">
            <div  v-for="(itemc, indexc) in calls" :key="indexc" class="ecl-u-mt-m">
              <!--h7 class="ecl-u-type-heading-7"><strong>Actions managed by the European Commission:</strong>
              </h7><br/-->
              <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-l ecl-u-mb-xs" v-if="indexc > 0"></span>                  

              <div v-if="itemc.cText != ''">
                <h7 class="ecl-u-type-heading-7"><strong v-html="itemc.cText"></strong>
                </h7><br/><br/>
              </div>
              <ecl-button
              class="ecl-u-mt-none"
              icon="external"
              iconSize="xs"
              :text="buttonOWP"
              :ariaLabel="buttonOWP"
              :type="EclButtonType.Primary"
              :url="itemc.cLink"
              target="_blank"
              ></ecl-button>
              <br/>
            </div>
          </ecl-column>
        </ecl-row>
      </div>
    </article>

    <br v-if="FICHE_SUPPLAPPLWEB != null || FICHE_NATIONCONTACT != null"/>
    <article class="ecl-card" v-if="FICHE_SUPPLAPPLWEB != null || FICHE_NATIONCONTACT != null">

      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_More-info.svg"
            alt="Icon More Info"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalFurther}}</strong></h6>
          </ecl-column>
        </ecl-row>

                  <span v-if="FICHE_SUPPLAPPLWEB">
                    <ecl-button v-if="!suppMecMore && FICHE_SUPPLAPPLWEB"
                    class="ecl-u-mt-m"
                    icon="external"
                    iconSize="xs"
                    :text="modalSuppMec"
                    :ariaLabel="modalSuppMec"
                    :type="EclButtonType.Primary"
                    :url="FICHE_SUPPLAPPLWEB"
                    target="_blank"
                    ></ecl-button>    
                    <ecl-button v-if="suppMecMore && FICHE_SUPPLAPPLWEB"
                    class="ecl-u-mt-m"
                    icon="corner-arrow"
                    iconRotate="90"
                    iconSize="xs"
                    :text="modalSuppMec"
                    :ariaLabel="modalSuppMec"
                    :type="EclButtonType.Primary"
                    @click="performOpenModalSuppMec()"
                    target="_blank"
                    ></ecl-button>
                  <br/>
                </span>
                    <ecl-modal v-model="showModalSuppMec">
                      <template v-slot:title>{{modalSuppMec}}</template>
                      <template v-slot:body>
                        <!--ecl-p class="ecl-u-mt-none ecl-u-mb-none">AAAA</ecl-p-->
                        <div  v-for="(items, indexs) in suppMec" :key="indexs" class="ecl-u-mt-m">
                          <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-l ecl-u-mb-xs" v-if="indexs > 0"></span>
                          <h7 class="ecl-u-type-heading-7"><strong>{{items.cText}}</strong>
                          </h7><br/>
                          <ecl-button
                          class="ecl-u-mt-m"
                          icon="external"
                          iconSize="xs"
                          :text="buttonOWP"
                          :ariaLabel="buttonOWP"
                          :type="EclButtonType.Primary"
                          :url="items.cLink"
                          target="_blank"
                          ></ecl-button>
                          <br/>
                        </div>
                        
                      </template>
                    </ecl-modal>
                  <!--/div>
                </div-->
                
                <!--div class="vl furtherInfoBlock" v-if="found.FICHE_NATIONCONTACT">
                  <div id="furthInf" class="ecl-card__body"-->
                  <!--h7 class="ecl-u-type-heading-7"><strong>{{modalNationalCP}}<br/></strong></h7-->

                  <ecl-button v-if="!natContMore && FICHE_NATIONCONTACT"
                    class="ecl-u-mt-m"
                    icon="external"
                    iconSize="xs"
                    :text="modalNationalCP"
                    :ariaLabel="modalNationalCP"
                    :type="EclButtonType.Primary"
                    :url="FICHE_NATIONCONTACT"
                    target="_blank"
                    ></ecl-button>

                  <ecl-button v-if="natContMore && FICHE_NATIONCONTACT"
                  class="ecl-u-mt-m"
                  icon="corner-arrow"
                  iconRotate="90"
                  iconSize="xs"
                  :text="modalNationalCP"
                  :ariaLabel="modalNationalCP"
                  :type="EclButtonType.Primary"
                  @click="performOpenModalNatCont()"
                  target="_blank"
                  ></ecl-button>

                  <ecl-modal v-model="showModalNatCont">
                    <template v-slot:title>{{modalNationalCP}}</template>
                    <template v-slot:body>
                      <!--ecl-p class="ecl-u-mt-none ecl-u-mb-none">AAAA</ecl-p-->
                      <div  v-for="(items, indexs) in natCont" :key="indexs" class="ecl-u-mt-m">
                        <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-l ecl-u-mb-xs" v-if="indexs > 0"></span>
                        <h7 class="ecl-u-type-heading-7"><strong>{{items.cText}}</strong>
                        </h7><br/>
                        <ecl-button
                        class="ecl-u-mt-m"
                        icon="external"
                        iconSize="xs"
                        :text="buttonOWP"
                        :ariaLabel="buttonOWP"
                        :type="EclButtonType.Primary"
                        :url="items.cLink"
                        target="_blank"
                        ></ecl-button>
                        <br/>
                      </div>
                    </template>
                   </ecl-modal>

                  <!--/div>
                </div>
              </div-->
      </div>
    </article>
    
    <br  v-if="found.FICHE_EX_PROJTITLE_1"/>
    <article class="ecl-card-rural" v-if="found.FICHE_EX_PROJTITLE_1">

      <div class="ecl-card__body">
        <ecl-row class="ecl-u-mb-s">
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Project.svg"
            alt="Icon Project"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalProjectEx}}</strong></h6>
          </ecl-column>
        </ecl-row>

          <div v-if="found.FICHE_EX_PROJTITLE_1">
            <ecl-row>
              <ecl-column col="12" m="12" l="12" class="">
                <h7 class="ecl-u-type-heading-7 ecl-u-mt-s ecl-u-mb-s"><strong v-html="FICHE_EX_PROJTITLE_1"></strong></h7><br/>
                <ecl-p
                class="paragraph_list__item"
                v-html="found.FICHE_EX_PROJ2L_1"
                ></ecl-p>

                <ecl-button
                v-if="found.FICHE_EX_PROJWEB_1"
                icon="external"
                iconSize="xs"
                :text="modalGoProjectWeb"
                :ariaLabel="modalGoProjectWeb"
                :type="EclButtonType.Primary"
                :url="FICHE_EX_PROJWEB_1"
                target="_blank"
              ></ecl-button>                
              </ecl-column>
            </ecl-row> 
          </div>

          <div v-if="found.FICHE_EX_PROJTITLE_2">
            <ecl-row>
              <ecl-column col="12" m="12" class="">
                <span class="ecl-u-mt-m ecl-u-mb-s ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20"></span>
              </ecl-column>
            </ecl-row>
            <ecl-row>
              <ecl-column col="12" m="12" l="12" class="">
                <h7 class="ecl-u-type-heading-7 ecl-u-mt-s"><strong  v-html="FICHE_EX_PROJTITLE_2"></strong></h7><br/>
                <ecl-p
                class="paragraph_list__item"
                v-html="found.FICHE_EX_PROJ2L_2"
                ></ecl-p>

                <ecl-button
                v-if="found.FICHE_EX_PROJWEB_2"
                icon="external"
                iconSize="xs"
                :text="modalGoProjectWeb"
                :ariaLabel="modalGoProjectWeb"
                :type="EclButtonType.Primary"
                :url="FICHE_EX_PROJWEB_2"
                target="_blank"
              ></ecl-button>
              </ecl-column>
            </ecl-row> 
          </div>          
      </div>
    </article>

    <br/>

    <!--article class="ecl-card" v-if="found.FICHE_COFINGUIGEWEB" >
      <div class="ecl-card__body">
        <ecl-row>
         
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Combination-funding.svg"
            />            
          </ecl-col>
          
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalCombinationF}}</strong></h6>
          </ecl-column>
        </ecl-row-->
        
          <!--div class="ecl-u-mt-l">
            <ecl-row>
              <ecl-column col="12" m="12" l="12" class="ecl-u-mb-s">
                <h7 class="ecl-u-type-heading-7 ecl-u-mt-l"><strong>{{modalOtherProg}}</strong></h7>
                <br/>

                <ecl-row class="ecl-u-mt-l">
                  <ecl-column col="12" l="2" class="ecl-u-mb-none" v-for="(itemfg, indexfg) in combinationFund" :key="indexfg">
                    <div style="padding-bottom: .8rem; width: 90px; height: 60px;">
                      <article id="fiche" class="ecl-card ecl-card--pub" 
                      :class="itemfg.bkgColor"
                      href=""
                      @click="handleClickFicheModal(itemfg.FUNDID, itemfg.indexColor)">
                      <div id="parent">
                        <div id="child" class="ecl-u-type-align-center ecl-u-type-bold" :class="itemfg.textColor">{{itemfg.FICHE_SHORTTITLE}}</div>
                      </div>
                      </article>
                    </div>
                  </ecl-column>
                </ecl-row>

              </ecl-column>
            </ecl-row> 
          </div-->
        <!--
          <div v-if="found.FICHE_COFINGUIGEWEB" class="ecl-u-mt-l">
           
            <ecl-row>
              <ecl-column col="12" m="12" l="12" class="ecl-u-mb-s">
                <h7 class="ecl-u-type-heading-7 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalGuidance}}</strong></h7>
                <br/>
                <ecl-button
                class="ecl-u-mt-m"
                icon="external"
                iconSize="xs"
                :text="buttonOWP"
                :ariaLabel="buttonOWP"
                :type="EclButtonType.Primary"
                :url="found.FICHE_COFINGUIGEWEB"
                target="_blank"
              ></ecl-button>
              </ecl-column>
            </ecl-row> 
          </div>

      </div>
    </article-->

    <article class="ecl-card">
      <div class="ecl-card__body">
        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Additional-resources.svg"
            alt="Icon Additional Resources"
            />
          </ecl-col>
          <ecl-column col="11">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalAddRes}}</strong></h6>
          </ecl-column>
        </ecl-row>

          <div>
                <ecl-button
                class="ecl-u-mt-m"
                icon="corner-arrow"
                iconRotate="90"
                iconSize="xs"
                :text="buttonDiscover"
                :type="EclButtonType.Primary"
                url="/resources?lng=en"
                target="_blank"
              ></ecl-button>

          </div>

      </div>
    </article>
    &nbsp;
    <ecl-row>
      <ecl-col col="1">
    <img
    id="iconinfo"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Info_w.svg"
    alt="Icon Info"
    />
  </ecl-col>
  <ecl-col col="1">
    <img
    id="icondes"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Description_w.svg"
    alt="Icon Description"
    />
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconrel"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Relevance_w.svg"
    alt="Icon Relevance"
    />
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconwho"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Who_w.svg"
    alt="Icon Who"
    />
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconcalls"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Calls_w.svg"
    alt="Icon Calls"
    />
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconmore"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_More-info_w.svg"
    alt="Icon More Info"
    />
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconprj"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Project_w.svg"
    alt="Icon Project"
    />
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconcofu"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Combination-funding_w.svg"
    alt="Icon Combination Funding"
    />            
  </ecl-col>
  <ecl-col col="1">    
    <img
    id="iconaddres"
    class="ecl-u-mt-none"
    style="width: 44px; height: 44px; "
    src="@/assets/icons/Icon_Additional-resources_w.svg"
    alt="Icon Additional Resources"
    />    
  </ecl-col></ecl-row>

  </template>

</ecl-modal>
