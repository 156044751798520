import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ecl-container" }
const _hoisted_2 = { class: "ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl" }

export function render(_ctx, _cache) {
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _component_EclAccordionItem = _resolveComponent("EclAccordionItem")
  const _component_EclAccordion = _resolveComponent("EclAccordion")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ecl_row, { class: "ecl-u-pb-l" }, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_column, {
          col: "12",
          l: "12",
          xl: "12",
          class: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ecl_row, { class: "ecl-u-mt-xl ecl-u-pt-xl ecl-u-pb-xl ecl-u-mb-xl" }, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_column, {
          col: "12",
          m: "3",
          class: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "ecl-card__image",
              "aria-label": "card image",
              role: "img",
              style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_FAQ.svg`)})`})
            }, null, 4)
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_column, {
          col: "12",
          m: "9",
          class: "ecl-u-pb-l ecl-u-mt-m"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EclAccordion, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (item, index) => {
                  return (_openBlock(), _createBlock(_component_EclAccordionItem, { key: index }, {
                    trigger: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(item), 1)
                    ]),
                    content: _withCtx(() => [
                      _createVNode(_component_ecl_p, {
                        innerHTML: _ctx.dess[index]
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createVNode(_component_EclAccordionItem, { style: {"display":"none"} }, {
                  trigger: _withCtx(() => _cache[0] || (_cache[0] = [])),
                  content: _withCtx(() => _cache[1] || (_cache[1] = [])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}