import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_h = _resolveComponent("ecl-h")
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _component_EclAccordionItem = _resolveComponent("EclAccordionItem")
  const _component_EclAccordion = _resolveComponent("EclAccordion")
  const _component_ecl_container = _resolveComponent("ecl-container")

  return (_openBlock(), _createBlock(_component_ecl_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ecl_row, { class: "ecl-u-pb-l" }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            l: "12",
            xl: "12",
            class: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('about.title')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ecl_row, { class: "ecl-u-pt-xl" }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "3",
            class: "ecl-u-pt-none"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "ecl-card__image",
                "aria-label": "card image",
                role: "img",
                style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_About-1.svg`)})`})
              }, null, 4)
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "9",
            class: "ecl-u-pt-l"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_h, {
                level: "3",
                class: "ecl-u-mt-none"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.aboutWhy), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ecl_p, { innerHTML: _ctx.aboutWhyText }, null, 8, ["innerHTML"])
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "12",
            class: ""
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "ecl-u-pt-s ecl-u-pb-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ecl_row, { class: "ecl-u-pt-xl" }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "3",
            class: "ecl-u-pt-none"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "ecl-card__image",
                "aria-label": "card image",
                role: "img",
                style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_About-2.svg`)})`})
              }, null, 4)
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "9",
            class: "ecl-u-pt-l"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_h, {
                level: "3",
                class: "ecl-u-mt-none"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.aboutHow), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ecl_p, { innerHTML: _ctx.aboutHowText }, null, 8, ["innerHTML"])
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "12",
            class: ""
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "ecl-u-pt-s ecl-u-pb-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1" }, null, -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ecl_row, { class: "ecl-u-pt-xl" }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "3",
            class: "ecl-u-pt-none"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "ecl-card__image",
                "aria-label": "card image",
                role: "img",
                style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_About-3.svg`)})`})
              }, null, 4)
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_column, {
            col: "12",
            m: "9",
            class: "ecl-u-pt-l ecl-u-mb-l"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_h, {
                level: "3",
                class: "ecl-u-mt-none"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.aboutWhat), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ecl_p, { innerHTML: _ctx.aboutWhatText }, null, 8, ["innerHTML"]),
              _createVNode(_component_EclAccordion, null, {
                default: _withCtx(() => [
                  _createVNode(_component_EclAccordionItem, null, {
                    trigger: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.aboutWhatDirect), 1)
                    ]),
                    content: _withCtx(() => [
                      _createVNode(_component_ecl_p, { innerHTML: _ctx.aboutWhatDirectText }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_EclAccordionItem, null, {
                    trigger: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.aboutWhatIndirect), 1)
                    ]),
                    content: _withCtx(() => [
                      _createVNode(_component_ecl_p, { innerHTML: _ctx.aboutWhatIndirectText }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_EclAccordionItem, null, {
                    trigger: _withCtx(() => [
                      _createElementVNode("span", { innerHTML: _ctx.aboutWhatFinancial }, null, 8, _hoisted_2)
                    ]),
                    content: _withCtx(() => [
                      _createVNode(_component_ecl_p, { innerHTML: _ctx.aboutWhatFinancialText }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_EclAccordionItem, { style: {"display":"none"} }, {
                    trigger: _withCtx(() => _cache[2] || (_cache[2] = [])),
                    content: _withCtx(() => _cache[3] || (_cache[3] = [])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
    ]),
    _: 1
  }))
}