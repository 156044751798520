    <div class="tools__search">
      <div style="width: 100%;">
        <div id="searchPanelTop" style="position: relative;">

          <div class="contenitore" style="margin-left: 16px;">
            <div class="div-fisso">
              <!--svg height="25" width="25">
                <circle cx="9" cy="11" r="7" stroke="black" stroke-width="0" fill="#f1c200" />
              </svg-->
              <label class="toggle" style="vertical-align: top;">
                <input class="toggle-checkbox" type="checkbox" id="sud" checked @click="monoToggle">
                <div class="toggle-switch"></div>
                <span class="toggle-label">{{monoFunds}}</span>
              </label>                            
              &nbsp;&nbsp;
              <!--svg height="25" width="25" >
                <circle cx="9" cy="11" r="7" stroke="black" stroke-width="0" fill="#e43636" />
              </svg-->
              <label class="toggle" style="vertical-align: top;">
                <input class="toggle-checkbox" type="checkbox" id="nonSud" checked @click="combToggle">
                <div class="toggle-switch"></div>
                <span class="toggle-label">{{multiFunds}}</span>
              </label>
            </div>
            <div class="div-margine-destro">
              {{textTotal}}: {{totalCount}}
            </div>
          </div>

          <div class="contenitore" style="margin-left: 16px;">
            <div class="div-fisso">
              <div v-for="p in listLtvra">
                <svg height="25" width="25" style="padding-top: 2px;">
                  <circle cx="9" cy="11" r="7" stroke="black" stroke-width="0" :fill="p.color" />
                </svg>
                <label class="toggle" style="vertical-align: top;">
                  <input class="toggle-checkbox" type="checkbox" :id="p.id" checked @click="pillarToggle(p.id)">
                  <div class="toggle-switch"></div>
                  <span class="toggle-label">{{p.value}}</span>
                </label>
                &nbsp;&nbsp;
              </div>
          </div>
        </div>
          
          <div class="container-search-fields">

                <jcl-autocomplete
                class="jcl-autocomplete--icon"
                labelKey="value"
                valueKey="id"
                size="s"
                theme="light"
                :showClearButton=true
                :searchPlaceholder="inspireKeyw"
                placeholderShow="false"
                :list="listFilterTypeAct"
                @selected="changeKey"
                v-model="keySelected"
                >
                </jcl-autocomplete>                

                <jcl-autocomplete
                class="jcl-autocomplete--icon"
                labelKey="value"
                valueKey="id"
                size="s"
                theme="light"
                :showClearButton=true
                :searchPlaceholder="inspireFunds"
                placeholderShow="false"
                :list="listFilterFund"
                @selected="changeFund"
                v-model="fundSelected"
                >
                </jcl-autocomplete>

                  <ecl-button
                    class=""
                    type="secondary"
                    @click="resetFilters"
                    v-tooltip="{ content: inspireRemoveFilters }"
                    aria-label="Reset filters"
                  >
                      <udp-icon class="ecl-icon ecl-icon--s" name="removefilter-x-red"></udp-icon>
                  </ecl-button>


            <br v-if="isMobileTablet">
            <br v-if="isMobileTablet">

          </div>
    
          <br>
             
        </div>

      </div>
    </div>

