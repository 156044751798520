<div class="ecl-container">
  <h1 class="ecl-page-header-harmonised__title ecl-u-mt-xl" v-html="title"></h1>

  <ecl-row class="ecl-u-mt-2xl" >
    <ecl-column col="12" l="12" xl="12" class="ecl-u-mb-m">
      <ecl-p v-html="s1"></ecl-p>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-mt-s" v-for="(item, index) in sections" :key="index">
    <ecl-column col="12" l="12" xl="12" class="ecl-u-mb-s">

      <h2 class="ecl-u-type-heading-2 " v-html="titles[index]"></h2>
      <ecl-p v-html="item"></ecl-p>
    </ecl-column>
  </ecl-row>
  <br/><br/>

</div>
