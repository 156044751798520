import { isString, isNil } from 'lodash';
import { LocaleManager } from '@/managers';
import { unref } from 'vue';

const decodeString = (string: string | null | undefined): string => {
  const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
    ? unref(LocaleManager.locale)
    : 'en';

  if (isString(string)) {
    try {
      // return decodeURI(string);
      if (string.indexOf('funding.rural-vision.europa.eu') !== -1) {
        const pages = ['/finder', '/inspire', '/resources', '/faq', '/about', '/resource'];
        for (let k = 0; k < pages.length; k++) {
          string = string.replace('funding.rural-vision.europa.eu' + pages[k], 'funding.rural-vision.europa.eu' + pages[k] + '?lng=' + lang);
        }
      }

      return decodeURIComponent(string.replace(/\+/g, ' ').replace('[notranslate]', '').replace('[/notranslate]', '').replaceAll('#ec.europa.eu', '@ec.europa.eu'));
    } catch {
      return string;
    }
  }
  return '';
};

/**
 * Return a string if it is valid or a empty string (check null or undefined)
 *
 * @param {String} text - text to check
 * @returns {String}
 */
const fallbackNilText = (text: string): string => {
  if (isNil(text) || text === 'null' || text === 'undefined') {
    return '';
  }
  return text;
};

export { decodeString, fallbackNilText };
