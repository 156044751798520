// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../node_modules/v-tooltip/dist/v-tooltip.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-popper--theme-tooltip .v-popper__inner{font-size:.8rem;padding:.3em .8em .25em;border-radius:20px}@media print{.v-popper__popper{display:none!important}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
