// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../local_packages/jrc_ecl_vue/dist/main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../local_packages/jrc_ecl_vue/dist/eu-theme.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../local_packages/jrc_jcl_vue/dist/main.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,html{font:normal normal 400 1rem/1.5rem arial,sans-serif}html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0;overflow-x:hidden}.app-page{min-height:calc(100vh - 1.75em);display:flex;flex-direction:column}.app-page .app-page__content{margin-bottom:auto}.ecl-site-header__site-name{background-color:#fff!important;border-top:1px solid var(--c-p-20)!important;align-self:center!important;color:var(--c-d)!important;flex-grow:1!important;font:var(--f-2xl)!important;margin-inline-end:0!important;padding:var(--s-l) 0!important}.ecl-theme-eu .ecl-site-header__banner{background:#fff!important}.ecl-theme-eu .ecl-site-header__site-name{color:#000!important;font:var(--f-2xl)!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
