import { defineComponent, watch, ref, computed } from 'vue';
import { EclLoader, isMobileOrTablet, EclModal, EclLink, EclAccordion, EclAccordionItem, EclButton, EclButtonType } from '@jrcb3/ecl-vue';
import { store } from '@/store';
import { isNil, isNumber } from 'lodash';
import ParagraphMoreLess from '@/components/ParagraphMoreLess/ParagraphMoreLess.component.vue';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import domtoimage from 'dom-to-image';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';
import { manageTooltip, manageTooltipPDF } from '../../views/App/composables/useGlossary';

export default defineComponent({
  name: 'Modal',
  props: {
  },
  directives: {
  },
  components: {
    EclLoader,
    EclButton,
    EclAccordion,
    EclAccordionItem,
    EclModal,
    EclLink,
    ParagraphMoreLess,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  setup() {
    const showPopup = ref(false);
    const showModalSuppMec = ref(false);
    const showModalNatCont = ref(false);
    const ID = ref('');
    // eslint-disable-next-line
    const found: any = ref({});
    const foundSurvey = ref(true);
    // const combinationFund = ref([]);
    const FICHE_FMBMAIL = ref('');
    const FICHE_WHO = ref('');
    const FICHE_WHO_PDF = ref([]);
    const FICHE_COFIN = ref('');
    const FICHE_COFIN_PDF = ref([]);
    const FICHE_TYPEFUND = ref('');
    const FICHE_TYPEFUND_PDF = ref('');
    const FICHE_EX_PROJTITLE_1 = ref('');
    const FICHE_EX_PROJTITLE_1_PDF = ref('');
    const FICHE_EX_PROJTITLE_2 = ref('');
    const FICHE_EX_PROJTITLE_2_PDF = ref('');
    const FICHE_DESCRGENERAL = ref('');
    const FICHE_DESCRGENERAL_PDF = ref([]);
    const FICHE_DESCRGENERAL_BREAK = ref(210);
    const PDF = ref([]);
    const FICHE_DESCRRURAL = ref('');
    const FICHE_DESCRRURAL_BREAK = ref(290);
    const FICHE_DESCRRURAL_PDF = ref([]);
    const FICHE_SUPPLAPPLWEB = ref('');
    const FICHE_NATIONCONTACT = ref('');
    const FICHE_WEBFUND = ref('');
    const FICHE_EX_PROJWEB_1 = ref('');
    const FICHE_EX_PROJWEB_2 = ref('');
    const calls = ref([]);
    const suppMec = ref([]);
    const suppMecMore = ref(false);
    const natCont = ref([]);
    const natContMore = ref(false);

    const typeColor = ref([]);
    const checkCalls = ref(false);
    const rowCalls1 = ref([]);
    const rowCalls2 = ref([]);

    const rowSuppMec1 = ref([]);
    const rowSuppMec2 = ref([]);
    const rowNatCont = ref([]);
    const rowNatCont1 = ref([]);
    const rowNatCont2 = ref([]);

    const rowPrj11 = ref([]);
    const rowPrj12 = ref([]);
    const rowPrj13 = ref([]);

    const rowPrj21 = ref([]);
    const rowPrj22 = ref([]);
    const rowPrj23 = ref([]);

    // const rowComb1 = ref([]);
    // const rowComb2 = ref([]);
    const loadPDF = ref(false);

    /* const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
      ? unref(LocaleManager.locale)
      : 'en';
    const l = lang === 'en' ? '' : '_' + lang.toUpperCase();
    let RuralToolkit;
    try {
      RuralToolkit = require('@/_static/ruraltoolkit' + l + '.json');
    } catch (ex) {
      RuralToolkit = require('@/_static/ruraltoolkit.json');
    } */
    const additionalClass = ref('');
    typeColor.value = store.getters['tool/typeColor'];

    const buttonDiscover = ref('');
    buttonDiscover.value = decodeString(i18n.global.t('button.discover'));
    const buttonWebpage = ref('');
    buttonWebpage.value = decodeString(i18n.global.t('button.webpage'));
    const buttonOWP = ref('');
    buttonOWP.value = decodeString(i18n.global.t('modal.officialWebPage'));
    const modalKey = ref('');
    modalKey.value = decodeString(i18n.global.t('modal.key'));
    const modalTotalB = ref('');
    modalTotalB.value = decodeString(i18n.global.t('modal.totalB'));
    const modalTypeF = ref('');
    modalTypeF.value = decodeString(i18n.global.t('modal.typeF'));
    const modalCfp = ref('');
    modalCfp.value = decodeString(i18n.global.t('modal.CFP'));
    const modalCoverage = ref('');
    modalCoverage.value = decodeString(i18n.global.t('modal.coverage'));
    const modalDesF = ref('');
    modalDesF.value = decodeString(i18n.global.t('modal.desF'));
    const modalWhy = ref('');
    modalWhy.value = decodeString(i18n.global.t('modal.why'));
    const modalWho = ref('');
    modalWho.value = decodeString(i18n.global.t('modal.who'));
    const modalCalls = ref('');
    modalCalls.value = decodeString(i18n.global.t('modal.calls'));
    const modalFurther = ref('');
    modalFurther.value = decodeString(i18n.global.t('modal.further'));
    const modalSuppMec = ref('');
    modalSuppMec.value = decodeString(i18n.global.t('modal.suppMec'));
    const modalNationalCP = ref('');
    modalNationalCP.value = decodeString(i18n.global.t('modal.nationalCP'));
    const modalContact = ref('');
    modalContact.value = decodeString(i18n.global.t('modal.contact'));
    const modalProjectEx = ref('');
    modalProjectEx.value = decodeString(i18n.global.t('modal.projectEx'));
    const modalGoProjectWeb = ref('');
    modalGoProjectWeb.value = decodeString(i18n.global.t('modal.goProjectWeb'));
    // const modalCombinationF = ref('');
    // modalCombinationF.value = decodeString(i18n.global.t('modal.combinationF'));
    // const modalOtherProg = ref('');
    // modalOtherProg.value = decodeString(i18n.global.t('modal.otherProg'));
    const modalGuidance = ref('');
    modalGuidance.value = decodeString(i18n.global.t('modal.guidance'));
    const modalAddRes = ref('');
    modalAddRes.value = decodeString(i18n.global.t('modal.addRes'));
    const modalMoreInfo = ref('');
    modalMoreInfo.value = decodeString(i18n.global.t('modal.moreInfo'));

    const isMobileTablet = computed(() => isMobileOrTablet());
    // console.log('>>isMobileTablet');
    // console.log(isMobileTablet);

    const listFund = ref([]);
    listFund.value = store.getters['tool/listFund'];

    const handleClickFicheModal = (e, color): void => {
      console.log('>> handleClickFicheModal' + e);
      console.log(color);

      showPopup.value = true;
      store.commit('tool/clickFoId', String(e));
      store.commit('tool/clickFo', !store.getters['tool/clickFo']);
      store.commit('tool/clickFoColor', String(color));
    };

    /* watch(() => showModalSuppMec.value, function() {
      setTimeout(() => {
        const elementw = document.getElementsByClassName('ecl-c-modal__overlay')[0] as HTMLElement;
        if (showModalSuppMec.value) {
          console.log('>>>>>>>DISABLE');
          elementw.style.overflow = 'hidden';
          // document.body.style.overflow = 'hidden';
        } else {
          console.log('>>>>>>>ENABLE');
          elementw.style.overflow = 'auto';
          // document.body.style.overflow = 'auto';
        }
      }, 1);
    }); */
    const replaceAt = (s, index, replacement): string => {
      // console.log('>>replaceAt');
      // console.log(s.substring(0, index));
      // console.log(s.substring(index + replacement.length));
      return (index === -1) ? s + replacement : s.substring(0, index) + replacement + s.substring(index + 1);
    };

    const manageTextList = (s): string => {
      // console.log('>>manageTextList');
      if (isNumber(s)) {
        return s.toString();
      }
      if (isNil(s)) {
        return s;
      }
      const pattern = /•/g;
      const match = s.match(pattern);
      // console.log(match);

      for (const n in match) {
        if (n === '0') {
          // console.log('>>0');
          s = s.replace('•', '<ul><li>');
          // console.log(s);
        } else {
          if (Number(n) + 1 === match.length) {
            // console.log('>>match.length');
            // console.log(match.length);
            const pos = s.indexOf('•');
            const posN = s.indexOf('\n', pos + 1);
            // console.log(pos);
            // console.log(posN);
            s = replaceAt(s, posN, '</li></ul>');
            s = s.replace('•', '</li><li>');
            // console.log(s);
          } else {
            // console.log('>>replace');
            s = s.replace('•', '</li><li>');
            // console.log(s);
          }
        }
      }

      const patternN = /\n/g;
      const matchN = s.match(patternN);
      // eslint-disable-next-line
      for (const _ in matchN) {
        s = s.replace('\n', '<br/>');
      }

      return s;
    };

    const manageHrefPdf = (s): void => {
      // console.log(s);
      PDF.value = [];
      if (isNil(s)) {
        return;
      }
      let start = 0;
      let posA1 = 0;
      let posA2 = 0;
      let posA3 = 0;
      do {
        posA1 = s.indexOf('<a target="_blank" href="', posA3);
        if (posA1 === -1) {
          PDF.value.push(s.substring(start));
          break;
        }
        posA2 = s.indexOf('">', posA1 + 25);
        posA3 = s.indexOf('</a>', posA2 + 2);
        if (posA3 === -1) {
          break;
        }
        PDF.value.push(s.substring(start, posA1));

        PDF.value.push({ text: s.substring(posA2 + 2, posA3), link: s.substring(posA1 + 25, posA2), style: 'textLink' });
        start = posA3 + 4;
      }
      while (posA1 !== -1 || start > s.length);
    };

    const manageHref = (s): string => {
      if (isNumber(s)) {
        return s.toString();
      }

      if (isNil(s)) {
        return s;
      }
      // eslint-disable-next-line prefer-regex-literals
      s = s.replace(new RegExp('#HYPhttps:// ', 'gi'), '#HYPhttps://');
      // console.log(s);
      // const patternT = /\[\[(.*?)]]/g;
      const patternT = /\[(.*?)]/g;
      const matchT = s.match(patternT);

      // const pattern = /#HYP\s*([^ ]+)/ig; // con spazio dopo #HYP
      const pattern = /#HYP\s*(\S+)/ig; // con o senza spazi dopo #HYP
      const match = s.match(pattern);
      // console.log(match);
      // console.log(matchT);
      if (!isNil(match) && !isNil(matchT)) {
        for (const n in match) {
          // const href = '<a target="_blank" href="' + match[n].replace('#HYP', '').trim() + '">' + matchT[n].replace('[[', '').replace(']]', '').trim() + '</a>';
          const href = '<a target="_blank" href="' + match[n].replace('#HYP', '').trim() + '">' + matchT[n].replace('[', '').replace(']', '').trim() + '</a>';
          // console.log(href);
          // con o senza spazi tra [] e http
          const orig = matchT[n] + ' ' + match[n];
          s = s.replace(orig, href);
          const origNoSpace = matchT[n] + match[n];
          s = s.replace(origNoSpace, href);
        }
        return s;
      } else {
        return s;
      }
    };

    const manageHyperlink = (s, ws = true): string => {
      const ns = s.replace('KL.', 'https://').replace('httpsű', 'https:').replace('httpsthe', 'https:').replace('httpsat', 'https:').replace('httpsof', 'https:').replace('httpsÜ', 'https:').replace('httpsspeċifikament', 'https:').replace('httpsb’', 'https:').replace('encequiconcernelespagessuivantes:', 'https:').replace('ainsiquesurlespagessuivantes:', 'https:').replace('ainsi que sur les pages suivantes:', 'https:').replace('httpss', 'https:').replace('httpson', 'https:').replace('Httpsti', 'https:').replace('httpsti', 'https:');
      return ws ? ns.replace(/\s/g, '') : ns;
    };

    watch(() => store.getters['tool/clickFo'], function() {
      foundSurvey.value = true;
      showPopup.value = true;
      ID.value = store.getters['tool/clickFoId'];

      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      // console.log(RuralToolkit);
      console.log(store.getters['tool/funding']);
      console.log(ID.value);
      // store.getters['tool/funding'];
      // found.value = RuralToolkit.find(element => String(element.FUNDID) === ID.value);
      found.value = store.getters['tool/funding'].find(element => String(element.FUNDID) === ID.value);

      console.log(found.value);
      if (!isNil(found.value)) {
        const shortTitle = listFund.value.find(element => element.id === String(found.value.FUNDID));
        found.value.FICHE_SHORTTITLE_FUND = isNil(shortTitle) ? found.value.FICHE_SHORTTITLE : shortTitle.value;
        found.value.FICHE_LONGTITLE_FUND = isNil(shortTitle) ? (found.value.FICHE_LONGTITLE === found.value.FICHE_SHORTTITLE ? null : found.value.FICHE_LONGTITLE) : shortTitle.value1;

        if (!found.value.FICHE_COFIN) {
          additionalClass.value = 'ecl-u-mb-s ecl-offset-2';
        } else {
          additionalClass.value = 'ecl-u-mb-s';
        }
        /* combinationFund.value = store.getters['tool/funding'];

        combinationFund.value = isNil(found.value.FICHE_COFINID) ? [] : combinationFund.value.filter(val => String(val.FUNDID).split(';').some(r => Object.values(found.value.FICHE_COFINID.split(';').map(s => s.trim())).includes(r.trim())));

        combinationFund.value.forEach(function(f) {
          f.bkgColor = typeColor.value.find(x => x.type === f.FILTER_TYPOLOGY).bkgColor;
          f.textColor = typeColor.value.find(x => x.type === f.FILTER_TYPOLOGY).textColor;
          f.indexColor = typeColor.value.find(x => x.type === f.FILTER_TYPOLOGY).indexColor;
        });
        console.log(combinationFund); */
        console.log('>>> webfund>>>', found.value.FICHE_WEBFUND);

        FICHE_WEBFUND.value = manageHyperlink(found.value.FICHE_WEBFUND);
        console.log(FICHE_WEBFUND.value);

        FICHE_EX_PROJWEB_1.value = isNil(found.value.FICHE_EX_PROJWEB_1) ? found.value.FICHE_EX_PROJWEB_1 : manageHyperlink(found.value.FICHE_EX_PROJWEB_1);

        FICHE_EX_PROJWEB_2.value = isNil(found.value.FICHE_EX_PROJWEB_2) ? found.value.FICHE_EX_PROJWEB_2 : manageHyperlink(found.value.FICHE_EX_PROJWEB_2);

        FICHE_FMBMAIL.value = 'mailto:' + found.value.FICHE_FMBMAIL;

        console.log('>>FICHE_WHO');
        FICHE_WHO.value = manageHref(found.value.FICHE_WHO);
        const retWPdf = manageTooltipPDF(FICHE_WHO.value);
        manageHrefPdf(retWPdf);
        FICHE_WHO_PDF.value = PDF.value;
        FICHE_WHO.value = manageTextList(retWPdf);

        console.log('>>FICHE_COFIN');
        FICHE_COFIN.value = manageHref(found.value.FICHE_COFIN);
        manageHrefPdf(FICHE_COFIN.value);
        FICHE_COFIN_PDF.value = PDF.value;

        console.log('>>FICHE_DESCRRURAL');
        FICHE_DESCRRURAL.value = manageHref(found.value.FICHE_DESCRRURAL);

        const retRPdf = manageTooltipPDF(FICHE_DESCRRURAL.value);
        manageHrefPdf(retRPdf);
        FICHE_DESCRRURAL_PDF.value = PDF.value;

        // SISTEMARE temporaneamente disabilitati tolltip
        // FICHE_DESCRRURAL.value = retRPdf;
        FICHE_DESCRRURAL.value = manageTextList(retRPdf);
        // const ret = manageTooltip(manageTextList(FICHE_DESCRRURAL.value));
        // FICHE_DESCRRURAL.value = ret[0];
        // FICHE_DESCRRURAL_BREAK.value = ret[1] === -1 ? FICHE_DESCRRURAL_BREAK.value : ret[1] - 10;

        console.log('>>FICHE_DESCRGENERAL');
        const retHref = manageHref(found.value.FICHE_DESCRGENERAL);

        const retDPdf = manageTooltipPDF(retHref);
        manageHrefPdf(retDPdf);
        FICHE_DESCRGENERAL_PDF.value = PDF.value;

        // SISTEMARE temporaneamente disabilitati tolltip
        FICHE_DESCRGENERAL.value = retDPdf;
        // const retD = manageTooltip(retHref);
        // FICHE_DESCRGENERAL.value = retD[0];
        // FICHE_DESCRGENERAL_BREAK.value = (retD[1] === -1 || retD[1] > FICHE_DESCRGENERAL_BREAK.value) ? FICHE_DESCRGENERAL_BREAK.value : retD[1] - 50;

        FICHE_DESCRGENERAL.value = manageTextList(FICHE_DESCRGENERAL.value);

        console.log('>>>>>FICHE_TYPEFUND');
        FICHE_TYPEFUND.value = manageTooltip(found.value.FICHE_TYPEFUND)[0];
        FICHE_TYPEFUND_PDF.value = manageTooltipPDF(found.value.FICHE_TYPEFUND);

        console.log('>>>>>FICHE_EX_PROJTITLE_1');
        FICHE_EX_PROJTITLE_1.value = manageTooltip(found.value.FICHE_EX_PROJTITLE_1)[0];
        console.log(FICHE_EX_PROJTITLE_1.value);
        FICHE_EX_PROJTITLE_1_PDF.value = manageTooltipPDF(found.value.FICHE_EX_PROJTITLE_1);

        console.log('>>>>>FICHE_EX_PROJTITLE_2');
        FICHE_EX_PROJTITLE_2.value = manageTooltip(found.value.FICHE_EX_PROJTITLE_2)[0];
        console.log(FICHE_EX_PROJTITLE_2.value);
        FICHE_EX_PROJTITLE_2_PDF.value = manageTooltipPDF(found.value.FICHE_EX_PROJTITLE_2);
        console.log(FICHE_EX_PROJTITLE_2.value);

        calls.value = [];
        rowCalls1.value = [];
        rowCalls2.value = [];
        if (!isNil(found.value.FICHE_CALLSWEB)) {
          console.log('>>>FICHE_CALLSWEB', found.value.FICHE_CALLSWEB);

          found.value.FICHE_CALLSWEB.split('**').forEach(c => {
            c = manageHyperlink(c, false);

            const text = c.includes('http') ? c.substring(0, c.indexOf('http')) : '';
            const link = c.includes('http') ? decodeString(c.substring(c.indexOf('http'), c.length)) : '';
            console.log('>>>text', text);
            console.log('>>>link', link);
            calls.value.push({ cText: text, cLink: link });

            if (text !== '') {
              rowCalls1.value.push({ text: text, style: 'text', colSpan: 2 }, {});
            }

            // checkCalls.value = found.value.FICHE_SHORTTITLE === 'SMP - SME Pillar';
            checkCalls.value = String(found.value.FUNDID) === '2';

            console.log('>>checkCalls');
            console.log(checkCalls.value);
            rowCalls2.value.push({ text: buttonOWP.value, style: 'textGoto' }, { text: link, link: link, style: 'textLink' });
          });

          //  CHECK LINK
          console.log('>>LINK');
          console.log(rowCalls2.value[0]);
          // CHECK LINK
          console.log(rowCalls2.value[1]);
          console.log(rowCalls1.value[2]);
          console.log(rowCalls2.value[2]);
          console.log(rowCalls2.value[3]);
        }

        suppMec.value = [];
        rowSuppMec1.value = [];
        rowSuppMec2.value = [];
        FICHE_SUPPLAPPLWEB.value = null;

        if (!isNil(found.value.FICHE_SUPPLAPPLWEB)) {
          if (found.value.FICHE_SUPPLAPPLWEB.includes('http')) {
            FICHE_SUPPLAPPLWEB.value = manageHyperlink(found.value.FICHE_SUPPLAPPLWEB);
            FICHE_SUPPLAPPLWEB.value.split(';').forEach(c => {
              let textM = '';
              textM = c.includes('http') ? c.substring(0, c.indexOf('http')) : '';
              let linkM = '';
              linkM = c.includes('http') ? c.substring(c.indexOf('http'), c.length) : '';
              suppMec.value.push({ cText: textM, cLink: manageHyperlink(linkM) });
              suppMecMore.value = (textM !== '');
              if (textM !== '') {
                rowSuppMec1.value.push({ text: textM, style: 'text', colSpan: 2 }, {});
              }
              rowSuppMec2.value.push({ text: modalSuppMec.value, style: 'textGoto' }, { text: linkM, link: manageHyperlink(linkM), style: 'textLink' });
            });
          }
        }
        console.log('>>>FICHE_SUPPLAPPLWEB');
        console.log(FICHE_SUPPLAPPLWEB.value);

        rowNatCont.value = [];
        rowNatCont1.value = [];
        rowNatCont2.value = [];
        FICHE_NATIONCONTACT.value = null;
        if (!isNil(found.value.FICHE_NATIONCONTACT)) {
          if (found.value.FICHE_NATIONCONTACT.includes('http')) {
            natCont.value = [];
            FICHE_NATIONCONTACT.value = manageHyperlink(found.value.FICHE_NATIONCONTACT);
            FICHE_NATIONCONTACT.value.split(';').forEach(c => {
              let textN = '';
              textN = c.includes('http') ? c.substring(0, c.indexOf('http')) : '';
              let linkN = '';
              linkN = c.includes('http') ? c.substring(c.indexOf('http'), c.length) : '';
              natCont.value.push({ cText: textN, cLink: manageHyperlink(linkN) });
              natContMore.value = (textN !== '');
              if (textN !== '') {
                rowNatCont1.value.push({ text: textN, style: 'text', colSpan: 2 }, {});
              }
              rowNatCont2.value.push({ text: modalNationalCP.value, style: 'textGoto' }, { text: linkN, link: manageHyperlink(linkN), style: 'textLink' });
            });
          }
        }
        console.log(FICHE_NATIONCONTACT.value);

        rowPrj11.value = [];
        rowPrj12.value = [];
        rowPrj13.value = [];
        if (!isNil(found.value.FICHE_EX_PROJTITLE_1)) {
          rowPrj11.value.push({ text: FICHE_EX_PROJTITLE_1_PDF, style: 'textBold', colSpan: 2 }, {});
          rowPrj12.value.push({ text: found.value.FICHE_EX_PROJ2L_1, style: 'text', colSpan: 2 }, {});
          let linkN = '';
          linkN = FICHE_EX_PROJWEB_1.value;
          rowPrj13.value.push({ text: modalGoProjectWeb.value, style: 'textGoto' }, { text: linkN, link: manageHyperlink(linkN), style: 'textLink' });
        }

        rowPrj21.value = [];
        rowPrj22.value = [];
        rowPrj23.value = [];
        if (!isNil(found.value.FICHE_EX_PROJTITLE_2)) {
          rowPrj21.value.push({ text: FICHE_EX_PROJTITLE_2_PDF, style: 'textBold', colSpan: 2 }, {});
          rowPrj22.value.push({ text: found.value.FICHE_EX_PROJ2L_2, style: 'text', colSpan: 2 }, {});
          let linkN = '';
          linkN = FICHE_EX_PROJWEB_2.value;

          rowPrj23.value.push({ text: modalGoProjectWeb.value, style: 'textGoto' }, { text: linkN, link: linkN, style: 'textLink' });
        }

        /* rowComb1.value = [];
        rowComb2.value = [];
        if (!isNil(found.value.FICHE_COFINGUIGEWEB)) {
          rowComb1.value.push({ text: modalGuidance, style: 'text', colSpan: 2 }, {});
          rowComb2.value.push({ text: buttonOWP.value, style: 'textGoto' }, { text: found.value.FICHE_COFINGUIGEWEB, link: found.value.FICHE_COFINGUIGEWEB, style: 'textLink' });
        } */

        setTimeout(() => {
          // console.log(store.getters['tool/clickFoColor']);
          const element = document.getElementsByClassName('ecl-modal__header')[0] as HTMLElement;
          if (element) {
            element.setAttribute('class', 'ecl-modal__header_' + store.getters['tool/clickFoColor'] + ' ecl-modal__header');
          }
          /* const elementB = document.getElementsByClassName('ecl-button--tertiary')[0] as HTMLElement;
          if (elementB) {
            elementB.setAttribute('class', 'ecl-button--tertiary_' + store.getters['tool/clickFoColor'] + ' ecl-button ecl-button--tertiary ecl-modal__close ecl-button--size-m ecl-button--icon-only');
          } */

          const elementw = document.getElementsByClassName('ecl-c-modal__wrapper')[0] as HTMLElement;
          if (!isNil(elementw)) {
            elementw.setAttribute('class', isMobileTablet.value ? 'ecl-c-modal__wrapper_main_mobile' : 'ecl-c-modal__wrapper_main');
          }
          // document.getElementById('topDiv').scrollIntoView(false);

          /* const header = document.getElementsByClassName('ecl-c-modal__header')[0] as HTMLElement;

          const btnPrint = document.createElement('div');
          btnPrint.innerHTML = '<button class="ecl-button ecl-button--primary ecl-u-mt-m" type="submit"  onclick="createPDF()" target="_blank">print</button>';

          header.appendChild(btnPrint); */
        }, 1);
      }
    });

    const performOpenModalSuppMec = (): void => {
      showModalSuppMec.value = true;
    };

    const performOpenModalNatCont = (): void => {
      showModalNatCont.value = true;
    };

    watch(() => showPopup.value, function() {
      if (showPopup.value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });

    const createPDF = (): void => {
      loadPDF.value = true;
      console.log('>>PDF');

      // const rowSpace = [{ text: '', style: 'mainHeaderSpace', colSpan: 2 }, {}];
      const rowEmpty = [{ text: '', border: [false, false, false, false] }, { text: '', border: [false, false, false, false] }];

      const rowHeader = [{ text: found.value.FICHE_SHORTTITLE_FUND + ': ' + found.value.FICHE_LONGTITLE, style: 'pageHeader' + store.getters['tool/clickFoColor'], colSpan: 2 }, {}];

      const iconInfo = document.getElementById('iconinfo') as HTMLElement;
      iconInfo.style.display = 'block';
      const iconDes = document.getElementById('icondes') as HTMLElement;
      iconDes.style.display = 'block';
      const iconRel = document.getElementById('iconrel') as HTMLElement;
      iconRel.style.display = 'block';
      const iconWho = document.getElementById('iconwho') as HTMLElement;
      iconWho.style.display = 'block';
      const iconCalls = document.getElementById('iconcalls') as HTMLElement;
      iconCalls.style.display = 'block';
      const iconMore = document.getElementById('iconmore') as HTMLElement;
      iconMore.style.display = 'block';
      const iconPrj = document.getElementById('iconprj') as HTMLElement;
      iconPrj.style.display = 'block';
      const iconFu = document.getElementById('iconcofu') as HTMLElement;
      iconFu.style.display = 'block';

      // toJpeg(document.getElementById('iconinfo') as HTMLElement, { quality: 1 }).then(function(
      domtoimage.toPng(iconInfo).then(function(
        imageInfo
      ) {
        domtoimage.toPng(iconDes).then(function(
          imageDes
        ) {
          domtoimage.toPng(iconRel).then(function(
            imageRel
          ) {
            domtoimage.toPng(iconWho).then(function(
              imageWho
            ) {
              domtoimage.toPng(iconCalls).then(function(
                imageCalls
              ) {
                domtoimage.toPng(iconMore).then(function(
                  imageMore
                ) {
                  domtoimage.toPng(iconPrj).then(function(
                    imagePrj
                  ) {
                    domtoimage.toPng(iconFu).then(function(
                    ) {
                      const docDefinition = {
                        content: [
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                rowHeader,
                              ]
                            },
                            layout: {
                              hLineWidth: function () {
                                return 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                            }
                            // layout: 'headerLineOnly',
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                [{ image: imageInfo, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: modalKey.value, style: 'mainHeader1' }],
                                [
                                  { text: modalTotalB.value, style: 'text' },
                                  { text: found.value.FICHE_BUDGET, style: 'text' },
                                ],
                                [
                                  { text: modalTypeF.value, style: 'text' },
                                  { text: FICHE_TYPEFUND_PDF.value, style: 'text' }
                                ],
                                [
                                  { text: modalCfp.value, style: 'text', },
                                  { text: FICHE_COFIN_PDF.value, style: 'text' }
                                ],
                                [
                                  { text: modalCoverage.value, style: 'text', },
                                  { text: found.value.FICHE_PERIOD, style: 'text' }
                                ],
                                [
                                  { text: buttonOWP.value, style: 'text', },
                                  { text: FICHE_WEBFUND.value, link: FICHE_WEBFUND.value, style: 'textLink' }
                                ],
                                [
                                  { text: modalContact.value, style: 'text', },
                                  { text: found.value.FICHE_FMBMAIL, link: FICHE_FMBMAIL.value, style: 'textLink' }
                                ],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 0) ? 0 : (i === 1) ? 0 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                            // layout: 'headerLineOnly',
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                [{ image: imageDes, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: modalDesF.value, style: 'mainHeader1' }],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 0) ? 0 : (i === 1) ? 0 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                          },
                          {
                            alignment: 'justify',
                            columns: [
                              {
                                fontSize: 9,
                                color: '#696969',
                                text: FICHE_DESCRGENERAL_PDF.value
                              }
                            ]
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                [{ image: imageRel, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: 'Why it is relevant for rural areas', style: 'mainHeader1' }],
                                [
                                  { text: FICHE_DESCRRURAL_PDF.value, style: 'text', colSpan: 2 }, {}
                                ],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 0) ? 0 : (i === 1) ? 0 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                [{ image: imageWho, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: 'Who can apply', style: 'mainHeader1' }],
                                [
                                  { text: FICHE_WHO_PDF.value, style: 'text', colSpan: 2 }, {}
                                ],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 0) ? 0 : (i === 1) ? 0 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              unbreakable: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                rowEmpty,
                                [{ image: imageCalls, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: modalCalls.value, style: 'mainHeader1' }],
                                // eslint-disable-next-line multiline-ternary
                                isNil(rowCalls2.value[0]) ? rowEmpty : checkCalls.value ? [
                                  { text: buttonOWP.value, style: 'textGoto' },
                                  {
                                    text: 'https://eismea.ec.europa.eu/funding-opportunities/calls-proposals_en?f[0]=oe_call_proposals_status:upcoming&f[1]=oe_call_proposals_title:sme&f[2]=programmes_news_programmes:8',
                                    link: 'https://eismea.ec.europa.eu/funding-opportunities/calls-proposals_en?f[0]=oe_call_proposals_status:upcoming&f[1]=oe_call_proposals_title:sme&f[2]=programmes_news_programmes:8',
                                    style: 'textLink'
                                  }
                                ] : rowEmpty,
                                isNil(rowCalls2.value[0]) ? rowEmpty : checkCalls.value ? rowEmpty : [rowCalls2.value[0], rowCalls2.value[1]],
                                isNil(rowCalls1.value[2]) ? rowEmpty : [rowCalls1.value[2]],
                                isNil(rowCalls2.value[2]) ? rowEmpty : [rowCalls2.value[2], rowCalls2.value[3]],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 1) ? 0 : (i === 2) ? 0 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                            // layout: 'headerLineOnly',
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                [{ image: imageMore, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: modalFurther.value, style: 'mainHeader1' }],
                                isNil(rowSuppMec1.value[0]) ? rowEmpty : [rowSuppMec1.value[0]],
                                isNil(rowSuppMec2.value[0]) ? rowEmpty : [rowSuppMec2.value[0], rowSuppMec2.value[1]],
                                isNil(rowSuppMec1.value[2]) ? rowEmpty : [rowSuppMec1.value[2]],
                                isNil(rowSuppMec2.value[2]) ? rowEmpty : [rowSuppMec2.value[2], rowSuppMec2.value[3]],
                                rowEmpty,
                                isNil(rowNatCont1.value[0]) ? rowEmpty : [rowNatCont1.value[0], rowNatCont1.value[1]],
                                isNil(rowNatCont2.value[0]) ? rowEmpty : [rowNatCont2.value[0], rowNatCont2.value[1]],
                                isNil(rowNatCont1.value[2]) ? rowEmpty : [rowNatCont1.value[2]],
                                isNil(rowNatCont2.value[2]) ? rowEmpty : [rowNatCont2.value[2], rowNatCont2.value[3]],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 0) ? 0 : (i === 1) ? 0 : (i === 3) ? 1 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                          },
                          {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                rowEmpty,
                                [{ image: imagePrj, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: modalProjectEx.value, style: 'mainHeader1' }],
                                isNil(rowPrj11.value[0]) ? rowEmpty : [rowPrj11.value[0]],
                                isNil(rowPrj12.value[0]) ? rowEmpty : [rowPrj12.value[0]],
                                isNil(rowPrj13.value[0]) ? rowEmpty : [rowPrj13.value[0], rowPrj13.value[1]],
                                rowEmpty,
                                isNil(rowPrj21.value[0]) ? rowEmpty : [rowPrj21.value[0]],
                                isNil(rowPrj22.value[0]) ? rowEmpty : [rowPrj22.value[0]],
                                isNil(rowPrj23.value[0]) ? rowEmpty : [rowPrj23.value[0], rowPrj23.value[1]],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i) {
                                return (i === 1) ? 0 : (i === 2) ? 0 : (i === 6) ? 1 : 0;
                              },
                              vLineWidth: function () {
                                return 0;
                              },
                              hLineColor: function (i) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                            // layout: 'headerLineOnly',
                          },
                          /* {
                            style: 'tableMain',
                            table: {
                              dontBreakRows: true,
                              widths: ['20%', '80%'],
                              headerRows: 1,
                              hLineWidth: 13,
                              body: [
                                rowEmpty,
                                isNil(rowComb1.value[0]) ? rowEmpty : [{ image: imageCoFu, width: 22, height: 22, alignment: 'left', style: 'mainHeaderIcon' }, { text: modalCombinationF.value, style: 'mainHeader1' }],
                                isNil(rowComb1.value[0]) ? rowEmpty : [rowComb1.value[0]],
                                isNil(rowComb2.value[0]) ? rowEmpty : [rowComb2.value[0], rowComb2.value[1]],
                              ]
                            },
                            layout: {
                              hLineWidth: function (i, node) {
                                return (i === 1) ? 0 : (i === 2) ? 0 : 0;
                              },
                              vLineWidth: function (i, node) {
                                return 0;
                              },
                              hLineColor: function (i, node) {
                                return (i === 1) ? '#9FB5EA' : '#9FB5EA';
                              },
                            }
                            // layout: 'headerLineOnly',
                          } */
                        ],
                        styles: {
                          text: {
                            fontSize: 9,
                            color: '#696969'
                          },
                          title: {
                            fontSize: 14,
                            bold: true,
                            color: '#164194'
                          },
                          textGoto: {
                            fontSize: 9,
                            color: '#696969'
                          },
                          textLink: {
                            color: 'blue',
                            decoration: 'underline',
                            markerColor: 'grey',
                            fontSize: 9
                          },
                          textBold12: {
                            fontSize: 12,
                            bold: true
                          },
                          textBold: {
                            fontSize: 9,
                            bold: true
                          },
                          tableMain: {
                            margin: [0, 20, 0, 0],
                          },
                          pageHeader0: {
                            bold: true,
                            fontSize: 16,
                            color: 'white',
                            fillColor: '#7ac5f0',
                            alignment: 'center',
                            margin: [0, 5, 0, 5]
                          },
                          pageHeader1: {
                            bold: true,
                            fontSize: 16,
                            color: 'white',
                            fillColor: '#6AB976',
                            alignment: 'center',
                            margin: [0, 5, 0, 5]
                          },
                          pageHeader2: {
                            bold: true,
                            fontSize: 16,
                            color: 'white',
                            fillColor: '#E63736',
                            alignment: 'center',
                            margin: [0, 5, 0, 5]
                          },
                          mainHeader1: {
                            bold: true,
                            fontSize: 12,
                            color: 'white',
                            fillColor: '#0e47cb',
                            margin: [0, 4, 0, 0]
                          },
                          mainHeaderIcon: {
                            fillColor: '#0e47cb',
                          },
                          mainHeaderSpace: {
                            margin: [0, 0, 0, 20],
                            color: 'white',
                            fillColor: 'white',
                          }
                        },
                        defaultStyle: {
                          columnGap: 20
                          // alignment: 'justify'
                        }
                      };

                      pdfMake.vfs = pdfFonts.pdfMake.vfs;
                      console.log('>>docDefinition');
                      console.log(docDefinition);
                      const pdf = pdfMake.createPdf(docDefinition);
                      pdf.download(found.value.FICHE_SHORTTITLE_FUND + '.pdf');
                      loadPDF.value = false;
                    });
                  });
                });
              });
            });
          });
        });
      });
      /*       setTimeout(function() {
        iconInfo.style.display = 'none';
        iconDes.style.display = 'none';
        iconRel.style.display = 'none';
        iconWho.style.display = 'none';
        iconCalls.style.display = 'none';
        iconMore.style.display = 'none';
        iconPrj.style.display = 'none';
        iconFu.style.display = 'none';
      }, 1000); */
    };

    return {
      EclButtonType,
      showPopup,
      foundSurvey,
      handleClickFicheModal,
      found,
      ID,
      additionalClass,
      // combinationFund,
      FICHE_FMBMAIL,
      FICHE_WHO,
      FICHE_COFIN,
      FICHE_DESCRGENERAL,
      FICHE_DESCRGENERAL_BREAK,
      FICHE_DESCRRURAL,
      FICHE_DESCRRURAL_BREAK,
      FICHE_TYPEFUND,
      FICHE_EX_PROJTITLE_1,
      FICHE_EX_PROJTITLE_1_PDF,
      FICHE_EX_PROJTITLE_2,
      FICHE_EX_PROJTITLE_2_PDF,
      FICHE_SUPPLAPPLWEB,
      FICHE_NATIONCONTACT,
      FICHE_WEBFUND,
      FICHE_EX_PROJWEB_1,
      FICHE_EX_PROJWEB_2,
      calls,
      suppMec,
      natCont,
      natContMore,
      suppMecMore,
      performOpenModalSuppMec,
      performOpenModalNatCont,
      showModalSuppMec,
      showModalNatCont,
      createPDF,
      buttonDiscover,
      buttonWebpage,
      modalKey,
      modalTotalB,
      modalTypeF,
      modalCfp,
      modalCoverage,
      modalDesF,
      modalWhy,
      modalWho,
      modalCalls,
      modalFurther,
      modalSuppMec,
      modalNationalCP,
      modalContact,
      modalProjectEx,
      modalGoProjectWeb,
      // modalCombinationF,
      // modalOtherProg,
      modalGuidance,
      modalAddRes,
      modalMoreInfo,
      buttonOWP,
      loadPDF,
      isMobileTablet
      // ...map,
    };
  }

});
