<ecl-container>
  <ecl-row class="ecl-u-pb-l">
    <ecl-column col="12" l="12" xl="12" class="">
      <h1 class="ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl">{{$t('about.title')}}</h1>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-xl">
    <ecl-column col="12" m="3" class="ecl-u-pt-none">
      <div
        class="ecl-card__image"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_About-1.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" m="9" class="ecl-u-pt-l">
      <ecl-h level="3" class="ecl-u-mt-none">{{aboutWhy}}</ecl-h>
      <ecl-p v-html="aboutWhyText"></ecl-p>
    </ecl-column>

    <ecl-column col="12" m="12" class="">
      <span class="ecl-u-pt-s ecl-u-pb-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1"></span>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-xl">
    <ecl-column col="12" m="3" class="ecl-u-pt-none">
      <div
        class="ecl-card__image"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_About-2.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" m="9" class="ecl-u-pt-l">
      <ecl-h level="3" class="ecl-u-mt-none">{{aboutHow}}</ecl-h>
      <ecl-p v-html="aboutHowText"></ecl-p>
    </ecl-column>

    <ecl-column col="12" m="12" class="">
      <span class="ecl-u-pt-s ecl-u-pb-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1"></span>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-xl">
    <ecl-column col="12" m="3" class="ecl-u-pt-none">
      <div
        class="ecl-card__image"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_About-3.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" m="9" class="ecl-u-pt-l ecl-u-mb-l">
      <ecl-h level="3" class="ecl-u-mt-none">{{aboutWhat}}</ecl-h>
      <ecl-p v-html="aboutWhatText"></ecl-p>
      <EclAccordion>
        <EclAccordionItem>
          <template v-slot:trigger>
            <span>{{aboutWhatDirect}}</span>
          </template>
          <template v-slot:content>
            <ecl-p v-html="aboutWhatDirectText"></ecl-p>
          </template>
        </EclAccordionItem>

        <EclAccordionItem>
          <template v-slot:trigger>
            <span>{{aboutWhatIndirect}}</span>
          </template>
          <template v-slot:content>
            <ecl-p v-html="aboutWhatIndirectText"></ecl-p>
          </template>
        </EclAccordionItem>

        <EclAccordionItem>
          <template v-slot:trigger>
            <span v-html="aboutWhatFinancial"></span>
          </template>
          <template v-slot:content>
            <ecl-p v-html="aboutWhatFinancialText"></ecl-p>
          </template>
        </EclAccordionItem>

        <EclAccordionItem style="display:none">
          <template v-slot:trigger style="display:none">
          </template>
          <template v-slot:content style="display:none">
          </template>
        </EclAccordionItem>

      </EclAccordion>
      
    </ecl-column>
  </ecl-row>  
  <br/><br/>
</ecl-container>