import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/icons/Icon_Info.svg'
import _imports_1 from '@/assets/icons/Icon_Description.svg'
import _imports_2 from '@/assets/icons/Icon_Relevance.svg'
import _imports_3 from '@/assets/icons/Icon_Who.svg'
import _imports_4 from '@/assets/icons/Icon_Calls.svg'
import _imports_5 from '@/assets/icons/Icon_More-info.svg'
import _imports_6 from '@/assets/icons/Icon_Project.svg'
import _imports_7 from '@/assets/icons/Icon_Additional-resources.svg'
import _imports_8 from '@/assets/icons/Icon_Info_w.svg'
import _imports_9 from '@/assets/icons/Icon_Description_w.svg'
import _imports_10 from '@/assets/icons/Icon_Relevance_w.svg'
import _imports_11 from '@/assets/icons/Icon_Who_w.svg'
import _imports_12 from '@/assets/icons/Icon_Calls_w.svg'
import _imports_13 from '@/assets/icons/Icon_More-info_w.svg'
import _imports_14 from '@/assets/icons/Icon_Project_w.svg'
import _imports_15 from '@/assets/icons/Icon_Combination-funding_w.svg'
import _imports_16 from '@/assets/icons/Icon_Additional-resources_w.svg'


const _hoisted_1 = { class: "printButton" }
const _hoisted_2 = { class: "printButtonBlock" }
const _hoisted_3 = { class: "ecl-card" }
const _hoisted_4 = { class: "ecl-card__body" }
const _hoisted_5 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "ecl-card-rural"
}
const _hoisted_10 = { class: "ecl-card__body" }
const _hoisted_11 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_12 = {
  key: 2,
  class: "ecl-card-rural"
}
const _hoisted_13 = { class: "ecl-card__body" }
const _hoisted_14 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_15 = {
  key: 3,
  class: "ecl-card"
}
const _hoisted_16 = { class: "ecl-card__body" }
const _hoisted_17 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_18 = {
  key: 4,
  class: "ecl-card"
}
const _hoisted_19 = { class: "ecl-card__body" }
const _hoisted_20 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_21 = {
  key: 0,
  class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-l ecl-u-mb-xs"
}
const _hoisted_22 = { key: 1 }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { key: 5 }
const _hoisted_25 = {
  key: 6,
  class: "ecl-card"
}
const _hoisted_26 = { class: "ecl-card__body" }
const _hoisted_27 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 0,
  class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-l ecl-u-mb-xs"
}
const _hoisted_30 = {
  key: 0,
  class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-l ecl-u-mb-xs"
}
const _hoisted_31 = { key: 7 }
const _hoisted_32 = {
  key: 8,
  class: "ecl-card-rural"
}
const _hoisted_33 = { class: "ecl-card__body" }
const _hoisted_34 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = { key: 1 }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = { class: "ecl-card" }
const _hoisted_40 = { class: "ecl-card__body" }
const _hoisted_41 = { class: "ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s" }

export function render(_ctx, _cache) {
  const _component_ecl_loader = _resolveComponent("ecl-loader")
  const _component_ecl_button = _resolveComponent("ecl-button")
  const _component_ecl_col = _resolveComponent("ecl-col")
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_h7 = _resolveComponent("h7")
  const _component_paragraph_more_less = _resolveComponent("paragraph-more-less")
  const _component_ecl_modal = _resolveComponent("ecl-modal")
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock(_component_ecl_modal, {
    modelValue: _ctx.showPopup,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.showPopup) = $event)),
    id: "modal-village"
  }, _createSlots({ _: 2 }, [
    (_ctx.found.FICHE_LONGTITLE_FUND != null)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.found.FICHE_SHORTTITLE_FUND + ': ' + _ctx.found.FICHE_LONGTITLE_FUND), 1)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.found.FICHE_LONGTITLE_FUND == null)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.found.FICHE_SHORTTITLE_FUND), 1)
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.foundSurvey)
      ? {
          name: "body",
          fn: _withCtx(() => [
            (_ctx.loadPDF === true)
              ? (_openBlock(), _createBlock(_component_ecl_loader, {
                  key: 0,
                  background: "transparent",
                  panel: true
                }))
              : _createCommentVNode("", true),
            _cache[39] || (_cache[39] = _createElementVNode("div", { id: "topDiv" }, null, -1)),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_component_ecl_button, {
                  class: "ecl-u-mt-m",
                  icon: "print",
                  iconSize: "s",
                  type: _ctx.EclButtonType.Secondary,
                  onClick: _cache[0] || (_cache[0] = $event => (_ctx.createPDF())),
                  target: "_blank",
                  alt: "Print",
                  title: "Print",
                  "aria-label": "Print"
                }, null, 8, ["type"]), [
                  [_directive_tooltip, { content: 'Print' }]
                ])
              ])
            ]),
            _createElementVNode("article", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ecl_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_col, {
                      col: "1",
                      class: "ecl-u-ml-m"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createElementVNode("img", {
                          class: "ecl-u-mt-none",
                          style: {"width":"44px","height":"44px"},
                          src: _imports_0,
                          alt: "Icon Info"
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ecl_column, { col: "11" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h6", _hoisted_5, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.modalKey), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ecl_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_column, {
                      col: "12",
                      m: "12",
                      l: "12",
                      class: "ecl-u-mt-l"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                          default: _withCtx(() => [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.modalTotalB) + " ", 1),
                            _createTextVNode(_toDisplayString(_ctx.found.FICHE_BUDGET), 1)
                          ]),
                          _: 1
                        }),
                        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                        _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                          default: _withCtx(() => [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.modalTypeF) + " ", 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("span", { innerHTML: _ctx.FICHE_TYPEFUND }, null, 8, _hoisted_6),
                        (_ctx.found.FICHE_COFIN)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                              _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("strong", null, _toDisplayString(_ctx.modalCfp) + " ", 1)
                                ]),
                                _: 1
                              }),
                              _createElementVNode("span", { innerHTML: _ctx.FICHE_COFIN }, null, 8, _hoisted_8)
                            ]))
                          : _createCommentVNode("", true),
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                        _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                          default: _withCtx(() => [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.modalCoverage) + " ", 1),
                            _createTextVNode(_toDisplayString(_ctx.found.FICHE_PERIOD), 1)
                          ]),
                          _: 1
                        }),
                        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs" }, null, -1)),
                        _createVNode(_component_ecl_button, {
                          class: "ecl-u-mt-xs",
                          icon: "external",
                          iconSize: "xs",
                          text: _ctx.buttonOWP,
                          ariaLabel: _ctx.buttonOWP,
                          type: _ctx.EclButtonType.Primary,
                          url: _ctx.FICHE_WEBFUND,
                          target: "_blank"
                        }, null, 8, ["text", "ariaLabel", "type", "url"]),
                        _cache[11] || (_cache[11] = _createTextVNode("      ")),
                        (_ctx.found.FICHE_FMBMAIL)
                          ? (_openBlock(), _createBlock(_component_ecl_button, {
                              key: 1,
                              class: "ecl-u-mt-m",
                              icon: "external",
                              iconSize: "xs",
                              text: _ctx.modalContact,
                              ariaLabel: _ctx.modalContact,
                              type: _ctx.EclButtonType.Primary,
                              url: _ctx.FICHE_FMBMAIL,
                              target: "_blank"
                            }, null, 8, ["text", "ariaLabel", "type", "url"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1)),
            (_ctx.found.FICHE_DESCRGENERAL)
              ? (_openBlock(), _createElementBlock("article", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_ecl_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ecl_col, {
                          col: "1",
                          class: "ecl-u-ml-m"
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createElementVNode("img", {
                              class: "ecl-u-mt-none",
                              style: {"width":"44px","height":"44px"},
                              src: _imports_1,
                              alt: "Icon Description"
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ecl_column, { col: "11" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", _hoisted_11, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.modalDesF), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_paragraph_more_less, {
                      breakAbstract: true,
                      breakAbstractCount: _ctx.FICHE_DESCRGENERAL_BREAK,
                      abstract: _ctx.FICHE_DESCRGENERAL
                    }, null, 8, ["breakAbstractCount", "abstract"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[41] || (_cache[41] = _createElementVNode("br", null, null, -1)),
            (_ctx.found.FICHE_DESCRRURAL)
              ? (_openBlock(), _createElementBlock("article", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_ecl_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ecl_col, {
                          col: "1",
                          class: "ecl-u-ml-m"
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createElementVNode("img", {
                              class: "ecl-u-mt-none",
                              style: {"width":"44px","height":"44px"},
                              src: _imports_2,
                              alt: "Icon Relevance"
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ecl_column, { col: "11" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", _hoisted_14, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.modalWhy), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_paragraph_more_less, {
                      breakAbstract: true,
                      breakAbstractCount: 190,
                      abstract: _ctx.FICHE_DESCRRURAL
                    }, null, 8, ["abstract"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1)),
            (_ctx.found.FICHE_WHO)
              ? (_openBlock(), _createElementBlock("article", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_ecl_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ecl_col, {
                          col: "1",
                          class: "ecl-u-ml-m"
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createElementVNode("img", {
                              class: "ecl-u-mt-none",
                              style: {"width":"44px","height":"44px"},
                              src: _imports_3,
                              alt: "Icon Who"
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ecl_column, { col: "11" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", _hoisted_17, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.modalWho), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_paragraph_more_less, {
                      breakAbstract: true,
                      breakAbstractCount: 290,
                      abstract: _ctx.FICHE_WHO
                    }, null, 8, ["abstract"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[43] || (_cache[43] = _createElementVNode("br", null, null, -1)),
            (_ctx.found.FICHE_CALLSWEB)
              ? (_openBlock(), _createElementBlock("article", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_ecl_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ecl_col, {
                          col: "1",
                          class: "ecl-u-ml-m"
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createElementVNode("img", {
                              class: "ecl-u-mt-none",
                              style: {"width":"44px","height":"44px"},
                              src: _imports_4,
                              alt: "Icon Calls"
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ecl_column, { col: "11" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", _hoisted_20, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.modalCalls), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ecl_row, null, {
                      default: _withCtx(() => [
                        (_ctx.found.FICHE_CALLSWEB)
                          ? (_openBlock(), _createBlock(_component_ecl_column, {
                              key: 0,
                              col: "12",
                              m: "12",
                              l: "12"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calls, (itemc, indexc) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: indexc,
                                    class: "ecl-u-mt-m"
                                  }, [
                                    (indexc > 0)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_21))
                                      : _createCommentVNode("", true),
                                    (itemc.cText != '')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                          _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("strong", {
                                                innerHTML: itemc.cText
                                              }, null, 8, _hoisted_23)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                                          _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1))
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ecl_button, {
                                      class: "ecl-u-mt-none",
                                      icon: "external",
                                      iconSize: "xs",
                                      text: _ctx.buttonOWP,
                                      ariaLabel: _ctx.buttonOWP,
                                      type: _ctx.EclButtonType.Primary,
                                      url: itemc.cLink,
                                      target: "_blank"
                                    }, null, 8, ["text", "ariaLabel", "type", "url"]),
                                    _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1))
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.FICHE_SUPPLAPPLWEB != null || _ctx.FICHE_NATIONCONTACT != null)
              ? (_openBlock(), _createElementBlock("br", _hoisted_24))
              : _createCommentVNode("", true),
            (_ctx.FICHE_SUPPLAPPLWEB != null || _ctx.FICHE_NATIONCONTACT != null)
              ? (_openBlock(), _createElementBlock("article", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_ecl_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ecl_col, {
                          col: "1",
                          class: "ecl-u-ml-m"
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createElementVNode("img", {
                              class: "ecl-u-mt-none",
                              style: {"width":"44px","height":"44px"},
                              src: _imports_5,
                              alt: "Icon More Info"
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ecl_column, { col: "11" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", _hoisted_27, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.modalFurther), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.FICHE_SUPPLAPPLWEB)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                          (!_ctx.suppMecMore && _ctx.FICHE_SUPPLAPPLWEB)
                            ? (_openBlock(), _createBlock(_component_ecl_button, {
                                key: 0,
                                class: "ecl-u-mt-m",
                                icon: "external",
                                iconSize: "xs",
                                text: _ctx.modalSuppMec,
                                ariaLabel: _ctx.modalSuppMec,
                                type: _ctx.EclButtonType.Primary,
                                url: _ctx.FICHE_SUPPLAPPLWEB,
                                target: "_blank"
                              }, null, 8, ["text", "ariaLabel", "type", "url"]))
                            : _createCommentVNode("", true),
                          (_ctx.suppMecMore && _ctx.FICHE_SUPPLAPPLWEB)
                            ? (_openBlock(), _createBlock(_component_ecl_button, {
                                key: 1,
                                class: "ecl-u-mt-m",
                                icon: "corner-arrow",
                                iconRotate: "90",
                                iconSize: "xs",
                                text: _ctx.modalSuppMec,
                                ariaLabel: _ctx.modalSuppMec,
                                type: _ctx.EclButtonType.Primary,
                                onClick: _cache[1] || (_cache[1] = $event => (_ctx.performOpenModalSuppMec())),
                                target: "_blank"
                              }, null, 8, ["text", "ariaLabel", "type"]))
                            : _createCommentVNode("", true),
                          _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1))
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ecl_modal, {
                      modelValue: _ctx.showModalSuppMec,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.showModalSuppMec) = $event))
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.modalSuppMec), 1)
                      ]),
                      body: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suppMec, (items, indexs) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: indexs,
                            class: "ecl-u-mt-m"
                          }, [
                            (indexs > 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_29))
                              : _createCommentVNode("", true),
                            _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                              default: _withCtx(() => [
                                _createElementVNode("strong", null, _toDisplayString(items.cText), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                            _createVNode(_component_ecl_button, {
                              class: "ecl-u-mt-m",
                              icon: "external",
                              iconSize: "xs",
                              text: _ctx.buttonOWP,
                              ariaLabel: _ctx.buttonOWP,
                              type: _ctx.EclButtonType.Primary,
                              url: items.cLink,
                              target: "_blank"
                            }, null, 8, ["text", "ariaLabel", "type", "url"]),
                            _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1))
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    (!_ctx.natContMore && _ctx.FICHE_NATIONCONTACT)
                      ? (_openBlock(), _createBlock(_component_ecl_button, {
                          key: 1,
                          class: "ecl-u-mt-m",
                          icon: "external",
                          iconSize: "xs",
                          text: _ctx.modalNationalCP,
                          ariaLabel: _ctx.modalNationalCP,
                          type: _ctx.EclButtonType.Primary,
                          url: _ctx.FICHE_NATIONCONTACT,
                          target: "_blank"
                        }, null, 8, ["text", "ariaLabel", "type", "url"]))
                      : _createCommentVNode("", true),
                    (_ctx.natContMore && _ctx.FICHE_NATIONCONTACT)
                      ? (_openBlock(), _createBlock(_component_ecl_button, {
                          key: 2,
                          class: "ecl-u-mt-m",
                          icon: "corner-arrow",
                          iconRotate: "90",
                          iconSize: "xs",
                          text: _ctx.modalNationalCP,
                          ariaLabel: _ctx.modalNationalCP,
                          type: _ctx.EclButtonType.Primary,
                          onClick: _cache[3] || (_cache[3] = $event => (_ctx.performOpenModalNatCont())),
                          target: "_blank"
                        }, null, 8, ["text", "ariaLabel", "type"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ecl_modal, {
                      modelValue: _ctx.showModalNatCont,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.showModalNatCont) = $event))
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.modalNationalCP), 1)
                      ]),
                      body: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.natCont, (items, indexs) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: indexs,
                            class: "ecl-u-mt-m"
                          }, [
                            (indexs > 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_30))
                              : _createCommentVNode("", true),
                            _createVNode(_component_h7, { class: "ecl-u-type-heading-7" }, {
                              default: _withCtx(() => [
                                _createElementVNode("strong", null, _toDisplayString(items.cText), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                            _createVNode(_component_ecl_button, {
                              class: "ecl-u-mt-m",
                              icon: "external",
                              iconSize: "xs",
                              text: _ctx.buttonOWP,
                              ariaLabel: _ctx.buttonOWP,
                              type: _ctx.EclButtonType.Primary,
                              url: items.cLink,
                              target: "_blank"
                            }, null, 8, ["text", "ariaLabel", "type", "url"]),
                            _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1))
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.found.FICHE_EX_PROJTITLE_1)
              ? (_openBlock(), _createElementBlock("br", _hoisted_31))
              : _createCommentVNode("", true),
            (_ctx.found.FICHE_EX_PROJTITLE_1)
              ? (_openBlock(), _createElementBlock("article", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, [
                    _createVNode(_component_ecl_row, { class: "ecl-u-mb-s" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ecl_col, {
                          col: "1",
                          class: "ecl-u-ml-m"
                        }, {
                          default: _withCtx(() => _cache[25] || (_cache[25] = [
                            _createElementVNode("img", {
                              class: "ecl-u-mt-none",
                              style: {"width":"44px","height":"44px"},
                              src: _imports_6,
                              alt: "Icon Project"
                            }, null, -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_ecl_column, { col: "11" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", _hoisted_34, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.modalProjectEx), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.found.FICHE_EX_PROJTITLE_1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createVNode(_component_ecl_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ecl_column, {
                                col: "12",
                                m: "12",
                                l: "12",
                                class: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_h7, { class: "ecl-u-type-heading-7 ecl-u-mt-s ecl-u-mb-s" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("strong", { innerHTML: _ctx.FICHE_EX_PROJTITLE_1 }, null, 8, _hoisted_36)
                                    ]),
                                    _: 1
                                  }),
                                  _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
                                  _createVNode(_component_ecl_p, {
                                    class: "paragraph_list__item",
                                    innerHTML: _ctx.found.FICHE_EX_PROJ2L_1
                                  }, null, 8, ["innerHTML"]),
                                  (_ctx.found.FICHE_EX_PROJWEB_1)
                                    ? (_openBlock(), _createBlock(_component_ecl_button, {
                                        key: 0,
                                        icon: "external",
                                        iconSize: "xs",
                                        text: _ctx.modalGoProjectWeb,
                                        ariaLabel: _ctx.modalGoProjectWeb,
                                        type: _ctx.EclButtonType.Primary,
                                        url: _ctx.FICHE_EX_PROJWEB_1,
                                        target: "_blank"
                                      }, null, 8, ["text", "ariaLabel", "type", "url"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.found.FICHE_EX_PROJTITLE_2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                          _createVNode(_component_ecl_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ecl_column, {
                                col: "12",
                                m: "12",
                                class: ""
                              }, {
                                default: _withCtx(() => _cache[27] || (_cache[27] = [
                                  _createElementVNode("span", { class: "ecl-u-mt-m ecl-u-mb-s ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20" }, null, -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ecl_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ecl_column, {
                                col: "12",
                                m: "12",
                                l: "12",
                                class: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_h7, { class: "ecl-u-type-heading-7 ecl-u-mt-s" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("strong", { innerHTML: _ctx.FICHE_EX_PROJTITLE_2 }, null, 8, _hoisted_38)
                                    ]),
                                    _: 1
                                  }),
                                  _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                                  _createVNode(_component_ecl_p, {
                                    class: "paragraph_list__item",
                                    innerHTML: _ctx.found.FICHE_EX_PROJ2L_2
                                  }, null, 8, ["innerHTML"]),
                                  (_ctx.found.FICHE_EX_PROJWEB_2)
                                    ? (_openBlock(), _createBlock(_component_ecl_button, {
                                        key: 0,
                                        icon: "external",
                                        iconSize: "xs",
                                        text: _ctx.modalGoProjectWeb,
                                        ariaLabel: _ctx.modalGoProjectWeb,
                                        type: _ctx.EclButtonType.Primary,
                                        url: _ctx.FICHE_EX_PROJWEB_2,
                                        target: "_blank"
                                      }, null, 8, ["text", "ariaLabel", "type", "url"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[44] || (_cache[44] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("article", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createVNode(_component_ecl_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_col, {
                      col: "1",
                      class: "ecl-u-ml-m"
                    }, {
                      default: _withCtx(() => _cache[29] || (_cache[29] = [
                        _createElementVNode("img", {
                          class: "ecl-u-mt-none",
                          style: {"width":"44px","height":"44px"},
                          src: _imports_7,
                          alt: "Icon Additional Resources"
                        }, null, -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_ecl_column, { col: "11" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h6", _hoisted_41, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.modalAddRes), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createVNode(_component_ecl_button, {
                    class: "ecl-u-mt-m",
                    icon: "corner-arrow",
                    iconRotate: "90",
                    iconSize: "xs",
                    text: _ctx.buttonDiscover,
                    type: _ctx.EclButtonType.Primary,
                    url: "/resources?lng=en",
                    target: "_blank"
                  }, null, 8, ["text", "type"])
                ])
              ])
            ]),
            _cache[45] || (_cache[45] = _createTextVNode("   ")),
            _createVNode(_component_ecl_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                    _createElementVNode("img", {
                      id: "iconinfo",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_8,
                      alt: "Icon Info"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                    _createElementVNode("img", {
                      id: "icondes",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_9,
                      alt: "Icon Description"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                    _createElementVNode("img", {
                      id: "iconrel",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_10,
                      alt: "Icon Relevance"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                    _createElementVNode("img", {
                      id: "iconwho",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_11,
                      alt: "Icon Who"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                    _createElementVNode("img", {
                      id: "iconcalls",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_12,
                      alt: "Icon Calls"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createElementVNode("img", {
                      id: "iconmore",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_13,
                      alt: "Icon More Info"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createElementVNode("img", {
                      id: "iconprj",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_14,
                      alt: "Icon Project"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                    _createElementVNode("img", {
                      id: "iconcofu",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_15,
                      alt: "Icon Combination Funding"
                    }, null, -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_ecl_col, { col: "1" }, {
                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                    _createElementVNode("img", {
                      id: "iconaddres",
                      class: "ecl-u-mt-none",
                      style: {"width":"44px","height":"44px"},
                      src: _imports_16,
                      alt: "Icon Additional Resources"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          key: "2"
        }
      : undefined
  ]), 1032, ["modelValue"]))
}