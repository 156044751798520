import { createApp, h } from 'vue';
import JCL from '@jrcb3/jcl-vue';
import router from '@/router';
import store from '@/store';
import i18n, { loadLocales } from '@/locale';
import App from '@/views/App/App.vue';
import { SEOManager, BreadcrumbManager, LocaleManager } from '@/managers';
import { loadSite } from './router/contentLoader';
import VTooltipPlugin from 'v-tooltip';
import '@/assets/app.scss';
import { decodeString } from '@/helpers/string/mutate';
import { truncate } from '@/helpers/string/truncate';
import UdpIcon from '@/components/UdpIcon/UdpIcon.component.vue';
const theme = process.env.VUE_APP_ECL_THEME;

const initApp = () => {
  const app = createApp({
    setup() {
      BreadcrumbManager.provide();
      SEOManager.default();
      LocaleManager.autoInit();
    },
    render() {
      return h(App);
    }
  });

  app.config.globalProperties.$filters = {
    truncate: truncate,
    decodeString: decodeString
  };

  app.use(JCL, {
    ecl: {
      theme: theme
    },
  });

  app.component('UdpIcon', UdpIcon);
  app.use(store);
  app.use(i18n);
  app.use(router);
  app.use(VTooltipPlugin);

  app.mount('#app');
};

console.log('MAIN TS APP');

// Carica le lingue disponibili per il sito
import('../node_modules/@jrcb3/ecl-vue/dist/ecl.es.js').then(() => {
  // Carica le lingue disponibili per il sito
  loadLocales().then(
    () => {
      // get the params from url
      const browserLang: string = LocaleManager.getBrowserLanguage(true);
      const requestedLanguage: string = new URLSearchParams(window.location.search).get('lng') ?? browserLang;
      const language: string = LocaleManager.localeIsAvailable(requestedLanguage) ? requestedLanguage : 'en';
      LocaleManager.setLocale(language);

      initApp();
      loadSite();
    },
    error => {
      alert('Error while acquiring available locale!');
      console.error(error);
    }
  );
});
