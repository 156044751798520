import { onMounted, ref, Ref, watch } from 'vue';
import * as L from 'leaflet';
import { isNil } from 'lodash';
import { store } from '@/store';

export default function useMapL() {
  // ================ DATA ================
  const circleMarkerOpts = {
    mono: {
      stroke: false,
      fill: true,
      fillColor: '#f1c200',
      fillOpacity: 1,
      radius: 5.5
    },
    comb: {
      stroke: false,
      fill: true,
      fillColor: '#e43636',
      fillOpacity: 1,
      radius: 5.5
    },
    white: {
      stroke: false,
      fill: true,
      fillColor: '#FFFFFF',
      fillOpacity: 1,
      radius: 5.5
    },
  };

  let filtered = [];
  // filtered = caseStudies;
  filtered = store.getters['tool/inspired'];
  store.commit('tool/totalCountInspire', filtered.length);

  const mapEl: Ref<HTMLElement> = ref();
  const mapDisclaimerModalShow = ref(false);
  const showPopup = ref(false);
  const lmap = ref(null);
  const lmarkers = ref([]);
  const mbAttr =
    '<a href="https://www.openstreetmap.org/">OpenStreetMap</a> ' +
    'contributors, Credit: <a href="http://ec.europa.eu/eurostat/web/gisco/overview">EC-GISCO</a>,' +
    ' &copy; EuroGeographics for the administrative boundaries | <a class="map-disclaimer--open" href="javascript:void(0)">Disclaimer</a>';
  const mbUrl = 'https://gisco-services.ec.europa.eu/maps/tiles/OSMPositronComposite/EPSG3857/{z}/{x}/{y}.png';

  const getMarkerContentJson = (item): string => {
    let _tpl = '<div class="popup__container">';

    _tpl += `<strong>${item.TITLE}</strong><br/>${item.LOCATION}`;
    /* each(item.links, link => {
      _tpl += `<a class="ecl-link ecl-link--standalone ecl-u-type-color-blue-100" target="_blank" href="${link.url}">${link.title}</a><br />`;
    }); */
    _tpl += '</div>';

    return _tpl;
  };

  const addLfpOpen = (id): void => {
    setTimeout(() => {
      store.commit('tool/clickInspire', !store.getters['tool/clickInspire']);
      store.commit('tool/clickInspireId', '');
      store.commit('tool/clickInspireId', id);
      console.log(id);
    }, 100);

    let arr = [];
    if (store.getters['tool/clickInspireIds'].length !== 0) {
      arr = store.getters['tool/clickInspireIds'];
    }
    if (!arr.includes(id)) {
      arr.push(id);
      store.commit('tool/clickInspireIds', arr);
    }
  };

  function getColorCircle(color) {
    // console.log('>>>>>>>>>>>>>getColorCircle');
    // console.log(color);
    // console.log(circleMarkerOpts);

    circleMarkerOpts.white.fillColor = color;
    return circleMarkerOpts.white;
    // return bb === 'M' ? circleMarkerOpts.mono : bb === 'C' ? circleMarkerOpts.comb : circleMarkerOpts.white;
  }

  const listLtvra = ref([]);
  listLtvra.value = store.getters['tool/listPillar'];

  const addMarkers = (): void => {
    if (lmarkers.value.length > 0) {
      lmarkers.value.forEach(l => {
        lmap.value.removeLayer(l);
      });
      lmarkers.value = [];
    }
    if (!isNil(lmap.value) && lmarkers.value.length === 0) {
      const pi = store.getters['tool/pillarInspire'];

      filtered.forEach(item => {
        if (!isNil(item.LAT) && item.LAT.toString() !== '' && item.PILLAR_LTVRA_CODE !== '') {
          // eslint-disable-next-line
          const circleMarkerOpt: any = [];
          if (item.FUND !== '') {
            // circleMarkerOpt.value = getColorCircle(item.MONO_COMBINATION);
            if (item.PILLAR_LTVRA_CODE !== '') {
              // const p = item.PILLAR_LTVRA_CODE.toString().split(';')[0];
              let p = '';
              const pc = item.PILLAR_LTVRA_CODE.toString().split(',');
              for (const c in pc) {
                const pp = pi.find(o => o === pc[c].trim());
                if (!isNil(pp)) {
                  p = pc[c].trim();
                  break;
                }
              }
              const pp = listLtvra.value.find(o => o.id === p);
              circleMarkerOpt.value = getColorCircle(pp.color);
            } else {
              circleMarkerOpt.value = getColorCircle('#000000');
            }
          }

          // const circleMarkerOpt = circleMarkerOpts.pilot;
          // const marker = L.circleMarker([item.coordinates[0], item.coordinates[1]], circleMarkerOpt);
          const marker = L.circleMarker({ lat: item.LAT, lng: item.LNG }, circleMarkerOpt.value);

          const popup = L.popup().setContent(getMarkerContentJson(item));
          marker.bindPopup(popup);

          marker.addTo(lmap.value);
          lmarkers.value.push(marker);

          marker.on('click', function() {
            showPopup.value = true;
            // console.log('>>>click' + item.FICHE_ID);
            addLfpOpen(item.FICHE_ID);
            store.commit('tool/panelOpen', true);

            if (window.innerWidth > 992) {
              const omap = document.getElementById('olmap');
              omap.style.setProperty('width', '100%');
              const map = document.getElementById('lmap');
              map.style.setProperty('width', '100%');
              // const box = document.getElementById('detailpanel');
              // box.style.setProperty('width', '100%');
              const tools = document.getElementById('search-fields');
              tools.style.setProperty('width', '50%');
            } else {
              // const map = document.getElementById('lmap');
              // map.style.setProperty('width', '100%');
              const box = document.getElementById('detailpanel');
              box.style.setProperty('width', '100%');
              const tools = document.getElementById('search-fields');
              tools.style.setProperty('width', '100%');
            }

            setTimeout(() => {
              lmap.value.invalidateSize();
            }, 100);
          });

          marker.on('mouseover', function() {
            this.openPopup();
          });
          marker.on('mouseout', function () {
            this.closePopup();
          });
        } else {
          console.log('>>>>EMPTY');
          console.log(item.FICHE_ID);
          console.log(item.LOCATION);
        }
      });
    }
  };

  /**
   * Show a panel on the map to instruct the user to use 2 fingers
   *
   * @param {TouchEvent} event - the touch event
   */
  const onTwoFingerDrag = (event: TouchEvent): void => {
    const element: HTMLElement = event.currentTarget as HTMLElement;
    if (event.type === 'touchstart' && event.touches.length === 1) {
      element.classList.add('swiping');
    } else if (event.type === 'touchmove' && event.touches.length === 1) {
      event.stopPropagation();
      element.classList.add('swiping');
    } else {
      element.classList.remove('swiping');
    }
  };

  watch(() => store.getters['tool/clickInspireIds'], function() {
    if (store.getters['tool/clickInspireIds'].length === 0) {
      // console.log('>>>>>>>clickInspireIds===0');

      // if (window.innerWidth > 992) {
      const omap = document.getElementById('olmap');
      omap.style.setProperty('width', '100%');
      const map = document.getElementById('lmap');
      map.style.setProperty('width', '100%');
      const box = document.getElementById('detailpanel');
      box.style.setProperty('width', '0');
      const tools = document.getElementById('search-fields');
      tools.style.setProperty('width', '100%');

      setTimeout(() => {
        lmap.value.invalidateSize();
      }, 100);
      // }
    }
  });

  /* SDGClient.vrlList().then(success => {
    markersList.value = success.getData().getList();
    console.log('>>>LIST');
    console.log(markersList.value);

    addMarkers();
  }); */

  // ============== WATCHERS ==============

  onMounted(() => {
    setTimeout(() => {
      const disclaimerEl = mapEl.value.getElementsByClassName('map-disclaimer--open');
      if (disclaimerEl.length > 0) {
        disclaimerEl[0].addEventListener('click', () => {
          mapDisclaimerModalShow.value = true;
        });
      }
    });

    mapEl.value.addEventListener('touchmove', onTwoFingerDrag, { passive: true });
    mapEl.value.addEventListener('touchend', onTwoFingerDrag, { passive: true });

    const tileLayer = L.tileLayer(mbUrl, {
      attribution: mbAttr,
      id: mapEl.value,
    });

    lmap.value = L.map(mapEl.value, {
      dragging: !L.Browser.mobile,
      tap: !L.Browser.mobile,
      zoomControl: false,
      layers: [tileLayer],
      minZoom: 2.2,
      maxZoom: 9,
      scrollWheelZoom: false,
    }).setView([56, 11], 3.6);

    // lmap.value.options.crs = crs3035;

    L.control.zoom({
      position: 'topleft'
    }).addTo(lmap.value);
    addMarkers();

    watch(() => [store.getters['tool/combInspire'], store.getters['tool/monoInspire'], store.getters['tool/inspireKey'], store.getters['tool/inspireFund'], store.getters['tool/pillarInspire']], function() {
      // filtered = caseStudies;
      filtered = store.getters['tool/inspired'];
      console.log('>>>SEARCH WATCH');

      let filterKey = [];
      filterKey = store.getters['tool/inspireKey'];
      if (filterKey.length > 0) {
        filtered = filtered.filter(val => val.KEY_WORDS_CODE.toString().replaceAll('.', ',').split(',').some(r => filterKey === r.trim()));
      }

      let filterFund = [];
      filterFund = store.getters['tool/inspireFund'];
      console.log('>>>FILTER FUND');
      console.log(filterFund);

      if (filterFund.length > 0) {
        filtered = filtered.filter(val => val.FUND_KEY.toString().replaceAll('.', ',').split(',').some(r => filterFund.toString().trim() === r.trim()));
      }
      console.log(filtered);

      if (!store.getters['tool/monoInspire']) {
        filtered = filtered.filter(val => val.MONO_COMBINATION.toString() === 'C');
      } else if (!store.getters['tool/combInspire']) {
        filtered = filtered.filter(val => val.MONO_COMBINATION.toString() === 'M');
      }

      const pm = store.getters['tool/pillarInspire'];
      if (!isNil(pm)) {
        // console.log(pm);
        const tmpFiltered = ref([]);
        tmpFiltered.value = [];
        for (const b in pm) {
          // console.log(pm[b].toLowerCase().replace('-', ' ').trim());
          const tmp1 = filtered.filter(val => val.PILLAR_LTVRA_CODE.toString().trim().includes(pm[b].trim()));
          tmpFiltered.value = tmpFiltered.value.concat(tmp1);
        }

        const tmpEmpty = filtered.filter(val => val.PILLAR_LTVRA_CODE.toString() === '');
        tmpFiltered.value = tmpFiltered.value.concat(tmpEmpty);

        const uniqueArray = tmpFiltered.value.filter((item, index, self) => {
          return index === self.findIndex((t) => t.FICHE_ID === item.FICHE_ID);
        });

        filtered = uniqueArray;
      }

      store.commit('tool/totalCountInspire', filtered.length);
      addMarkers();
    });
  });

  return {
    mapEl,
    mapDisclaimerModalShow,
    showPopup
  };
}
