import { defineComponent, ref } from 'vue';
// import { SEOManager } from '@/managers';
import resources from '@/_static/resources.json';
import { EclButton, EclButtonType } from '@jrcb3/ecl-vue';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';

export default defineComponent({
  name: 'Resources',
  props: {
  },
  directives: {
  },
  components: {
    EclButton,
    EclButtonType
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  setup() {
    // SEOManager.setTitles('About');
    const buttonGo = ref('');
    buttonGo.value = decodeString(i18n.global.t('button.go'));
    const title = ref('');
    title.value = decodeString(i18n.global.t('resources.title'));

    const subTitle1 = ref('');
    subTitle1.value = decodeString(i18n.global.t('resources.subtitle1'));
    const subTitle2 = ref('');
    subTitle2.value = decodeString(i18n.global.t('resources.subtitle2'));
    const subTitle3 = ref('');
    subTitle3.value = decodeString(i18n.global.t('resources.subtitle3'));
    const subTitle4 = ref('');
    subTitle4.value = decodeString(i18n.global.t('resources.subtitle4'));

    const des = ref('');
    des.value = decodeString(i18n.global.t('resources.des'));
    const titles = ref([]);
    const dess = ref([]);

    for (let k = 1; k < 25; k++) {
      const title = ref('');
      title.value = decodeString(i18n.global.t('resources.title' + k));
      const des = ref('');
      des.value = decodeString(i18n.global.t('resources.des' + k));
      titles.value.push(title.value);
      dess.value.push(des.value);
    }
    const des51 = ref('');
    des51.value = decodeString(i18n.global.t('resources.des51'));
    const des221 = ref('');
    des221.value = decodeString(i18n.global.t('resources.des221'));

    return {
      EclButtonType,
      titles,
      dess,
      des51,
      des221,
      title,
      subTitle1,
      subTitle2,
      subTitle3,
      subTitle4,
      des,
      resources,
      buttonGo
    };
  }

});
