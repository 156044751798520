import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ecl-container" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      class: "ecl-page-header-harmonised__title ecl-u-mt-xl",
      innerHTML: _ctx.title
    }, null, 8, _hoisted_2),
    _createVNode(_component_ecl_row, { class: "ecl-u-mt-2xl" }, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_column, {
          col: "12",
          l: "12",
          xl: "12",
          class: "ecl-u-mb-m"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_p, { innerHTML: _ctx.s1 }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (item, index) => {
      return (_openBlock(), _createBlock(_component_ecl_row, {
        class: "ecl-u-mt-s",
        key: index
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            l: "12",
            xl: "12",
            class: "ecl-u-mb-s"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", {
                class: "ecl-u-type-heading-2",
                innerHTML: _ctx.titles[index]
              }, null, 8, _hoisted_3),
              _createVNode(_component_ecl_p, { innerHTML: item }, null, 8, ["innerHTML"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
  ]))
}