import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl ecl-u-mb-l" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx, _cache) {
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_h = _resolveComponent("ecl-h")
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _component_ecl_button = _resolveComponent("ecl-button")
  const _component_ecl_container = _resolveComponent("ecl-container")

  return (_openBlock(), _createBlock(_component_ecl_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_ecl_row, { class: "ecl-u-pb-l" }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            l: "12",
            xl: "12",
            class: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("p", {
        class: "ecl-u-type-paragraph-lead ecl-u-pt-l",
        innerHTML: _ctx.des
      }, null, 8, _hoisted_2),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ecl-u-mb-xl" }, " ", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources.resources, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "ecl-u-pt-xl ecl-u-pb-s"
        }, [
          (index == 0)
            ? (_openBlock(), _createBlock(_component_ecl_h, {
                key: 0,
                level: "3",
                class: "ecl-u-mt-none ecl-u-pb-m"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subTitle1), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (index == 5)
            ? (_openBlock(), _createBlock(_component_ecl_h, {
                key: 1,
                level: "3",
                class: "ecl-u-pt-xl ecl-u-pb-m"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subTitle2), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (index == 16)
            ? (_openBlock(), _createBlock(_component_ecl_h, {
                key: 2,
                level: "3",
                class: "ecl-u-pt-xl ecl-u-pb-m"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subTitle3), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (index == 18)
            ? (_openBlock(), _createBlock(_component_ecl_h, {
                key: 3,
                level: "3",
                class: "ecl-u-pt-xl ecl-u-pb-m"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subTitle4), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ecl_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_column, {
                col: "12",
                l: "3",
                m: "3",
                class: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "section__image",
                    src: require(`@/assets/${item.image}`),
                    alt: _ctx.titles[index]
                  }, null, 8, _hoisted_3)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ecl_column, {
                col: "12",
                m: "9",
                l: "9",
                class: "ecl-u-mb-m",
                style: {"padding-left":"2rem"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ecl_h, {
                    level: "5",
                    class: "ecl-u-mt-s",
                    innerHTML: _ctx.titles[index]
                  }, null, 8, ["innerHTML"]),
                  _createVNode(_component_ecl_p, {
                    innerHTML: _ctx.dess[index]
                  }, null, 8, ["innerHTML"]),
                  _createVNode(_component_ecl_button, {
                    class: "ecl-u-mt-m",
                    icon: "external",
                    iconSize: "xs",
                    text: _ctx.buttonGo,
                    ariaLabel: _ctx.buttonGo,
                    type: _ctx.EclButtonType.Primary,
                    url: item.url,
                    target: item.target
                  }, null, 8, ["text", "ariaLabel", "type", "url", "target"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024),
          (item.row == 1)
            ? (_openBlock(), _createBlock(_component_ecl_row, { key: 4 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ecl_column, {
                    col: "12",
                    m: "12",
                    class: ""
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("span", { class: "ecl-u-pt-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1" }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "ecl-u-mb-l" }, " ", -1))
    ]),
    _: 1
  }))
}