import { defineComponent, ref } from 'vue';
// import { SEOManager } from '@/managers';
import resources from '@/_static/resources.json';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';
import { EclAccordion, EclAccordionItem, EclButton, EclButtonType } from '@jrcb3/ecl-vue';
import { manageTooltip } from '../../views/App/composables/useGlossary';

export default defineComponent({
  name: 'Resources',
  props: {
  },
  directives: {
  },
  components: {
    EclButton,
    EclAccordion,
    EclAccordionItem
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },

  setup() {
    const title = ref('');
    title.value = decodeString(i18n.global.t('FAQ.title'));

    const titles = ref([]);
    const dess = ref([]);

    for (let k = 1; k < 9; k++) {
      const title = ref('');
      title.value = decodeString(i18n.global.t('FAQ.question' + k));
      const des = ref('');
      des.value = manageTooltip(decodeString(i18n.global.t('FAQ.answer' + k)))[0];
      titles.value.push(title.value);
      dess.value.push(des.value);
    }

    return {
      resources,
      EclButtonType,
      titles,
      dess,
      title
    };
  }
});
