<div id="detailpanelplacelfp" class="detail-panel-place-lfp">

  <div
    ref="stickyElement"
    v-sticky
    :sticky-offset="stickyOffset"
    class="detail__tools detail-lfp__tools--sticky tab"
    :key="keyDetail"
    >
      <button v-for="s in clickLfpds" :id="s" style="display: flex; overflow: auto;" class="tablinksStrategy ecl-u-pt-xs" @click="openInspire(s)">

          <span style="margin-bottom: 0px; margin-top: 2px;">
            {{s}}
          </span> &nbsp;
          <ecl-icon class="iconClose" @click="closeInspire(s)" name="close" icon="close"></ecl-icon>
        

      </button>


    <div id="topDiv"></div>
  </div>

  <div id="FactSheet" class="tabcontent">
    <FactSheet :id=lfpId v-if="lfpId !== ''"></FactSheet>
  </div>

</div>
