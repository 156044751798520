<div class="ecl-container">
  <ecl-row class="ecl-u-pb-l">
    <ecl-column col="12" l="12" xl="12" class="">
      <h1 class="ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl">{{title}}</h1>
    </ecl-column>
  </ecl-row>
  <ecl-row class="ecl-u-mt-xl ecl-u-pt-xl ecl-u-pb-xl ecl-u-mb-xl" >
    <ecl-column col="12" m="3" class="">
      <div
        class="ecl-card__image"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_FAQ.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" m="9" class="ecl-u-pb-l ecl-u-mt-m">
      
      <EclAccordion>
        <EclAccordionItem v-for="(item, index) in titles" :key="index">
          <template v-slot:trigger>
            <span>{{item}}</span>
          </template>
          <template v-slot:content>
            <ecl-p v-html="dess[index]"></ecl-p>
          </template>
        </EclAccordionItem>
        <EclAccordionItem style="display:none">
          <template v-slot:trigger style="display:none">
          </template>
          <template v-slot:content style="display:none">
          </template>
        </EclAccordionItem>        
      </EclAccordion>
    </ecl-column>
  </ecl-row>  

</div>