import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tools__search" }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = {
  id: "searchPanelTop",
  style: {"position":"relative"}
}
const _hoisted_4 = {
  class: "contenitore",
  style: {"margin-left":"16px"}
}
const _hoisted_5 = { class: "div-fisso" }
const _hoisted_6 = {
  class: "toggle",
  style: {"vertical-align":"top"}
}
const _hoisted_7 = { class: "toggle-label" }
const _hoisted_8 = {
  class: "toggle",
  style: {"vertical-align":"top"}
}
const _hoisted_9 = { class: "toggle-label" }
const _hoisted_10 = { class: "div-margine-destro" }
const _hoisted_11 = {
  class: "contenitore",
  style: {"margin-left":"16px"}
}
const _hoisted_12 = { class: "div-fisso" }
const _hoisted_13 = {
  height: "25",
  width: "25",
  style: {"padding-top":"2px"}
}
const _hoisted_14 = ["fill"]
const _hoisted_15 = {
  class: "toggle",
  style: {"vertical-align":"top"}
}
const _hoisted_16 = ["id", "onClick"]
const _hoisted_17 = { class: "toggle-label" }
const _hoisted_18 = { class: "container-search-fields" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_jcl_autocomplete = _resolveComponent("jcl-autocomplete")
  const _component_udp_icon = _resolveComponent("udp-icon")
  const _component_ecl_button = _resolveComponent("ecl-button")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, [
              _createElementVNode("input", {
                class: "toggle-checkbox",
                type: "checkbox",
                id: "sud",
                checked: "",
                onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.monoToggle && _ctx.monoToggle(...args)))
              }),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "toggle-switch" }, null, -1)),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.monoFunds), 1)
            ]),
            _cache[6] || (_cache[6] = _createTextVNode("    ")),
            _createElementVNode("label", _hoisted_8, [
              _createElementVNode("input", {
                class: "toggle-checkbox",
                type: "checkbox",
                id: "nonSud",
                checked: "",
                onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.combToggle && _ctx.combToggle(...args)))
              }),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "toggle-switch" }, null, -1)),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.multiFunds), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.textTotal) + ": " + _toDisplayString(_ctx.totalCount), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listLtvra, (p) => {
              return (_openBlock(), _createElementBlock("div", null, [
                (_openBlock(), _createElementBlock("svg", _hoisted_13, [
                  _createElementVNode("circle", {
                    cx: "9",
                    cy: "11",
                    r: "7",
                    stroke: "black",
                    "stroke-width": "0",
                    fill: p.color
                  }, null, 8, _hoisted_14)
                ])),
                _createElementVNode("label", _hoisted_15, [
                  _createElementVNode("input", {
                    class: "toggle-checkbox",
                    type: "checkbox",
                    id: p.id,
                    checked: "",
                    onClick: $event => (_ctx.pillarToggle(p.id))
                  }, null, 8, _hoisted_16),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "toggle-switch" }, null, -1)),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(p.value), 1)
                ]),
                _cache[8] || (_cache[8] = _createTextVNode("    "))
              ]))
            }), 256))
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_jcl_autocomplete, {
            class: "jcl-autocomplete--icon",
            labelKey: "value",
            valueKey: "id",
            size: "s",
            theme: "light",
            showClearButton: true,
            searchPlaceholder: _ctx.inspireKeyw,
            placeholderShow: "false",
            list: _ctx.listFilterTypeAct,
            onSelected: _ctx.changeKey,
            modelValue: _ctx.keySelected,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.keySelected) = $event))
          }, null, 8, ["searchPlaceholder", "list", "onSelected", "modelValue"]),
          _createVNode(_component_jcl_autocomplete, {
            class: "jcl-autocomplete--icon",
            labelKey: "value",
            valueKey: "id",
            size: "s",
            theme: "light",
            showClearButton: true,
            searchPlaceholder: _ctx.inspireFunds,
            placeholderShow: "false",
            list: _ctx.listFilterFund,
            onSelected: _ctx.changeFund,
            modelValue: _ctx.fundSelected,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.fundSelected) = $event))
          }, null, 8, ["searchPlaceholder", "list", "onSelected", "modelValue"]),
          _withDirectives((_openBlock(), _createBlock(_component_ecl_button, {
            class: "",
            type: "secondary",
            onClick: _ctx.resetFilters,
            "aria-label": "Reset filters"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_udp_icon, {
                class: "ecl-icon ecl-icon--s",
                name: "removefilter-x-red"
              })
            ]),
            _: 1
          }, 8, ["onClick"])), [
            [_directive_tooltip, { content: _ctx.inspireRemoveFilters }]
          ]),
          (_ctx.isMobileTablet)
            ? (_openBlock(), _createElementBlock("br", _hoisted_19))
            : _createCommentVNode("", true),
          (_ctx.isMobileTablet)
            ? (_openBlock(), _createElementBlock("br", _hoisted_20))
            : _createCommentVNode("", true)
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
      ])
    ])
  ]))
}