import { defineComponent, ref, computed, Ref, unref, watch, getCurrentInstance } from 'vue';
import { mapGetters } from 'vuex';
import { useRouter } from 'vue-router';
import { isNil, each } from 'lodash';
import headerConfigs from '@/configs/header';
import { LocaleManager } from '@/managers';
import useMainNavigation from './composables/useMainNavigation';
import useFooterNavigation from './composables/useFooterNavigation';
import useBreadcrumb from './composables/useBreadcrumb';
import {
  EclButton,
  EclPageHeaderHarmonised,
  EclSiteHeaderHarmonised,
  EclFooterHarmonised,
} from '@jrcb3/ecl-vue';
import store from '@/store';
import { decodeString } from '../../helpers/string/mutate';

export default defineComponent({
  name: 'App',
  components: { EclSiteHeaderHarmonised, EclFooterHarmonised, EclPageHeaderHarmonised },
  computed: {
    ...mapGetters('site', [
      'loading',
      'i18nLoading',
      'name',
      'contentOwnerDetails',
      'title',
      'description',
      'metaInfo'
    ]),
    ...mapGetters('meta', ['title', 'list'])
  },
  setup() {
    const router = useRouter();
    const app = getCurrentInstance();

    const minimalInterface: Ref<boolean> = ref(false);
    const refHeader: Ref<typeof EclButton | undefined> = ref();
    const refFooter: Ref<typeof EclButton | undefined> = ref();

    const mainNavigation = useMainNavigation();
    const footerNavigation = useFooterNavigation({ refFooter, minimalInterface });

    const cmpBreadcrumb = useBreadcrumb();
    const currentRoute = unref(router.currentRoute);

    const pageTitle = computed((): string | null => {
      if (currentRoute.name !== 'home') {
        const title = store.state.site.title;
        if (!isNil(title)) {
          return decodeString(title);
        }
        const name = store.state.site.name;
        if (name) {
          return decodeString(name);
        }
      }
      return null;
    });

    const homeContactUs = ref('');
    homeContactUs.value = 'Contact us'; // decodeString(i18n.global.t('home.contactUs'));
    const homeRelatedSites = ref('');
    homeRelatedSites.value = 'Related sites'; // decodeString(i18n.global.t('home.relatedSites'));

    const footerSiteContentColumns = computed(() => {
      return [
        [
          {
            title: homeContactUs.value,
            items: [
              {
                url: 'mailto:JRC-RURAL-TOOLKIT@ec.europa.eu',
                label: 'Get in touch!'
              }
            ]
          }
        ],
        [
          {
            title: homeRelatedSites.value,
            items: [
              {
                url: 'https://ec.europa.eu/info/departments/regional-and-urban-policy_en',
                label: 'Directorate-General for Regional and Urban Policy'
              }
            ]
          }
        ]
      ];
    });
    const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
      ? unref(LocaleManager.locale)
      : 'en';
    const l = lang === 'en' ? '' : '_' + lang.toUpperCase();

    let RuralToolkit;
    try {
      RuralToolkit = require('@/_static/ruraltoolkit' + l + '.json');
    } catch (ex) {
      RuralToolkit = require('@/_static/ruraltoolkit.json');
    }
    store.commit('tool/funding', unref(RuralToolkit));

    let Inspired;
    try {
      Inspired = require('@/_static/case_studies' + l + '.json');
    } catch (ex) {
      Inspired = require('@/_static/case_studies.json');
    }
    store.commit('tool/inspired', unref(Inspired));

    let valueTypeAct;
    try {
      valueTypeAct = require('@/_static/filter_type_act' + l + '.json');
    } catch (ex) {
      valueTypeAct = require('@/_static/filter_type_act.json');
    }
    const filterTypeAct = ref([]);
    filterTypeAct.value = unref(valueTypeAct);
    store.commit('tool/listKeyw', filterTypeAct.value);

    let valueFund;
    try {
      valueFund = require('@/_static/funds' + l + '.json');
    } catch (ex) {
      valueFund = require('@/_static/funds.json');
    }
    const filterFund = ref([]);
    filterFund.value = unref(valueFund);
    const listFilterFund = ref([]);

    each(filterFund.value, w => {
      const keys = ['id', 'value', 'value1', 'value2'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : (k === 1 ? w.SHORT_TITLE : (k === 2 ? w.LONG_TITLE : w.CAT));
      }
      listFilterFund.value.push(obj);
    });
    store.commit('tool/listFund', listFilterFund.value);

    let valueLtvra;
    try {
      valueLtvra = require('@/_static/pillar_ltvra' + l + '.json');
    } catch (ex) {
      valueLtvra = require('@/_static/pillar_ltvra.json');
    }
    const ltvra = ref([]);
    ltvra.value = unref(valueLtvra);
    const listLtvra = ref([]);
    const codePillar = [];

    each(ltvra.value, w => {
      const keys = ['id', 'value', 'color'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : (k === 1 ? w.DES : w.COLOR);
      }
      listLtvra.value.push(obj);
      codePillar.push(w.CODE.toString());
    });
    store.commit('tool/listPillar', listLtvra.value);
    store.commit('tool/pillarInspire', codePillar);

    let valueGlossary;
    try {
      valueGlossary = require('@/_static/glossary' + l + '.json');
    } catch (ex) {
      valueGlossary = require('@/_static/glossary.json');
    }
    const filterGlossary = ref([]);
    filterGlossary.value = unref(valueGlossary);
    const listFilterGlossary = ref([]);

    each(filterGlossary.value, w => {
      const keys = ['id', 'value'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : w.DES;
      }
      listFilterGlossary.value.push(obj);
    });
    store.commit('tool/listGlossary', listFilterGlossary.value);

    const languageList = computed(() => unref(LocaleManager.getLanguages()));
    watch(
      languageList,
      (_ll) => {
        if (_ll) {
          app.appContext.config.globalProperties.$language = {
            list: _ll,
          };
        }
      },
      { immediate: true }
    );

    return {
      pageTitle,
      footerSiteContentColumns,
      ...cmpBreadcrumb,
      baseUrl: process.env.VUE_APP_BASE_URL,
      performSearch: (value: string) => {
        window.open(`https://ec.europa.eu/search?QueryText=${value}&op=Search&swlang=${LocaleManager.locale.value}`);
      },
      className: headerConfigs.className,
      siteName: headerConfigs.siteName,
      ...mainNavigation,
      ...footerNavigation,
      languages: languageList,
      refHeader,
      refFooter
    };
  }
});
