import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "detailpanelplacelfp",
  class: "detail-panel-place-lfp"
}
const _hoisted_2 = ["sticky-offset"]
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = { style: {"margin-bottom":"0px","margin-top":"2px"} }
const _hoisted_5 = {
  id: "FactSheet",
  class: "tabcontent"
}

export function render(_ctx, _cache) {
  const _component_ecl_icon = _resolveComponent("ecl-icon")
  const _component_FactSheet = _resolveComponent("FactSheet")
  const _directive_sticky = _resolveDirective("sticky")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      ref: "stickyElement",
      "sticky-offset": _ctx.stickyOffset,
      class: "detail__tools detail-lfp__tools--sticky tab",
      key: _ctx.keyDetail
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clickLfpds, (s) => {
        return (_openBlock(), _createElementBlock("button", {
          id: s,
          style: {"display":"flex","overflow":"auto"},
          class: "tablinksStrategy ecl-u-pt-xs",
          onClick: $event => (_ctx.openInspire(s))
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(s), 1),
          _cache[0] || (_cache[0] = _createTextVNode("   ")),
          _createVNode(_component_ecl_icon, {
            class: "iconClose",
            onClick: $event => (_ctx.closeInspire(s)),
            name: "close",
            icon: "close"
          }, null, 8, ["onClick"])
        ], 8, _hoisted_3))
      }), 256)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { id: "topDiv" }, null, -1))
    ], 8, _hoisted_2)), [
      [_directive_sticky]
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.lfpId !== '')
        ? (_openBlock(), _createBlock(_component_FactSheet, {
            key: 0,
            id: _ctx.lfpId
          }, null, 8, ["id"]))
        : _createCommentVNode("", true)
    ])
  ]))
}