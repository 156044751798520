<!-- <Suspense> -->
<ecl-row>
  <ecl-column col="12" class="ecl-u-pb-xl">
    <div style="background-color: rgb(106, 184, 118)" class="main-image">
      <img :src="require(`@/assets/${sections.mainImage.image}`)" :alt="sections.mainImage.alt" class="hero-image" />
      <img class="image-mobile" :src="require(`@/assets/${sections.mainImage.image}`)" :alt="sections.mainImage.alt" />
    </div>
  </ecl-column>
</ecl-row>

<ecl-container>
  <ecl-row class="ecl-u-pt-xl ecl-u-pb-xl">
    <ecl-column col="12" l="6" m="6" class="ecl-u-mb-s">
      <p class="ecl-u-type-paragraph-lead" v-html="homeDes"></p>

      <section
        id="home-message"
        class="ecl-notification ecl-notification--info ecl-u-mt-xl ecl-u-mb-xl"
        data-ecl-message=""
        data-ecl-auto-init="Message"
      >
        <img class="ecl-u-mr-m" style="width: 22px; height: 22px" src="@/assets/icons/Icon_Info_2.svg" alt="Note" />
        <div class="ecl-notification__content ecl-u-mr-m">
          <div class="ecl-notification__description" v-html="homeNote"></div>
        </div>
      </section>

      <ecl-button
        icon="corner-arrow"
        :iconRotate="90"
        iconSize="xs"
        :text="buttonMoreAbout"
        :type="EclButtonType.Primary"
        url="/about"
      ></ecl-button>
    </ecl-column>

    <ecl-column col="12" l="6" m="6" class="ecl-u-mb-s ecl-u-mt-none">
      <div class="ecl-media-container ecl-u-mt-l">
        <figure class="ecl-media-container__figure">
          <div class="ecl-media-container__rural">
            <iframe
              id="videoplayer"
              src="https://audiovisual.ec.europa.eu/corporateplayer/index.html?video=I-251618"
              width="100%"
              height="303px"
              title="The Rural toolkit, a guide to EU funding for rural areas"
              frameborder="0"
              scrolling="no"
              allowfullscreen="true"
            ></iframe>
          </div>
          <figcaption class="ecl-media-container__caption">{{homeWhatIs}}</figcaption>
        </figure>
      </div>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-xl ecl-u-pb-xl">
    <ecl-column col="12" m="3" class="ecl-u-mb-l ecl-u-mb-m-none">
      <div
        class="ecl-card__image ecl-card--icon"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_v2_Funding-finder.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" m="9" class="ecl-u-mb-s ecl-u-mt-l">
      <ecl-h level="3" class="ecl-u-mt-none">{{$t('finder.title')}}</ecl-h>

      <ecl-p v-html="homeFinder"></ecl-p>
      <ecl-button
        icon="corner-arrow"
        :iconRotate="90"
        iconSize="xs"
        :text="buttonGo"
        :ariaLabel="buttonGo"
        :type="EclButtonType.Primary"
        :url="'/finder?lng=' + lang"
      ></ecl-button>
    </ecl-column>

    <ecl-column col="12" m="12" class="">
      <span
        class="ecl-u-pt-s ecl-u-pb-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1"
      ></span>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-s ecl-u-pb-xl">
    <ecl-column col="12" l="3" m="3" class="ecl-u-mb-l ecl-u-mb-m-none">
      <div
        class="ecl-card__image ecl-card--icon"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_v2_Inspire-youreself.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" l="9" m="9" class="ecl-u-mb-s ecl-u-mt-l">
      <ecl-h level="3" class="ecl-u-mt-none">{{buttonInspire}}</ecl-h>
      <ecl-p v-html="homeInspire"></ecl-p>
      <ecl-button
        icon="corner-arrow"
        :iconRotate="90"
        iconSize="xs"
        :text="buttonGo"
        :ariaLabel="buttonGo"
        :type="EclButtonType.Primary"
        :url="'/inspire?lng=' + lang"
      ></ecl-button>
    </ecl-column>

    <ecl-column col="12" m="12" class="">
      <span
        class="ecl-u-mt-l ecl-u-mt-xl-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1"
      ></span>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-s ecl-u-pb-xl">
    <ecl-column col="12" l="3" m="3" class="ecl-u-mb-l ecl-u-mb-m-none">
      <div
        class="ecl-card__image ecl-card--icon"
        aria-label="card image"
        role="img"
        :style="{'background-image': `url(${require(`@/assets/icons/RT-asset_v2_Resources.svg`)})`}"
      ></div>
    </ecl-column>
    <ecl-column col="12" l="9" m="9" class="ecl-u-mb-s ecl-u-mt-l">
      <ecl-h level="3" class="ecl-u-mt-none">{{buttonResources}}</ecl-h>
      <ecl-p v-html="homeResources"></ecl-p>
      <ecl-button
        icon="corner-arrow"
        :iconRotate="90"
        iconSize="xs"
        :text="buttonGo"
        :ariaLabel="buttonGo"
        :type="EclButtonType.Primary"
        :url="'/resources?lng=' + lang"
      ></ecl-button>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-s ecl-u-pb-s">
    <ecl-column col="12" l="12" m="12" class="ecl-u-mb-l ecl-u-mb-m-none">
      <ecl-p v-html="homeDesF"></ecl-p>
    </ecl-column>
  </ecl-row>

  <ecl-row class="ecl-u-pt-xl ecl-u-pb-xl ecl-u-mb-xl">
    <ecl-column col="12" l="3" m="8" class="ecl-u-mb-s">
      <article class="ecl-card ecl-card--ruralv">
        <div class="ecl-card__body" style="height: 340px">
          <ecl-h level="6" class="ecl-u-mt-none ecl-u-mb-none"><strong>{{buttonLTV}}</strong></ecl-h>
          <div
            class="ecl-card__image"
            aria-label="card image"
            role="img"
            :style="{'background-image': `url(${require(`@/assets/Assets-RT-homepage_rural-vision.jpg`)})`}"
          ></div>
        </div>

        <ecl-button
          icon="external"
          iconSize="xs"
          :text="buttonDiscover"
          :ariaLabel="buttonWebpage"
          :type="EclButtonType.Primary"
          url="https://rural-vision.europa.eu/index_en"
          target="_blank"
          class="ecl-u-mb-l"
        ></ecl-button>
      </article>
    </ecl-column>

    <ecl-column col="12" l="3" m="8" class="ecl-u-mb-s">
      <article class="ecl-card ecl-card--ruralo">
        <div class="ecl-card__body" style="height: 340px">
          <ecl-h level="6" class="ecl-u-mt-none ecl-u-mb-none"><strong>{{buttonRO}}</strong></ecl-h>
          <div
            class="ecl-card__image"
            aria-label="card image"
            role="img"
            :style="{'background-image': `url(${require(`@/assets/Assets-RT-homepage_rural-obs-vector.svg`)})`}"
          ></div>
        </div>

        <ecl-button
          icon="external"
          iconSize="xs"
          :text="buttonDiscover"
          :ariaLabel="buttonWebpage"
          :type="EclButtonType.Primary"
          url="https://observatory.rural-vision.europa.eu/?lng=en&ctx=RUROBS"
          target="_blank"
          class="ecl-u-mb-l"
        ></ecl-button>
      </article>
    </ecl-column>

    <ecl-column col="12" l="3" m="8" class="ecl-u-mb-s">
      <article class="ecl-card ecl-card--rural">
        <div class="ecl-card__body" style="height: 340px">
          <ecl-h level="6" class="ecl-u-mt-none ecl-u-mb-none"><strong>{{buttonRP}}</strong></ecl-h>
          <div
            class="ecl-card__image"
            aria-label="card image"
            role="img"
            :style="{'background-image': `url(${require(`@/assets/rural-pact_v2.jpg`)})`}"
          ></div>
        </div>

        <ecl-button
          icon="external"
          iconSize="xs"
          :text="buttonDiscover"
          :ariaLabel="buttonWebpage"
          :type="EclButtonType.Primary"
          url="https://ruralpact.rural-vision.europa.eu/index_en"
          target="_blank"
          class="ecl-u-mb-l"
        ></ecl-button>
      </article>
    </ecl-column>

    <ecl-column col="12" l="3" m="8" class="ecl-u-mb-s">
      <article class="ecl-card ecl-card--ruralv">
        <div class="ecl-card__body" style="height: 340px">
          <ecl-h level="6" class="ecl-u-mt-none ecl-u-mb-none"><strong>{{buttonTA}}</strong></ecl-h>
          <div
            class="ecl-card__image"
            aria-label="card image"
            role="img"
            :style="{'background-image': `url(${require(`@/assets/Territorial_agenda.png`)})`}"
          ></div>
        </div>

        <ecl-button
          icon="external"
          iconSize="xs"
          :text="buttonDiscover"
          :ariaLabel="buttonWebpage"
          :type="EclButtonType.Primary"
          url="https://territorialagenda.eu/"
          target="_blank"
          class="ecl-u-mb-l"
        ></ecl-button>
      </article>
    </ecl-column>
  </ecl-row>
</ecl-container>
