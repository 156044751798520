import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl" }
const _hoisted_2 = {
  key: 0,
  id: "financialOpp",
  class: "ecl-u-type-heading-4"
}
const _hoisted_3 = {
  key: 1,
  id: "financialOpp",
  class: "ecl-u-type-heading-3"
}
const _hoisted_4 = {
  key: 0,
  class: "ecl-u-type-heading-4"
}
const _hoisted_5 = {
  key: 1,
  class: "ecl-u-type-heading-3"
}
const _hoisted_6 = {
  id: "col-chips",
  style: {"display":"none"}
}
const _hoisted_7 = { class: "component-chip-list" }
const _hoisted_8 = { class: "chip-list" }
const _hoisted_9 = { class: "chip__name" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "chip__name" }
const _hoisted_12 = { class: "chip__name" }
const _hoisted_13 = {
  id: "chip",
  style: {"display":"none"}
}
const _hoisted_14 = { class: "chip__name" }
const _hoisted_15 = {
  class: "ecl-form-group",
  "aria-describedby": "helper-id-K"
}
const _hoisted_16 = ["id", "value"]
const _hoisted_17 = ["for"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  class: "ecl-form-group",
  "aria-describedby": "helper-id-T"
}
const _hoisted_21 = ["id", "value"]
const _hoisted_22 = ["for"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick", "innerHTML"]
const _hoisted_25 = {
  class: "ecl-card-rural ecl-card--pub ecl-u-mb-none",
  style: {"background-color":"#f1f5fc"}
}
const _hoisted_26 = { class: "ecl-card__body ecl-u-mb-none" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 0,
  class: "popper"
}
const _hoisted_29 = { style: {"padding-bottom":".8rem","height":"95px"} }
const _hoisted_30 = ["id"]
const _hoisted_31 = ["id", "onClick"]
const _hoisted_32 = { id: "parent" }
const _hoisted_33 = {
  id: "col-chips",
  style: {"display":"none"}
}
const _hoisted_34 = { class: "component-chip-list" }
const _hoisted_35 = { class: "chip-list" }
const _hoisted_36 = { class: "chip__name" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { class: "chip__name" }
const _hoisted_39 = { class: "chip__name" }
const _hoisted_40 = {
  id: "chip",
  style: {"display":"none"}
}
const _hoisted_41 = { class: "chip__name" }
const _hoisted_42 = {
  class: "ecl-form-group",
  "aria-describedby": "helper-id-K"
}
const _hoisted_43 = ["id", "value"]
const _hoisted_44 = ["for"]
const _hoisted_45 = ["onClick"]
const _hoisted_46 = ["onClick"]
const _hoisted_47 = {
  class: "ecl-form-group",
  "aria-describedby": "helper-id-T"
}
const _hoisted_48 = ["id", "value"]
const _hoisted_49 = ["for"]
const _hoisted_50 = ["onClick"]
const _hoisted_51 = ["onClick", "innerHTML"]

export function render(_ctx, _cache) {
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_icon = _resolveComponent("ecl-icon")
  const _component_EclAccordionItem = _resolveComponent("EclAccordionItem")
  const _component_h7 = _resolveComponent("h7")
  const _component_ecl_checkbox = _resolveComponent("ecl-checkbox")
  const _component_EclAccordion = _resolveComponent("EclAccordion")
  const _component_ecl_container = _resolveComponent("ecl-container")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ecl_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_row, { class: "ecl-u-pb-l" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "12",
              xl: "12",
              class: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.finderTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-l" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "7",
              class: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_row, { class: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_column, {
                      col: "12",
                      l: "12",
                      class: ""
                    }, {
                      default: _withCtx(() => [
                        (_ctx.isMobileTablet)
                          ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.finderFO) + " (" + _toDisplayString(_ctx.fiches.length) + ")", 1))
                          : _createCommentVNode("", true),
                        (!_ctx.isMobileTablet)
                          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.finderFO) + " (" + _toDisplayString(_ctx.fiches.length) + ")", 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "5",
              class: ""
            }, {
              default: _withCtx(() => [
                (_ctx.isMobileTablet)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.finderTaylor), 1))
                  : _createCommentVNode("", true),
                (!_ctx.isMobileTablet)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.finderTaylor), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-mb-none ecl-u-mt-l" }, {
          default: _withCtx(() => [
            (_ctx.isMobileTablet)
              ? (_openBlock(), _createBlock(_component_ecl_column, {
                  key: 0,
                  col: "12",
                  l: "5",
                  class: "ecl-u-mb-s"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_row, { class: "" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_ecl_column, {
                            col: "12",
                            l: "12",
                            class: ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                  (_ctx.listFilterKind[_ctx.filterKindSelected-1])
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "chip chip--pointer",
                                        onClick: _cache[0] || (_cache[0] = $event => (_ctx.resetFilterSelected('K', _ctx.filterKindSelected[0])))
                                      }, [
                                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.filterKindSelectedDes.value), 1),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon",
                                          icon: "close",
                                          size: "xs"
                                        }),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon chip__icon--hover",
                                          icon: "close-filled",
                                          size: "xs"
                                        })
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.filterTypeActSelected)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filterTypeActSelected, (item, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "chip chip--pointer",
                                          onClick: $event => (_ctx.resetFilterSelected('TA', item))
                                        }, [
                                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.listFilterTypeActOrig[item-1].value), 1),
                                          _createVNode(_component_ecl_icon, {
                                            class: "chip__icon",
                                            icon: "close",
                                            size: "xs"
                                          }),
                                          _createVNode(_component_ecl_icon, {
                                            class: "chip__icon chip__icon--hover",
                                            icon: "close-filled",
                                            size: "xs"
                                          })
                                        ], 8, _hoisted_10))
                                      }), 128))
                                    : _createCommentVNode("", true),
                                  (_ctx.listFilterTypeSupportOrig[_ctx.filterTypeSupportSelected-1])
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 2,
                                        class: "chip chip--pointer",
                                        onClick: _cache[1] || (_cache[1] = $event => (_ctx.resetFilterSelected('T', _ctx.filterTypeSupportSelected[0])))
                                      }, [
                                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.listFilterTypeSupportOrig[_ctx.filterTypeSupportSelected-1].valueOrig), 1),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon",
                                          icon: "close",
                                          size: "xs"
                                        }),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon chip__icon--hover",
                                          icon: "close-filled",
                                          size: "xs"
                                        })
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("template", _hoisted_13, [
                                    _createElementVNode("div", {
                                      class: "chip chip-reset chip--pointer",
                                      onClick: _cache[2] || (_cache[2] = $event => (_ctx.resetFilters()))
                                    }, [
                                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.finderResetF), 1),
                                      _createVNode(_component_ecl_icon, {
                                        class: "chip__icon",
                                        icon: "close",
                                        size: "xs"
                                      }),
                                      _createVNode(_component_ecl_icon, {
                                        class: "chip__icon chip__icon--hover",
                                        icon: "close-filled",
                                        size: "xs"
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_ecl_column, {
                          col: "12",
                          l: "12",
                          class: "ecl-u-mb-l"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_EclAccordion, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_EclAccordionItem, {
                                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.accordionItemToggle()))
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.finderKind), 1)
                                  ]),
                                  content: _withCtx(() => [
                                    _createElementVNode("fieldset", _hoisted_15, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listFilterKind, (item, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "ecl-radio"
                                        }, [
                                          _createElementVNode("input", {
                                            type: "radio",
                                            id: 'K' + item.id,
                                            name: "radio-group-K",
                                            class: "ecl-radio__input",
                                            required: "",
                                            value: item.id
                                          }, null, 8, _hoisted_16),
                                          _createElementVNode("label", {
                                            class: "ecl-radio__label",
                                            for: 'K' + item.id
                                          }, [
                                            _createElementVNode("span", {
                                              class: "ecl-radio__box",
                                              onClick: $event => (_ctx.changeSelectedFilterKind(item.id))
                                            }, _cache[12] || (_cache[12] = [
                                              _createElementVNode("span", { class: "ecl-radio__box-inner" }, null, -1)
                                            ]), 8, _hoisted_18),
                                            _createElementVNode("span", {
                                              class: "ecl-radio__text",
                                              onClick: $event => (_ctx.changeSelectedFilterKind(item.id))
                                            }, _toDisplayString(item.value), 9, _hoisted_19)
                                          ], 8, _hoisted_17)
                                        ]))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_EclAccordionItem, {
                                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.accordionItemToggle()))
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.finderTA), 1)
                                  ]),
                                  content: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listCheckboxes, (item, index) => {
                                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                                        _createVNode(_component_h7, {
                                          class: "ecl-u-type-heading-7 ecl-u-mb-m ecl-u-mt-m",
                                          id: "cat"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("strong", null, _toDisplayString(_ctx.listCheckboxesCat[index]), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ecl_checkbox, {
                                          checkboxes: item,
                                          modelValue: _ctx.checkbox[index],
                                          "onUpdate:modelValue": $event => (_ctx.checkbox[index] = $event)
                                        }, null, 8, ["checkboxes", "modelValue", "onUpdate:modelValue"]),
                                        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_EclAccordionItem, {
                                  onClick: _cache[5] || (_cache[5] = $event => (_ctx.accordionItemToggle()))
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.finderTS), 1)
                                  ]),
                                  content: _withCtx(() => [
                                    _createElementVNode("fieldset", _hoisted_20, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listFilterTypeSupport, (item, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "ecl-radio"
                                        }, [
                                          _createElementVNode("input", {
                                            type: "radio",
                                            id: 'T'+item.id,
                                            name: "radio-group-T",
                                            class: "ecl-radio__input",
                                            required: "",
                                            value: item.id
                                          }, null, 8, _hoisted_21),
                                          _createElementVNode("label", {
                                            class: "ecl-radio__label",
                                            for: 'T'+item.id
                                          }, [
                                            _createElementVNode("span", {
                                              class: "ecl-radio__box",
                                              onClick: $event => (_ctx.changeSelectedFilterTypeSupport(item.id))
                                            }, _cache[14] || (_cache[14] = [
                                              _createElementVNode("span", { class: "ecl-radio__box-inner" }, null, -1)
                                            ]), 8, _hoisted_23),
                                            _createElementVNode("span", {
                                              class: "ecl-radio__text",
                                              onClick: $event => (_ctx.changeSelectedFilterTypeSupport(item.id)),
                                              innerHTML: item.value
                                            }, null, 8, _hoisted_24)
                                          ], 8, _hoisted_22)
                                        ]))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "7",
              class: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_column, {
                      col: "12",
                      l: "12",
                      class: "ecl-u-mb-none"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeArrays, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            (_ctx.fichesGrouped[index].length > 0)
                              ? (_openBlock(), _createBlock(_component_ecl_row, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ecl_column, {
                                      col: "12",
                                      l: "12",
                                      m: "12",
                                      class: "ecl-u-mb-xl"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("article", _hoisted_25, [
                                          _createElementVNode("div", _hoisted_26, [
                                            _createElementVNode("h3", {
                                              class: "ecl-content-block__title",
                                              innerHTML: _ctx.typeArraysDes[index]
                                            }, null, 8, _hoisted_27),
                                            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "ecl-u-mt-m ecl-u-mb-l ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-2" }, null, -1)),
                                            _createVNode(_component_ecl_row, null, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fichesGrouped[index], (itemfg, indexfg) => {
                                                  return (_openBlock(), _createBlock(_component_ecl_column, {
                                                    col: "12",
                                                    l: "4",
                                                    class: "ecl-u-mb-none ecl-u-pl-xs ecl-u-pr-xs",
                                                    key: indexfg
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (!_ctx.showTooltip & itemfg.FICHE_LONGTITLE_FUND !== null)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(itemfg.FICHE_LONGTITLE_FUND), 1))
                                                        : _createCommentVNode("", true),
                                                      _createElementVNode("div", _hoisted_29, [
                                                        (itemfg.FICHE_LONGTITLE_FUND !== null)
                                                          ? (_openBlock(), _createElementBlock("div", {
                                                              key: 0,
                                                              class: "tooltip-found",
                                                              id: 'tooltip-fiche' + itemfg.FUNDID
                                                            }, _toDisplayString(itemfg.FICHE_LONGTITLE_FUND), 9, _hoisted_30))
                                                          : _createCommentVNode("", true),
                                                        _createElementVNode("article", {
                                                          id: 'fiche' + itemfg.FUNDID,
                                                          class: _normalizeClass(["ecl-card ecl-card--pub", _ctx.classBkgColors[index]]),
                                                          href: "",
                                                          onClick: $event => (_ctx.handleClickFiche(itemfg.FUNDID, index))
                                                        }, [
                                                          _createElementVNode("div", _hoisted_32, [
                                                            _createElementVNode("div", {
                                                              id: "child",
                                                              style: {"font-size":"19px"},
                                                              class: _normalizeClass(["ecl-u-type-align-center ecl-u-type-bold", _ctx.classTextColors[index]])
                                                            }, _toDisplayString(itemfg.FICHE_SHORTTITLE_FUND), 3)
                                                          ])
                                                        ], 10, _hoisted_31)
                                                      ])
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (!_ctx.isMobileTablet)
              ? (_openBlock(), _createBlock(_component_ecl_column, {
                  key: 1,
                  col: "12",
                  l: "5",
                  class: "ecl-u-mb-s"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ecl_row, { class: "" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, [
                          _createVNode(_component_ecl_column, {
                            col: "12",
                            l: "12",
                            class: ""
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("div", _hoisted_35, [
                                  (_ctx.listFilterKind[_ctx.filterKindSelected-1])
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "chip chip--pointer",
                                        onClick: _cache[6] || (_cache[6] = $event => (_ctx.resetFilterSelected('K', _ctx.filterKindSelected[0])))
                                      }, [
                                        _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.filterKindSelectedDes.value), 1),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon",
                                          icon: "close",
                                          size: "xs"
                                        }),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon chip__icon--hover",
                                          icon: "close-filled",
                                          size: "xs"
                                        })
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.filterTypeActSelected)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filterTypeActSelected, (item, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "chip chip--pointer",
                                          onClick: $event => (_ctx.resetFilterSelected('TA', item))
                                        }, [
                                          _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.listFilterTypeActOrig[item-1].value), 1),
                                          _createVNode(_component_ecl_icon, {
                                            class: "chip__icon",
                                            icon: "close",
                                            size: "xs"
                                          }),
                                          _createVNode(_component_ecl_icon, {
                                            class: "chip__icon chip__icon--hover",
                                            icon: "close-filled",
                                            size: "xs"
                                          })
                                        ], 8, _hoisted_37))
                                      }), 128))
                                    : _createCommentVNode("", true),
                                  (_ctx.listFilterTypeSupportOrig[_ctx.filterTypeSupportSelected-1])
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 2,
                                        class: "chip chip--pointer",
                                        onClick: _cache[7] || (_cache[7] = $event => (_ctx.resetFilterSelected('T', _ctx.filterTypeSupportSelected[0])))
                                      }, [
                                        _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.listFilterTypeSupportOrig[_ctx.filterTypeSupportSelected-1].valueOrig), 1),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon",
                                          icon: "close",
                                          size: "xs"
                                        }),
                                        _createVNode(_component_ecl_icon, {
                                          class: "chip__icon chip__icon--hover",
                                          icon: "close-filled",
                                          size: "xs"
                                        })
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("template", _hoisted_40, [
                                    _createElementVNode("div", {
                                      class: "chip chip-reset chip--pointer",
                                      onClick: _cache[8] || (_cache[8] = $event => (_ctx.resetFilters()))
                                    }, [
                                      _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.finderResetF), 1),
                                      _createVNode(_component_ecl_icon, {
                                        class: "chip__icon",
                                        icon: "close",
                                        size: "xs"
                                      }),
                                      _createVNode(_component_ecl_icon, {
                                        class: "chip__icon chip__icon--hover",
                                        icon: "close-filled",
                                        size: "xs"
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_ecl_column, {
                          col: "12",
                          l: "12",
                          class: "ecl-u-mb-l"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_EclAccordion, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_EclAccordionItem, {
                                  onClick: _cache[9] || (_cache[9] = $event => (_ctx.accordionItemToggle()))
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.finderKind), 1)
                                  ]),
                                  content: _withCtx(() => [
                                    _createElementVNode("fieldset", _hoisted_42, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listFilterKind, (item, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "ecl-radio"
                                        }, [
                                          _createElementVNode("input", {
                                            type: "radio",
                                            id: 'K' + item.id,
                                            name: "radio-group-K",
                                            class: "ecl-radio__input",
                                            required: "",
                                            value: item.id
                                          }, null, 8, _hoisted_43),
                                          _createElementVNode("label", {
                                            class: "ecl-radio__label",
                                            for: 'K' + item.id
                                          }, [
                                            _createElementVNode("span", {
                                              class: "ecl-radio__box",
                                              onClick: $event => (_ctx.changeSelectedFilterKind(item.id))
                                            }, _cache[16] || (_cache[16] = [
                                              _createElementVNode("span", { class: "ecl-radio__box-inner" }, null, -1)
                                            ]), 8, _hoisted_45),
                                            _createElementVNode("span", {
                                              class: "ecl-radio__text",
                                              onClick: $event => (_ctx.changeSelectedFilterKind(item.id))
                                            }, _toDisplayString(item.value), 9, _hoisted_46)
                                          ], 8, _hoisted_44)
                                        ]))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_EclAccordionItem, {
                                  onClick: _cache[10] || (_cache[10] = $event => (_ctx.accordionItemToggle()))
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.finderTA), 1)
                                  ]),
                                  content: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listCheckboxes, (item, index) => {
                                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                                        _createVNode(_component_h7, {
                                          class: "ecl-u-type-heading-7 ecl-u-mb-m ecl-u-mt-m",
                                          id: "cat"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("strong", null, _toDisplayString(_ctx.listCheckboxesCat[index]), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ecl_checkbox, {
                                          checkboxes: item,
                                          modelValue: _ctx.checkbox[index],
                                          "onUpdate:modelValue": $event => (_ctx.checkbox[index] = $event)
                                        }, null, 8, ["checkboxes", "modelValue", "onUpdate:modelValue"]),
                                        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1))
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_EclAccordionItem, {
                                  onClick: _cache[11] || (_cache[11] = $event => (_ctx.accordionItemToggle()))
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.finderTS), 1)
                                  ]),
                                  content: _withCtx(() => [
                                    _createElementVNode("fieldset", _hoisted_47, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listFilterTypeSupport, (item, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "ecl-radio"
                                        }, [
                                          _createElementVNode("input", {
                                            type: "radio",
                                            id: 'T'+item.id,
                                            name: "radio-group-T",
                                            class: "ecl-radio__input",
                                            required: "",
                                            value: item.id
                                          }, null, 8, _hoisted_48),
                                          _createElementVNode("label", {
                                            class: "ecl-radio__label",
                                            for: 'T'+item.id
                                          }, [
                                            _createElementVNode("span", {
                                              class: "ecl-radio__box",
                                              onClick: $event => (_ctx.changeSelectedFilterTypeSupport(item.id))
                                            }, _cache[18] || (_cache[18] = [
                                              _createElementVNode("span", { class: "ecl-radio__box-inner" }, null, -1)
                                            ]), 8, _hoisted_50),
                                            _createElementVNode("span", {
                                              class: "ecl-radio__text",
                                              onClick: $event => (_ctx.changeSelectedFilterTypeSupport(item.id)),
                                              innerHTML: item.value
                                            }, null, 8, _hoisted_51)
                                          ], 8, _hoisted_49)
                                        ]))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_EclAccordionItem, { style: {"display":"none"} })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
        _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1))
      ]),
      _: 1
    }),
    _createVNode(_component_modal)
  ], 64))
}