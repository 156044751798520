import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx, _cache) {
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _component_ecl_button = _resolveComponent("ecl-button")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["paragraph-more-less", {'break-abscract': _ctx.splitAbstract, 'is-collapsed': _ctx.isCollapsed, 'is-expanded': _ctx.isExpanded}])
  }, [
    (_ctx.abstractText)
      ? (_openBlock(), _createBlock(_component_ecl_p, {
          key: 0,
          class: "paragraph__abstract",
          innerHTML: _ctx.abstractText
        }, null, 8, ["innerHTML"]))
      : _createCommentVNode("", true),
    (_ctx.paragraphList)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _mergeProps({
                class: "paragraph__list",
                id: _ctx.id
              }, { hidden: _ctx.showCollapsed }), [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraphList, (paragraph, index) => {
                  return (_openBlock(), _createBlock(_component_ecl_p, {
                    class: "paragraph_list__item",
                    key: index,
                    innerHTML: paragraph
                  }, null, 8, ["innerHTML"]))
                }), 128)),
                (_ctx.showExpand)
                  ? (_openBlock(), _createBlock(_component_ecl_button, {
                      key: 0,
                      class: "ecl-button ecl-button--more",
                      text: _ctx.$t(_ctx.buttonLabel),
                      ariaLabel: _ctx.$t(_ctx.buttonLabel),
                      "aria-controls": _ctx.id,
                      "aria-expanded": _ctx.isExpanded,
                      type: _ctx.EclButtonType.Text,
                      onClick: _ctx.toggleCollapse
                    }, null, 8, ["text", "ariaLabel", "aria-controls", "aria-expanded", "type", "onClick"]))
                  : _createCommentVNode("", true)
              ], 16, _hoisted_1), [
                [_vShow, _ctx.isExpanded]
              ])
            ]),
            _: 1
          }),
          (_ctx.showCollapsed)
            ? (_openBlock(), _createBlock(_component_ecl_button, {
                key: 0,
                class: "ecl-button ecl-button--more",
                text: _ctx.$t(_ctx.buttonLabel),
                ariaLabel: _ctx.$t(_ctx.buttonLabel),
                "aria-controls": _ctx.id,
                "aria-expanded": _ctx.isExpanded,
                type: _ctx.EclButtonType.Text,
                onClick: _ctx.toggleCollapse
              }, null, 8, ["text", "ariaLabel", "aria-controls", "aria-expanded", "type", "onClick"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}