import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ecl-container ecl-u-pb-xl" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "panel_inspire" }
const _hoisted_5 = {
  id: "search-fields",
  class: "contenitore-superiore"
}
const _hoisted_6 = {
  id: "olmap",
  class: "contenitore-inferiore"
}
const _hoisted_7 = {
  ref: "mapEl",
  id: "lmap",
  class: "map",
  "data-finger-alert": "Use two finger to move the map"
}
const _hoisted_8 = {
  key: 0,
  id: "detailpanel",
  class: "panel_inspire_detail"
}
const _hoisted_9 = {
  key: 0,
  "sticky-container": "",
  ref: "panelContent",
  id: "detailPanelContent"
}

export function render(_ctx, _cache) {
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_SearchFields = _resolveComponent("SearchFields")
  const _component_DetailPanel = _resolveComponent("DetailPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ecl_row, { class: "ecl-u-pb-l" }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_column, {
            col: "12",
            l: "12",
            xl: "12",
            class: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("h1", {
                class: "ecl-page-header__title-container ecl-page-header__title ecl-u-mt-xl ecl-u-mb-l",
                innerHTML: _ctx.inspireTitle
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("p", {
        class: "ecl-u-type-paragraph-lead ecl-u-pt-l",
        innerHTML: _ctx.inspireDes
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", {
      id: "inspire",
      class: _normalizeClass(_ctx.classPanel)
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SearchFields)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, null, 512)
        ])
      ]),
      (_ctx.panelOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_ctx.panelOpen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_DetailPanel)
                ], 512))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}