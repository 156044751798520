import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/Icon_Info_2.svg'


const _hoisted_1 = {
  style: {"background-color":"rgb(106, 184, 118)"},
  class: "main-image"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  id: "home-message",
  class: "ecl-notification ecl-notification--info ecl-u-mt-xl ecl-u-mb-xl",
  "data-ecl-message": "",
  "data-ecl-auto-init": "Message"
}
const _hoisted_6 = { class: "ecl-notification__content ecl-u-mr-m" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "ecl-media-container ecl-u-mt-l" }
const _hoisted_9 = { class: "ecl-media-container__figure" }
const _hoisted_10 = { class: "ecl-media-container__caption" }
const _hoisted_11 = { class: "ecl-card ecl-card--ruralv" }
const _hoisted_12 = {
  class: "ecl-card__body",
  style: {"height":"340px"}
}
const _hoisted_13 = { class: "ecl-card ecl-card--ruralo" }
const _hoisted_14 = {
  class: "ecl-card__body",
  style: {"height":"340px"}
}
const _hoisted_15 = { class: "ecl-card ecl-card--rural" }
const _hoisted_16 = {
  class: "ecl-card__body",
  style: {"height":"340px"}
}
const _hoisted_17 = { class: "ecl-card ecl-card--ruralv" }
const _hoisted_18 = {
  class: "ecl-card__body",
  style: {"height":"340px"}
}

export function render(_ctx, _cache) {
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_button = _resolveComponent("ecl-button")
  const _component_ecl_h = _resolveComponent("ecl-h")
  const _component_ecl_p = _resolveComponent("ecl-p")
  const _component_ecl_container = _resolveComponent("ecl-container")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ecl_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_column, {
          col: "12",
          class: "ecl-u-pb-xl"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", {
                src: require(`@/assets/${_ctx.sections.mainImage.image}`),
                alt: _ctx.sections.mainImage.alt,
                class: "hero-image"
              }, null, 8, _hoisted_2),
              _createElementVNode("img", {
                class: "image-mobile",
                src: require(`@/assets/${_ctx.sections.mainImage.image}`),
                alt: _ctx.sections.mainImage.alt
              }, null, 8, _hoisted_3)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ecl_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-xl ecl-u-pb-xl" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "6",
              m: "6",
              class: "ecl-u-mb-s"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "ecl-u-type-paragraph-lead",
                  innerHTML: _ctx.homeDes
                }, null, 8, _hoisted_4),
                _createElementVNode("section", _hoisted_5, [
                  _cache[0] || (_cache[0] = _createElementVNode("img", {
                    class: "ecl-u-mr-m",
                    style: {"width":"22px","height":"22px"},
                    src: _imports_0,
                    alt: "Note"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                      class: "ecl-notification__description",
                      innerHTML: _ctx.homeNote
                    }, null, 8, _hoisted_7)
                  ])
                ]),
                _createVNode(_component_ecl_button, {
                  icon: "corner-arrow",
                  iconRotate: 90,
                  iconSize: "xs",
                  text: _ctx.buttonMoreAbout,
                  type: _ctx.EclButtonType.Primary,
                  url: "/about"
                }, null, 8, ["text", "type"])
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "6",
              m: "6",
              class: "ecl-u-mb-s ecl-u-mt-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("figure", _hoisted_9, [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ecl-media-container__rural" }, [
                      _createElementVNode("iframe", {
                        id: "videoplayer",
                        src: "https://audiovisual.ec.europa.eu/corporateplayer/index.html?video=I-251618",
                        width: "100%",
                        height: "303px",
                        title: "The Rural toolkit, a guide to EU funding for rural areas",
                        frameborder: "0",
                        scrolling: "no",
                        allowfullscreen: "true"
                      })
                    ], -1)),
                    _createElementVNode("figcaption", _hoisted_10, _toDisplayString(_ctx.homeWhatIs), 1)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-xl ecl-u-pb-xl" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              m: "3",
              class: "ecl-u-mb-l ecl-u-mb-m-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "ecl-card__image ecl-card--icon",
                  "aria-label": "card image",
                  role: "img",
                  style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_v2_Funding-finder.svg`)})`})
                }, null, 4)
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              m: "9",
              class: "ecl-u-mb-s ecl-u-mt-l"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_h, {
                  level: "3",
                  class: "ecl-u-mt-none"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('finder.title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ecl_p, { innerHTML: _ctx.homeFinder }, null, 8, ["innerHTML"]),
                _createVNode(_component_ecl_button, {
                  icon: "corner-arrow",
                  iconRotate: 90,
                  iconSize: "xs",
                  text: _ctx.buttonGo,
                  ariaLabel: _ctx.buttonGo,
                  type: _ctx.EclButtonType.Primary,
                  url: '/finder?lng=' + _ctx.lang
                }, null, 8, ["text", "ariaLabel", "type", "url"])
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              m: "12",
              class: ""
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("span", { class: "ecl-u-pt-s ecl-u-pb-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-s ecl-u-pb-xl" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "3",
              m: "3",
              class: "ecl-u-mb-l ecl-u-mb-m-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "ecl-card__image ecl-card--icon",
                  "aria-label": "card image",
                  role: "img",
                  style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_v2_Inspire-youreself.svg`)})`})
                }, null, 4)
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "9",
              m: "9",
              class: "ecl-u-mb-s ecl-u-mt-l"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_h, {
                  level: "3",
                  class: "ecl-u-mt-none"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonInspire), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ecl_p, { innerHTML: _ctx.homeInspire }, null, 8, ["innerHTML"]),
                _createVNode(_component_ecl_button, {
                  icon: "corner-arrow",
                  iconRotate: 90,
                  iconSize: "xs",
                  text: _ctx.buttonGo,
                  ariaLabel: _ctx.buttonGo,
                  type: _ctx.EclButtonType.Primary,
                  url: '/inspire?lng=' + _ctx.lang
                }, null, 8, ["text", "ariaLabel", "type", "url"])
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              m: "12",
              class: ""
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("span", { class: "ecl-u-mt-l ecl-u-mt-xl-xl ecl-u-d-block ecl-u-border-bottom ecl-u-border-color-primary-20 ecl-u-border-width-1" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-s ecl-u-pb-xl" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "3",
              m: "3",
              class: "ecl-u-mb-l ecl-u-mb-m-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "ecl-card__image ecl-card--icon",
                  "aria-label": "card image",
                  role: "img",
                  style: _normalizeStyle({'background-image': `url(${require(`@/assets/icons/RT-asset_v2_Resources.svg`)})`})
                }, null, 4)
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "9",
              m: "9",
              class: "ecl-u-mb-s ecl-u-mt-l"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_h, {
                  level: "3",
                  class: "ecl-u-mt-none"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonResources), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ecl_p, { innerHTML: _ctx.homeResources }, null, 8, ["innerHTML"]),
                _createVNode(_component_ecl_button, {
                  icon: "corner-arrow",
                  iconRotate: 90,
                  iconSize: "xs",
                  text: _ctx.buttonGo,
                  ariaLabel: _ctx.buttonGo,
                  type: _ctx.EclButtonType.Primary,
                  url: '/resources?lng=' + _ctx.lang
                }, null, 8, ["text", "ariaLabel", "type", "url"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-s ecl-u-pb-s" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "12",
              m: "12",
              class: "ecl-u-mb-l ecl-u-mb-m-none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_p, { innerHTML: _ctx.homeDesF }, null, 8, ["innerHTML"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ecl_row, { class: "ecl-u-pt-xl ecl-u-pb-xl ecl-u-mb-xl" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "3",
              m: "8",
              class: "ecl-u-mb-s"
            }, {
              default: _withCtx(() => [
                _createElementVNode("article", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_ecl_h, {
                      level: "6",
                      class: "ecl-u-mt-none ecl-u-mb-none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.buttonLTV), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "ecl-card__image",
                      "aria-label": "card image",
                      role: "img",
                      style: _normalizeStyle({'background-image': `url(${require(`@/assets/Assets-RT-homepage_rural-vision.jpg`)})`})
                    }, null, 4)
                  ]),
                  _createVNode(_component_ecl_button, {
                    icon: "external",
                    iconSize: "xs",
                    text: _ctx.buttonDiscover,
                    ariaLabel: _ctx.buttonWebpage,
                    type: _ctx.EclButtonType.Primary,
                    url: "https://rural-vision.europa.eu/index_en",
                    target: "_blank",
                    class: "ecl-u-mb-l"
                  }, null, 8, ["text", "ariaLabel", "type"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "3",
              m: "8",
              class: "ecl-u-mb-s"
            }, {
              default: _withCtx(() => [
                _createElementVNode("article", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_ecl_h, {
                      level: "6",
                      class: "ecl-u-mt-none ecl-u-mb-none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.buttonRO), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "ecl-card__image",
                      "aria-label": "card image",
                      role: "img",
                      style: _normalizeStyle({'background-image': `url(${require(`@/assets/Assets-RT-homepage_rural-obs-vector.svg`)})`})
                    }, null, 4)
                  ]),
                  _createVNode(_component_ecl_button, {
                    icon: "external",
                    iconSize: "xs",
                    text: _ctx.buttonDiscover,
                    ariaLabel: _ctx.buttonWebpage,
                    type: _ctx.EclButtonType.Primary,
                    url: "https://observatory.rural-vision.europa.eu/?lng=en&ctx=RUROBS",
                    target: "_blank",
                    class: "ecl-u-mb-l"
                  }, null, 8, ["text", "ariaLabel", "type"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "3",
              m: "8",
              class: "ecl-u-mb-s"
            }, {
              default: _withCtx(() => [
                _createElementVNode("article", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_ecl_h, {
                      level: "6",
                      class: "ecl-u-mt-none ecl-u-mb-none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.buttonRP), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "ecl-card__image",
                      "aria-label": "card image",
                      role: "img",
                      style: _normalizeStyle({'background-image': `url(${require(`@/assets/rural-pact_v2.jpg`)})`})
                    }, null, 4)
                  ]),
                  _createVNode(_component_ecl_button, {
                    icon: "external",
                    iconSize: "xs",
                    text: _ctx.buttonDiscover,
                    ariaLabel: _ctx.buttonWebpage,
                    type: _ctx.EclButtonType.Primary,
                    url: "https://ruralpact.rural-vision.europa.eu/index_en",
                    target: "_blank",
                    class: "ecl-u-mb-l"
                  }, null, 8, ["text", "ariaLabel", "type"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ecl_column, {
              col: "12",
              l: "3",
              m: "8",
              class: "ecl-u-mb-s"
            }, {
              default: _withCtx(() => [
                _createElementVNode("article", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_ecl_h, {
                      level: "6",
                      class: "ecl-u-mt-none ecl-u-mb-none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.buttonTA), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "ecl-card__image",
                      "aria-label": "card image",
                      role: "img",
                      style: _normalizeStyle({'background-image': `url(${require(`@/assets/Territorial_agenda.png`)})`})
                    }, null, 4)
                  ]),
                  _createVNode(_component_ecl_button, {
                    icon: "external",
                    iconSize: "xs",
                    text: _ctx.buttonDiscover,
                    ariaLabel: _ctx.buttonWebpage,
                    type: _ctx.EclButtonType.Primary,
                    url: "https://territorialagenda.eu/",
                    target: "_blank",
                    class: "ecl-u-mb-l"
                  }, null, 8, ["text", "ariaLabel", "type"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}