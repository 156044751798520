
<div id="factsheet-lfp" style="background-color: #FFFFFF">
  <div id="factsheet__lfp" class="ecl-u-mv-m ecl-u-mb-none ecl-u-mt-none ombra-div">
    

      <div class="center-image">
        <!--img class="ecl-image dim-image" :src="require(`@/assets/images/${IMAGE_FILE}`)"/-->
        <!--img class="ecl-image dim-image" :src="require(`@/assets/images/` + IMAGE_FILE)"/-->
        
        <!--img class="ecl-image dim-image" :src="require(`@/assets/images/Picture_to_repeat_1.png`)"/-->
        <!--img :src="require(`@/assets/${IMAGE_FILE}`)" class="ecl-image dim-image"/-->        

        <img v-if="imageFile" class="ecl-image dim-image" :src="require(`@/static/images/` + imageFile)" :alt="row.TITLE" />
        <img v-if="!imageFile" class="ecl-image dim-image" :src="require(`@/static/images/Picture_to_repeat_1.png`)" :alt="row.TITLE" />
        <div class="overlay-image">
          <div class="text-image">{{row.TITLE}}</div>
        </div>      
      </div>

      <div id="get-inspired" class="ecl-card__body">

        <ecl-row>
          <ecl-col col="1" class="ecl-u-ml-m">
            <img
            class="ecl-u-mt-none"
            style="width: 44px; height: 44px;"
            src="@/assets/icons/Icon_Info.svg"
            alt="Icon Info"
            />
          </ecl-col>
          <ecl-column col="8">
            <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{modalKey}}</strong></h6>
          </ecl-column>
        </ecl-row>

        <ecl-row>
          <ecl-column col="12" m="12" l="12" class="ecl-u-mt-none">
            <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireCountry}}:&nbsp;</strong>{{ row.COUNTRY }}</div>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>

            <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireLocation}}:&nbsp;</strong>{{ row.LOCATION }}</div>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>

            <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireOrg}}:&nbsp;</strong>{{ row.ORGANISATION }}</div>            
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>

            <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireBudget}}:&nbsp;</strong>{{ row.BUDGET }}</div>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>

            <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireFunds}}:&nbsp;</strong><span v-for="item in FUND_KEY">
              <a v-if="item[2] !== null" :class="classColors[item[2]]" @click="handleClickFiche(item[0], item[2])">{{ item[1] }}</a>
              <a v-if="item[2] == null" class="" >{{ item[1] }}</a>&nbsp;&nbsp;</span></div>
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>

            <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireTimeframe}}:&nbsp;</strong>{{ row.TIMEFRAME }}</div>                        
            <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>

            <div v-if="row.PROJECT_WEBSITE">
              <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{projectWebsite}}:&nbsp;</strong><span v-html="PROJECT_WEBSITE"></span></div>
            </div>

            <div v-if="row.PROJECT_WEBSITE && row.DATABASE_LINK">
              <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>
            </div>

            <div v-if="row.DATABASE_LINK">
              <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireDblink}}:&nbsp;</strong><span v-html="DATABASE_LINK"></span></div>
            </div>

            <div id="caseStudyTypology" v-if="row.CASE_STUDY_TYPOLOGY">
              <span class="ecl-u-d-block ecl-u-border-top ecl-u-border-color-primary-20 ecl-u-mt-xs ecl-u-mb-xs"></span>
              
              <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{caseStudy}}:&nbsp;</strong></div>
              <span v-for="item in caseStudyTypology" class="ecl-tag ecl-tag--display">{{ item }}</span>
              <br/><br/>
            </div>            

          </ecl-column>        
        </ecl-row>
      </div>

      <div id="" class="ecl-u-pt-xl" style="background-color: #f3f6fc;">
        <div id="city-profile" class="">
          <ecl-row>
            <ecl-col col="1" class="ecl-u-ml-m">
              <img
              class="ecl-u-mt-none"
              style="width: 44px; height: 44px;"
              src="@/assets/icons/Icon_Description.svg"
              alt="Icon Description"
              />
            </ecl-col>
            <ecl-column col="8">
              <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{inspireProjectDes}}</strong></h6>
              <!--div class="ecl-u-type-m ecl-u-type-color-blue-100 ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireProjectDes}}:&nbsp;</strong></div-->
            </ecl-column>
          </ecl-row>

            <!--div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireProjectDes}}:&nbsp;</strong></div-->
            <p :style="'text-align: left;'" class="ecl-u-type-paragraph-m  ecl-u-pb-m" v-html="row.DESCRIPTION"></p>

        </div>
      </div>

      <div id="" class="ecl-u-pt-xl" style="background-color: #ffffff;" v-if="row.COMBINATION_OF_FUNDS">
        <div id="city-profile" class="">
          <ecl-row>
            <ecl-col col="1" class="ecl-u-ml-m">
              <img
              class="ecl-u-mt-none"
              style="width: 44px; height: 44px;"
              src="@/assets/icons/Icon_Combination-funding.svg"
              alt="Icon Combination Funding"
              />
            </ecl-col>
            <ecl-column col="8">
              <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{multiFunds}}</strong></h6>
              <!--div class="ecl-u-type-m ecl-u-type-color-blue-100 ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireProjectDes}}:&nbsp;</strong></div-->
            </ecl-column>
          </ecl-row>

          <!--div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireProjectDes}}:&nbsp;</strong></div-->
            <p :style="'text-align: left;'" class="ecl-u-type-paragraph-m ecl-u-pb-m" v-html="row.COMBINATION_OF_FUNDS"></p>
        </div>
      </div>


      <div id="" class="ecl-u-pt-xl" style="background-color: #f3f6fc;" v-if="row.TIPS_COMBINATION_FUNDS">
        <div id="city-profile" class="">
          <ecl-row>
            <ecl-col col="1" class="ecl-u-ml-m">
              <img
              class="ecl-u-mt-none"
              style="width: 44px; height: 44px;"
              src="@/assets/icons/Icon_Tips.svg"
              alt="Icon Tips"
              />
            </ecl-col>
            <ecl-column col="10">
              <h6 class="ecl-u-type-heading-6 ecl-u-type-color-blue-100 ecl-u-mt-s ecl-u-mb-s"><strong>{{tips}}</strong></h6>
              <!--div class="ecl-u-type-m ecl-u-type-color-blue-100 ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireProjectDes}}:&nbsp;</strong></div-->
            </ecl-column>
          </ecl-row>

          <!--div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireProjectDes}}:&nbsp;</strong></div-->
            <p :style="'text-align: left;'" class="ecl-u-type-paragraph-m ecl-u-pb-m" v-html="row.TIPS_COMBINATION_FUNDS"></p>
        </div>
      </div>
      <div v-if="row.TIPS_COMBINATION_FUNDS">
        <br/>
      </div>

      <div id="get-inspired" class="ecl-card__body">
       
        <div id="keywords" v-if="KEY_WORDS">
          <div class="ecl-u-type-m ecl-u-type-color-black ecl-u-mt-m ecl-u-mb-m"><strong>{{inspireKeyw}}:&nbsp;</strong></div>
          <span v-for="item in KEY_WORDS" class="ecl-tag ecl-tag--display">{{ item }}</span>
          <hr class="ecl-u-mt-l">
        </div>

        <div class="ecl-u-type-m ecl-u-type-color-black  ecl-u-mt-m ecl-u-mb-m"><strong>{{inspirePillars}}:&nbsp;</strong>

        <div id="pillarLtvra" class="contenitore-img" style="margin-left: 1px;">
          <span v-for="item in PILLAR_LTVRA">
            <img 
            class="ecl-u-mt-none ecl-u-mr-s ecl-u-ml-s"
            style="width: 100px; height: 100px;"
            :src="require(`@/assets/icons/${item.img}`)"
            :alt="item.des"
            />
            <div class="ecl-u-type-m ecl-u-mt-none" style="text-align: center;" v-html="item.des"></div>
          </span>
        </div>
      </div>

    </div>

  </div>
</div>
<modal></modal>